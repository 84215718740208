import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select,
  Checkbox,
  Button,
  Tooltip,
  Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import fetchOrderPdf from '../helpers/getOrderPdf';
import {
  fetchOrder,
  setFacturasActiveFilters,
  setFilteredFacturas,
  uploadBulkFacturas,
} from '../actions/orderAction';
import { uploadFacturaPdf, deletePdf } from '../actions/orderFacturaAction';
import CustomSnackBar from './elements/CustomSnackBar';
import StatusChip from './elements/StatusChip';
import FacturasTable from './elements/FacturasTable';
import BulkFacturaDialog from './elements/BulkFacturaDialog';
import GradientButton from './form/GradientButton';
import OperationDetails from './elements/OperationDetails';
import SurplusChip from './elements/SurplusChip';
import {
  ORDER_FACTURAS_STATUSES,
  ORDER_STATUSES,
  BUTTON_LABEL,
} from '../helpers/Constants';

const useStyles = {
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
  },
  main: {
    marginTop: 20,
    minHeight: '60vh',
    borderRadius: 17,
    padding: 20,
    border: '1px solid #E7E5EC',
    height: '100%',
  },
  yellow: {
    background: '#FFEFC9',
    '& span': {
      fontWeight: 'bold',
      color: '#E29D02',
    },
  },
  link: {
    cursor: 'pointer',
    color: '#A8A4B3',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Muli',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    padding: 0,
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
  downloadLink: {
    cursor: 'pointer',
    display: 'flex',
    color: '#1A73E7',
    alignItems: 'center',
    fontFamily: 'Muli',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    padding: 0,
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
  textBold: {
    color: '#575360',
    fontWeight: 'bold',
  },
  totalAmount: {
    fontWeight: 'bold',
    color: '#575360',
  },
  orderDetailsContainer: {
    marginTop: 20,
    borderRadius: 17,
    padding: 20,
    border: '1px solid #E7E5EC',
  },
  greenTitle: {
    color: '#75B801',
  },
  marginTop: {
    marginTop: 10,
  },
  label: {
    color: '#6C6776',
    fontWeight: 'bold',
  },
  amount: {
    color: '#6C6776',
    fontWeight: 'normal',
    fontSize: 14,
  },
  detailsTitle: {
    fontFamily: 'Muli',
    fontWeight: 'bold',
    color: '#575360',
    fontSize: 18,
    marginBottom: 10,
  },
  smallIcon: {
    fontSize: 18,
  },
  helpIcon: {
    color: '#A8A4B3',
  },
  bulkPdfButton: {
    textTransform: 'none',
    padding: '5px 15px',
  },
  filterSelect: {
    color: '#CECAD9',
    borderRadius: '20px',
    '& .MuiSelect-root': {
      padding: '5px 10px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: '20px',
      color: '#575360',
    },
  },
  bodyRoot: {
    display: 'grid',
    gridTemplateColumns: 'auto 280px',
    width: '100%',
  },
};

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBulkFacturaDialog: false,
    };
  }

  componentDidMount = () => {
    const {
      fetchOrder,
      orderId,
      resetBulkFacturasSnackBar,
      user: { Businesses },
    } = this.props;
    const { id: businessId } = Businesses[0];
    fetchOrder(orderId, businessId);
    resetBulkFacturasSnackBar();
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.facturasActiveFilters !== this.props.facturasActiveFilters &&
      this.props.selectedOperation
    ) {
      this.filterFacturas();
    }
    if (
      prevProps.uploadedBulkFacturas !== this.props.uploadedBulkFacturas &&
      this.props.uploadedBulkFacturas === true
    ) {
      this.handleCloseBulkFacturaDialog();
    }
  };

  handleBackButtonClick = () => {
    this.props.history.push('/orders');
  };

  getResumeTypeName = statusGroup => {
    if (statusGroup === 'TRANSFERRED' || statusGroup === 'PAID') {
      return 'liquidación';
    }

    return 'simulación';
  };

  handleRemoveFilter = status => {
    const { facturasActiveFilters, setFacturasActiveFilters } = this.props;
    const statusArray = [...facturasActiveFilters];
    statusArray.splice(statusArray.indexOf(status), 1);
    setFacturasActiveFilters(statusArray);
    this.filterFacturas();
  };

  filterFacturas = () => {
    const { userOrderFacturas, facturasActiveFilters, setFilteredFacturas } =
      this.props;
    const updatedFilteredFacturas = userOrderFacturas.filter(factura => {
      return facturasActiveFilters.includes(factura.statusGroup);
    });

    setFilteredFacturas(updatedFilteredFacturas);
  };

  handleChangeMultiple = e => {
    this.props.setFacturasActiveFilters(e.target.value);
  };

  handleDownloadPdf = async operation => {
    const response = await fetchOrderPdf(operation);
    const { data } = response;
    const blob = this.b64toBlob(data.pdf, 'application/pdf');

    this.download(blob, `${Date.now()}.pdf`);
  };

  b64toBlob = (b64Data, contentType = '') => {
    const sliceSize = 512;
    // eslint-disable-next-line no-param-reassign
    b64Data = b64Data.replace(/^[^,]+,/, '');
    // eslint-disable-next-line no-param-reassign
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  //  TODO refactor function download and move to utility module.
  download = (file, filename) => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(file);

    link.download = filename;
    link.click();
    link.remove();
  };

  handleShowBulkFacturaDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showBulkFacturaDialog: true,
    }));
  };

  handleCloseBulkFacturaDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showBulkFacturaDialog: false,
    }));
    this.props.resetBulkFacturasDialog();
  };

  handleSubmitBulkFacturas = bulkFacturas => {
    const {
      uploadBulkFacturas,
      selectedOperation: { id: orderId },
      user: { id: userId, Businesses: [{ id: businessId }] = [] } = {},
    } = this.props;
    const rawBulk = bulkFacturas.map(factura => factura.file);

    uploadBulkFacturas(orderId, rawBulk, businessId, userId);
  };

  render() {
    const { REJECTED: REJECTED_STATUS } = ORDER_STATUSES;
    const { COMPLETE, REJECTED } = ORDER_FACTURAS_STATUSES;
    const {
      classes,
      selectedOperation,
      facturasAvailableFilters,
      facturasActiveFilters: statuses,
      filteredOrderFacturas,
      loadingOrder,
      uploadingBulkFacturas,
      uploadedBulkFacturas,
      uploadFacturaPdf,
      deletePdf,
      uploadingPdf,
      deletingPdf,
      uploadingFacturaId,
      orderError,
    } = this.props;

    const { showBulkFacturaDialog } = this.state;
    const currentSurplus = filteredOrderFacturas.reduce((sum, factura) => {
      const { amountRetention, status } = factura;
      const invoiceSurplus =
        amountRetention && status !== COMPLETE && status !== REJECTED
          ? amountRetention
          : 0;
      return sum + invoiceSurplus;
    }, 0);
    const {
      id = '',
      OrderDetails,
      advancePercentage,
      statusGroup = '',
      hasAnyEmptyAttachment,
    } = selectedOperation || {};
    const [orderDetail] = OrderDetails || [];
    const {
      anticipo,
      retencion,
      intereses,
      tasaBase,
      operationCost,
      montoTransferencia,
    } = orderDetail || {};
    const percentageSurplus =
      retencion > 0 ? Math.round((currentSurplus / retencion) * 100) : 0;

    return (
      <Fade timeout={400} in>
        <div className={classes.content}>
          {orderError ? (
            <Grid item xs={12}>
              <Typography variant="body">
                La orden solicitada no existe.
              </Typography>
            </Grid>
          ) : (
            <>
              {uploadedBulkFacturas && (
                <CustomSnackBar
                  anchor={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  variant="success"
                  size={24}
                  message="Tus facturas se han subido con éxito!"
                  showPercentage={false}
                />
              )}
              {filteredOrderFacturas && (
                <BulkFacturaDialog
                  open={showBulkFacturaDialog}
                  handleClose={this.handleCloseBulkFacturaDialog}
                  handleSubmitBulkFacturas={this.handleSubmitBulkFacturas}
                  pendingFacturasCounter={filteredOrderFacturas.length}
                  isUploading={uploadingBulkFacturas}
                />
              )}
              <Grid container direction="column" spacing={2}>
                <Grid item xs={10}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        className={classes.link}
                        onClick={this.handleBackButtonClick}
                      >
                        <LeftArrowIcon fontSize="large" /> {BUTTON_LABEL.VOLVER}
                      </Button>
                    </Grid>
                    {filteredOrderFacturas?.length >= 10 &&
                      hasAnyEmptyAttachment && (
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <GradientButton
                                startIcon={<CloudUploadIcon />}
                                className={classes.bulkPdfButton}
                                size="small"
                                disableElevation
                                onClick={this.handleShowBulkFacturaDialog}
                              >
                                Sube tus PDFs cedibles
                              </GradientButton>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Sube todos los PDFs cedibles de las facturas de tu operación.">
                                <HelpIcon className={classes.helpIcon} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className={classes.bodyRoot}
                  >
                    <Grid item>
                      <Paper elevation={0} className={classes.main}>
                        <Grid container direction="column" spacing={3}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justify={
                                statusGroup === REJECTED_STATUS
                                  ? 'center'
                                  : 'space-between'
                              }
                            >
                              <Grid item>
                                <Grid container justify="center">
                                  <Grid item>
                                    <Typography
                                      variant="h5"
                                      className={classes.textBold}
                                    >
                                      {`Detalle de la operación #${id}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {facturasAvailableFilters &&
                                statusGroup !== REJECTED_STATUS && (
                                  <Grid item xs={2}>
                                    <Select
                                      variant="outlined"
                                      name="statuses"
                                      label="Filtrar por estado"
                                      value={statuses}
                                      onChange={e =>
                                        this.handleChangeMultiple(e)
                                      }
                                      className={classes.filterSelect}
                                      renderValue={() => (
                                        <Typography variant="body2">
                                          Filtrar por:
                                        </Typography>
                                      )}
                                      multiple
                                      fullWidth
                                    >
                                      {facturasAvailableFilters.map(status => {
                                        return (
                                          <MenuItem value={status}>
                                            <Checkbox
                                              checked={statuses.includes(
                                                status
                                              )}
                                            />
                                            <StatusChip status={status} />
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                          {statusGroup !== REJECTED_STATUS && (
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                spacing={1}
                                alignItems="center"
                              >
                                {statuses &&
                                  statuses.map(status => (
                                    <Grid item>
                                      <StatusChip
                                        key={status}
                                        status={status}
                                        handleClick={() =>
                                          this.handleRemoveFilter(status)
                                        }
                                      />
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            {filteredOrderFacturas && (
                              <FacturasTable
                                facturas={filteredOrderFacturas}
                                loading={loadingOrder}
                                uploadFacturaPdf={uploadFacturaPdf}
                                deletePdf={deletePdf}
                                uploadingPdf={uploadingPdf}
                                deletingPdf={deletingPdf}
                                uploadingFacturaId={uploadingFacturaId}
                                tasaBase={tasaBase}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end">
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  className={classes.label}
                                >
                                  Monto Total:{' '}
                                  <NumberFormat
                                    value={anticipo || '-'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    isNumericString
                                    displayType="text"
                                    prefix="$"
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    {selectedOperation && (
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>
                            <Paper
                              spacing={3}
                              elevation={0}
                              className={classes.orderDetailsContainer}
                            >
                              <Grid
                                container
                                direction="column"
                                spacing={1}
                                align="center"
                              >
                                <OperationDetails
                                  porcentajeAnticipo={advancePercentage}
                                  excedentes={retencion}
                                  tasaInteres={tasaBase}
                                  diferenciaPrecio={intereses}
                                  costoOperacional={operationCost}
                                  totalAGirar={montoTransferencia}
                                  mostrarTooltips
                                />

                                {statusGroup !== 'REJECTED' && (
                                  <Grid item xs={12}>
                                    <Button
                                      className={classes.downloadLink}
                                      onClick={() =>
                                        this.handleDownloadPdf(
                                          selectedOperation
                                        )
                                      }
                                    >
                                      <FileIcon className={classes.smallIcon} />{' '}
                                      {`Descargar ${this.getResumeTypeName(
                                        statusGroup
                                      )}`}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper
                              spacing={3}
                              elevation={0}
                              className={classes.orderDetailsContainer}
                            >
                              <Grid container direction="column" spacing={1}>
                                <Grid item xs={12} align="center">
                                  <Typography className={classes.detailsTitle}>
                                    Resumen de excedentes
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                      <Tooltip
                                        title="Excedentes vigentes a la fecha"
                                        placement="left"
                                      >
                                        <Typography
                                          variant="body2"
                                          className={classes.label}
                                        >
                                          Excedentes vigentes:
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} align="right">
                                      <SurplusChip
                                        surplus={currentSurplus}
                                        percentage={percentageSurplus}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </Fade>
    );
  }
}

OrderDetails.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  resetBulkFacturasSnackBar: PropTypes.func.isRequired,
  facturasActiveFilters: PropTypes.arrayOf().isRequired,
  selectedOperation: PropTypes.objectOf(Object).isRequired,
  uploadedBulkFacturas: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  setFacturasActiveFilters: PropTypes.func.isRequired,
  userOrderFacturas: PropTypes.arrayOf().isRequired,
  setFilteredFacturas: PropTypes.func.isRequired,
  resetBulkFacturasDialog: PropTypes.func.isRequired,
  uploadBulkFacturas: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  facturasAvailableFilters: PropTypes.arrayOf().isRequired,
  filteredOrderFacturas: PropTypes.arrayOf().isRequired,
  loadingOrder: PropTypes.bool.isRequired,
  uploadingBulkFacturas: PropTypes.bool.isRequired,
  uploadFacturaPdf: PropTypes.bool.isRequired,
  deletePdf: PropTypes.func.isRequired,
  user: PropTypes.objectOf(Object).isRequired,
  uploadingPdf: PropTypes.bool.isRequired,
  deletingPdf: PropTypes.bool.isRequired,
  uploadingFacturaId: PropTypes.number.isRequired,
  orderError: PropTypes.objectOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    selectedOperation: state.order.selectedOrder,
    userOrderFacturas: state.order.selectedOrder.OrderFacturas,
    filteredOrderFacturas: state.order.filteredOrderFacturas,
    loadingOrder: state.order.loadingOrder,
    orderError: state.order.orderError,
    facturasAvailableFilters: state.order.facturasAvailableFilters,
    facturasActiveFilters: state.order.facturasActiveFilters,
    uploadingBulkFacturas: state.order.uploadingBulkFacturas,
    uploadedBulkFacturas: state.order.uploadedBulkFacturas,
    uploadingPdf: state.order.uploadingPdf,
    deletingPdf: state.order.deletingPdf,
    uploadingFacturaId: state.order.uploadingFacturaId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (orderId, businessId) =>
      dispatch(fetchOrder(orderId, businessId)),
    setFilteredFacturas: filteredOrderFacturas =>
      dispatch(setFilteredFacturas(filteredOrderFacturas)),
    setFacturasActiveFilters: facturasActiveFilters =>
      dispatch(setFacturasActiveFilters(facturasActiveFilters)),
    uploadBulkFacturas: (orderId, bulkFacturas, businessId, userId) =>
      dispatch(uploadBulkFacturas(orderId, bulkFacturas, businessId, userId)),
    resetBulkFacturasSnackBar: () =>
      dispatch({ type: 'RESET_BULK_FACTURAS_UPLOAD_SNACKBAR' }),
    resetBulkFacturasDialog: () =>
      dispatch({ type: 'RESET_BULK_FACTURAS_UPLOAD_DIALOG' }),
    uploadFacturaPdf: (pdf, orderFacturaData, businessId, userId) =>
      dispatch(uploadFacturaPdf(pdf, orderFacturaData, businessId, userId)),
    deletePdf: (orderfacturaId, pdfId, businessId, userId) =>
      dispatch(deletePdf(orderfacturaId, pdfId, businessId, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(withRouter(OrderDetails)));
