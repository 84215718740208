import React from 'react';
import PropTypes, { number } from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';

const CustomPaginationMaterialTable = props => {
  const {
    count,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPageOptions,
  } = props;
  const labelRowsPerPage = 'Filas por página:';
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onChangePage={onChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelRowsPerPage={labelRowsPerPage}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

CustomPaginationMaterialTable.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(number).isRequired,
};

export default CustomPaginationMaterialTable;
