import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles({
  textField: {
    marginLeft: 1,
    marginRight: 1,
  },
  errorInput: {
    '& label': {
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
});

const styles = {
  root: {
    '& input': {
      fontFamily: 'Muli',
    },
    '& label': {
      padding: '0 8px',
    },
    '& label.Mui-focused': {
      color: '#7300FF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7300FF',
    },
    '& .MuiOutlinedInput-input': {
      padding: '9.5px 20px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0',
      color: '#CECAD9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '40px',
        height: '42px',
        borderColor: '#CECAD9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7300FF',
      },
      '& .MuiSelect-select:focus': {
        borderRadius: '40px',
        background: 'transparent',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(190, 139, 255)',
      },
    },
  },
};

const CssTextFieldValidated = withStyles(styles)(TextValidator);
const CssTextField = withStyles(styles)(TextField);

const Input = props => {
  const classes = useStyles();
  return props.dontvalidate ? (
    <CssTextField
      {...props}
      className={classes.textField}
      margin="normal"
      variant="outlined"
      fullWidth
      textHelper={props.hasError ? props.textHelper : ''}
    />
  ) : (
    <CssTextFieldValidated
      {...props}
      className={props.hasError ? classes.errorInput : classes.textField}
      textHelper={props.hasError ? props.textHelper : ''}
      margin="normal"
      variant="outlined"
      fullWidth
    />
  );
};

export default Input;
