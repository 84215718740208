import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  rootDialog: {
    borderRadius: 17,
    width: 428,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 800,
    color: '#575360',
    marginTop: 50,
  },
  actions: {
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 50,
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  topIcon: {
    width: 140,
    margin: '20px 0px -40px 0px;',
  },
});
const BaseDialog = props => {
  const classes = useStyles();
  const {
    isOpen,
    handleClose,
    title,
    children,
    actions,
    topIcon,
    topIconWidth,
  } = props;

  const closeDialogHandler = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      size="sm"
      maxWidth="sm"
      onClose={closeDialogHandler}
      classes={{ paper: classes.rootDialog }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialogHandler}
          data-qa="button-close-dialog"
        >
          <CloseIcon />
        </IconButton>
        <Grid container align="center">
          {topIcon && (
            <Grid item xs={12}>
              <img
                src={topIcon}
                width={topIconWidth}
                className={classes.topIcon}
                alt="Top"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!!actions && (
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      )}
    </Dialog>
  );
};

BaseDialog.defaultProps = {
  actions: null,
  topIcon: null,
  topIconWidth: null,
  title: null,
};

BaseDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.objectOf(Object).isRequired,
  actions: PropTypes.objectOf(Object),
  topIcon: PropTypes.string,
  topIconWidth: PropTypes.number,
};

export default BaseDialog;
