import React, { Component } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import configureStore from './modules/store';
import Home from './Home';
import Start from './Start';
import Impersonate from './components/Impersonate';
import requireAuth from './components/hoc/require_auth';
import noRequireAuth from './components/hoc/no_require_auth';
import ResetPassword from './components/ResetPassword';
import HomeUserProfile from './components/HomeUserProfile';
import Layout from './Layout';
import { setPageView } from './helpers/trackingGA';
import { setPageViewSegment } from './helpers/trackingSegment';
import NotificationHandler from './components/hoc/NotificationHandler';
import { SnackbarUtilsConfigurator } from './helpers/SnackbarUtils';

const { store, persistor } = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f77240',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(245, 143, 105)',
    },
  },
  typography: {
    color: '#575360',
    h1: {
      fontFamily: 'Muli',
    },
    h2: {
      fontFamily: 'Muli',
    },
    h3: {
      fontFamily: 'Muli',
    },
    h4: {
      fontFamily: 'Muli',
    },
    h5: {
      fontFamily: 'Muli',
      fontSize: '20px',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Muli',
      fontSize: '18px',
    },
    body1: {
      fontFamily: 'Muli',
    },
    body2: {
      fontFamily: 'Muli',
    },
    button: {
      fontFamily: 'Muli',
      fontWeight: 'bold',
    },
  },
  overrides: {
    root: {
      typography: {
        color: 'red',
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      betaPassword: '',
      beta: localStorage.getItem('betaAccess') || true,
    };
  }

  handleInputChange(e) {
    this.setState({
      betaPassword: e.target.value,
    });
  }

  handleFormSubmit() {
    if (this.state.betaPassword === 'test123') {
      localStorage.setItem('betaAccess', true);
      this.setState(prevState => ({
        ...prevState,
        beta: true,
      }));
    } else {
      localStorage.setItem('betaAccess', false);
    }
  }

  componentDidMount() {
    this.props.history.listen(location => {
      const { pathname } = location;
      setPageView(pathname);
      setPageViewSegment(pathname);
    });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarUtilsConfigurator />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to="/finance/directo" />;
                  }}
                />
                <Route
                  exact
                  path="/finance"
                  render={() => {
                    return <Redirect to="/finance/directo" />;
                  }}
                />
                <Route
                  path="/finance"
                  component={requireAuth(Layout(NotificationHandler(Home)))}
                />
                <Route
                  path="/orders"
                  component={requireAuth(Layout(NotificationHandler(Home)))}
                />
                <Route
                  path="/user/profile"
                  component={requireAuth(
                    Layout(NotificationHandler(HomeUserProfile))
                  )}
                />
                <Route
                  exact
                  path="/start"
                  component={noRequireAuth(NotificationHandler(Start))}
                />
                <Route
                  exact
                  path="/impersonate/:token"
                  component={Impersonate}
                />
                <Route
                  exact
                  path="/resetpassword/:hash"
                  component={ResetPassword}
                />
                <Route
                  path="/xtracker"
                  component={requireAuth(Layout(NotificationHandler(Home)))}
                />
              </Switch>
            </PersistGate>
          </Provider>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(App);
