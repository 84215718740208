import axios from 'axios';
import {
  FETCH_USER_ORDERS_START,
  FETCH_USER_ORDERS_SUCCESS,
  FETCH_USER_ORDERS_FAILURE,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  SET_FACTURAS_AVAILABLE_FILTERS,
  SET_FACTURAS_ACTIVE_FILTERS,
  SET_FILTERED_ORDER_FACTURAS,
  UPLOAD_BULK_FACTURAS_START,
  UPLOAD_BULK_FACTURAS_SUCCESS,
  UPLOAD_BULK_FACTURAS_FAILURE,
} from './types';
import { fetchSettingsResume } from './businessAction';

export const fetchUserOrders = () => dispatch => {
  dispatch({ type: FETCH_USER_ORDERS_START });
  axios
    // hideRejected: Nro de días que será visible una operacion rechazada
    .get('/api/order?hideRejected=7')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_USER_ORDERS_SUCCESS, payload: resData });

      return resData;
    })
    .catch(err => {
      dispatch({ type: FETCH_USER_ORDERS_FAILURE, payload: err });
    });
};

export const fetchOrder = (id, businessId) => dispatch => {
  dispatch({ type: FETCH_ORDER_START });
  axios
    .get(`/api/order/${id}?businessId=${businessId}`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_ORDER_SUCCESS, payload: resData });
      const filters = [
        ...new Set(
          resData.order.OrderFacturas.map(
            orderFactura => orderFactura.statusGroup
          )
        ),
      ];

      dispatch({
        type: SET_FACTURAS_AVAILABLE_FILTERS,
        payload: { facturasAvailableFilters: filters },
      });

      dispatch({
        type: SET_FACTURAS_ACTIVE_FILTERS,
        payload: { facturasActiveFilters: filters },
      });

      return resData;
    })
    .catch(error => {
      dispatch({ type: FETCH_ORDER_FAILURE, payload: { error } });
    });
};

export const uploadBulkFacturas =
  (id, bulkFacturas, businessId, userId) => async dispatch => {
    dispatch({ type: UPLOAD_BULK_FACTURAS_START });
    const formData = new FormData();

    for (let i = 0; i < bulkFacturas.length; i++) {
      formData.append('bulkFiles', bulkFacturas[i], `${bulkFacturas[i].name}`);
    }

    axios
      .post(`api/order/${id}/bulk-facturas-upload`, formData)
      .then(res => {
        const resData = res.data;
        dispatch({ type: UPLOAD_BULK_FACTURAS_SUCCESS, payload: resData });
        dispatch(fetchSettingsResume(businessId, userId));
        return resData;
      })
      .catch(err => {
        dispatch({ type: UPLOAD_BULK_FACTURAS_FAILURE, payload: err });
      });
  };

export const setFacturasActiveFilters = activeFilters => dispatch => {
  dispatch({
    type: SET_FACTURAS_ACTIVE_FILTERS,
    payload: { facturasActiveFilters: activeFilters },
  });
};

export const setFilteredFacturas = filteredOrderFacturas => dispatch => {
  dispatch({
    type: SET_FILTERED_ORDER_FACTURAS,
    payload: { filteredOrderFacturas },
  });
};
