import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PhoneInput from 'react-phone-input-2';
import whatsappLogo from '../../assets/logo-whatsapp.svg';
import Button from '../form/SubmitLoadingGradientButton';
import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    // background: '#F3F2F5',
    borderRadius: '17px',
    overflow: 'visible',

    '& .MuiDialogContent-root': {
      overflow: 'visible',
    },
  },
  dialogTitle: {
    color: '#575360',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    fontFamily: 'Muli',
    textTransform: 'uppercase',
    padding: '0 20px',
  },
  dialogText: {
    color: '#575360',
    fontSize: '1.2rem',
    fontFamily: 'Muli',
  },
  dialogButton: {
    width: '218px',
    height: '100%',
    fontSize: '20px',
    fontFamily: 'Muli',
    color: 'white',
    borderRadius: '30px',
    '&:not(.Mui-disabled)': {
      background: '#7300FF',
      '&:hover': {
        background: '#4d00ab',
      },
    },
  },
  actionsContainer: {
    borderRadius: '30px',
    display: 'flex',
    background: 'white',
    justifyContent: 'flex-end',
  },
  labelChecked: {
    color: '#1A73E7',
    fontFamily: 'Muli',
    fontSize: '16px',
  },
  labelUnchecked: {
    color: '#A8A4B3',
    fontFamily: 'Muli',
    fontSize: '16px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '0px 60px 50px 60px',
  },
});

const WhatsappOptinDialog = ({
  user,
  open,
  handleCloseDialog,
  handleSubmitForm,
}) => {
  const classes = useStyles();
  const { id: userId, nombre: username, email, Businesses } = user;
  const { name: companyName } = Businesses[0];
  const [checked, setChecked] = useState(false);
  const [whatsapp, setWhatsapp] = useState('+569');

  const handleCloseButtonClick = () => {
    handleCloseDialog();
    setWhatsapp('+569');
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleClick = () => {
    const containsPlusSymbol = whatsapp.includes('+');

    const data = containsPlusSymbol ? whatsapp : `+${whatsapp}`;
    const phone = containsPlusSymbol ? whatsapp.substring(1) : whatsapp;
    const queryParams = {
      phone,
      name: username,
      name_user: username,
      company_name: companyName,
      email,
      platform_source_uid: userId,
      platform_source_id: 1,
    };

    handleSubmitForm(userId, { name: 'WHATSAPP', data, queryParams });
  };

  return (
    <Dialog open={open} PaperProps={{ className: classes.dialog }}>
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={handleCloseButtonClick}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column" spacing={3} alignItems="center">
          <Grid item xs={12}>
            <img src={whatsappLogo} height="60px" alt="Whatsapp" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.dialogTitle}>
              Notificaciones de WhatsApp
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  value="checked"
                  inputProps={{
                    'aria-label':
                      'Para recibir notificaciones del Whatsapp de Xepelin, ingresa tu número aquí.',
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon style={{ color: '#1A73E7' }} />}
                />
              }
              label={
                <Typography
                  align="left"
                  className={
                    checked ? classes.labelChecked : classes.labelUnchecked
                  }
                >
                  Para recibir notificaciones del Whatsapp de Xepelin ingresa tu
                  número aquí.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={10}>
            <PhoneInput
              placeholder="Número contacto"
              name="telefono"
              inputStyle={{
                background: 'transparent',
                borderRadius: '40px',
                width: '100%',
                padding: '10.5px 20px 10.5px 60px',
                '&:focus': {
                  borderColor: '#7300FF',
                  boxShadow: '0 0 0 1px #7300FF',
                },
              }}
              value={whatsapp}
              onChange={e => setWhatsapp(e)}
              defaultCountry="cl"
              autoFormat={false}
              onlyCountries={['cl', 'mx', 'co', 'ar', 'pe', 'br']}
              isValid={(inputNumber, country, onlyCountries) => {
                return onlyCountries.some(country => {
                  return inputNumber.startsWith(country.dialCode);
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              label="Guardar"
              onClick={handleClick}
              dontvalidate="true"
              disabled={!checked}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

WhatsappOptinDialog.propTypes = {
  user: PropTypes.objectOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
};

export default WhatsappOptinDialog;
