import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const MAX_DECIMALS = 2;

const useStyles = makeStyles({
  greenTitle: {
    color: '#75B801',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    marginTop: 20,
  },
  marginTop: {
    marginTop: 10,
  },
  label: {
    color: '#6C6776',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '0.13px',
  },
  amount: {
    color: '#6C6776',
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '0.13px',
    '& p': {
      fontSize: '13px',
      lineHeight: '28px',
      letterSpacing: '0.13px',
    },
  },
  detailsTitle: {
    fontFamily: 'Muli',
    fontWeight: 'bold',
    color: '#575360',
    fontSize: 18,
    marginBottom: 15,
  },
  totalAmount: {
    fontWeight: 800,
  },
  customWidth: {
    maxWidth: 120,
  },
  help: {
    color: 'orange',
  },
  tooltip: {
    maxWidth: 15,
    marginRight: 'auto',
  },
  rateLabel: {
    maxWidth: 105,
  },
  rate: {
    maxWidth: 40,
  },
  rateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const OperationDetails = props => {
  const classes = useStyles();
  const {
    porcentajeAnticipo = 0,
    excedentes = 0,
    tasaInteres = 0,
    diferenciaPrecio = 0,
    costoOperacional = 0,
    totalAGirar = 0,
    mostrarTooltips = false,
  } = props;
  let rate = '';
  if (tasaInteres) {
    rate = !tasaInteres % 1 ? tasaInteres : tasaInteres.toFixed(MAX_DECIMALS);
  }
  return (
    <Grid item>
      <Grid container align="left">
        <Grid item xs={12} align="center">
          <Typography className={classes.detailsTitle}>
            Resumen de operación
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.label}>
                % Anticipo:
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="body2" className={classes.amount}>
                {porcentajeAnticipo ? `${porcentajeAnticipo}%` : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              {mostrarTooltips ? (
                <Tooltip title="Retención total" placement="left">
                  <Typography variant="body2" className={classes.label}>
                    Excedentes:
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className={classes.label}>
                  Excedentes:
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} align="right">
              <NumberFormat
                className={classes.amount}
                value={excedentes || '-'}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                displayType="text"
                prefix="$"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.rateContainer}>
            <Grid item xs={6} className={classes.rateLabel}>
              <Typography variant="body2" className={classes.label}>
                Tasa de interés:
              </Typography>
            </Grid>
            <Grid item xs={6} align="left" className={classes.tooltip}>
              <Tooltip
                title="Tasa de interés ponderada de las facturas de tu operación"
                classes={{ tooltip: classes.customWidth }}
              >
                <HelpOutlineIcon fontSize="inherit" className={classes.help} />
              </Tooltip>
            </Grid>
            <Grid item xs={6} align="right" className={classes.rate}>
              <Typography variant="body2" className={classes.amount}>
                {rate}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.label}>
                Diferencia de precio:
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              <NumberFormat
                className={classes.amount}
                value={diferenciaPrecio || '-'}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                displayType="text"
                prefix="$"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.label}>
                Asesoría digital:
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              <NumberFormat
                className={classes.amount}
                value={costoOperacional || '-'}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                displayType="text"
                prefix="$"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} align="center" direction="column">
          <Typography variant="body1" className={classes.greenTitle}>
            Total a girar
          </Typography>
          <Typography variant="h5" className={classes.totalAmount}>
            <NumberFormat
              value={totalAGirar || '-'}
              decimalSeparator=","
              thousandSeparator="."
              isNumericString
              displayType="text"
              prefix="$"
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OperationDetails.propTypes = {
  porcentajeAnticipo: PropTypes.number.isRequired,
  excedentes: PropTypes.number.isRequired,
  tasaInteres: PropTypes.number.isRequired,
  diferenciaPrecio: PropTypes.number.isRequired,
  costoOperacional: PropTypes.number.isRequired,
  totalAGirar: PropTypes.number.isRequired,
  mostrarTooltips: PropTypes.bool.isRequired,
};

export default OperationDetails;
