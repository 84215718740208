import { ADD_NOTIFICATION, REMOVE_FIRST_NOTIFICATION } from '../actions/types';

const initialState = {
  notifications: [],
};

export default (state = initialState, { type, payload }) => {
  let newNotifications;
  switch (type) {
    case ADD_NOTIFICATION:
      newNotifications = [...state.notifications, payload];
      return { ...state, notifications: newNotifications };
    case REMOVE_FIRST_NOTIFICATION:
      newNotifications = [...state.notifications];
      newNotifications.shift();
      return { ...state, notifications: newNotifications };
    default:
      return state;
  }
};
