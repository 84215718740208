import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    padding: '25px 30px',
    borderRadius: props => props.borderRadius || 17,
    background: 'white',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: props => props.gap || props.gridGap || '30px',
    gridAutoRows: 'min-content',
    width: '100%',
    boxSizing: 'border-box',
    justifyItems: props => props.justifyItems || '',

    '&.bordered': {
      border: '1px solid #E4E1E9',
    },
  },
  titleContainer: {
    display: 'grid',
    gridGap: '20px',
    gridTemplateColumns: props => (props.buttons ? '1fr min-content' : '1fr'),
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  title: {
    color: props => props.titleColor || '',
    textAlign: props => props.titleAlign || 'left',
    font: props => props.titleFont || '',
  },
  childrenContainer: {
    display: 'grid',
    gridTemplateColumns: props =>
      `repeat(${props.cols || '1'}, minmax(0, 1fr))`,
    gridGap: props => props.gap || props.gridGap || 20,
  },
});
const Section = props => {
  const classes = useStyles(props);
  const { bordered, titleVariant, buttons, children, title } = props;
  return (
    <Box className={`${classes.container} ${bordered && 'bordered'}`}>
      {title && (
        <Box className={classes.titleContainer}>
          {title && (
            <Typography
              variant={titleVariant || 'h5'}
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {buttons && <Box>{buttons}</Box>}
        </Box>
      )}
      {children && <Box className={classes.childrenContainer}>{children}</Box>}
    </Box>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  titleAlign: PropTypes.string,
  bordered: PropTypes.bool,
  titleVariant: PropTypes.string,
  buttons: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  title: null,
  titleAlign: 'left',
  bordered: false,
  titleVariant: null,
  buttons: null,
};

export default Section;
