import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import logoSii from '../assets/logosii.png';
import { ReactComponent as BackButton } from '../assets/back-arrow.svg';
import GradientButton from './form/GradientButton';
import Input from './form/Input';

const useStyles = {
  backButton: {
    position: 'absolute',
    marginLeft: 80,
    marginTop: 40,
  },
  logoSii: {
    width: 140,
    margin: 30,
    WebkitFilter: 'grayscale(1)',
  },
  subTitle: {
    margin: 10,
    textAlign: 'center',
    color: 'gray',
    marginBottom: 70,
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  wrapper: {
    margin: 1,
    position: 'relative',
  },
};

class ClaveSii extends Component {
  handleSubmit() {
    const { handleSubmit } = this.props;
    handleSubmit();
  }

  render() {
    const {
      classes,
      handleNext,
      rutEmpresa,
      handleInputChange,
      claveSII,
      submit,
    } = this.props;
    return (
      <>
        <Grid
          container
          alignItems="start"
          justify="start"
          direction="column"
          xs={12}
        >
          <IconButton
            disableFocusRipple
            className={classes.backButton}
            onClick={() => handleNext(1)}
          >
            <BackButton />
          </IconButton>
        </Grid>
        <Grid container xs={10} sm={5} alignItems="center" justify="center">
          <img src={logoSii} className={classes.logoSii} alt="Logo" />
          <Typography variant="body2" className={classes.subTitle}>
            Ahora necesitamos las credenciales de la empresa para poder cargar
            la información del SII. Pierde cuidado, Xepelin solo leerá la
            información y no realizará ningún cambio. Además, las contraseñas se
            guardan encriptadas por seguridad.
          </Typography>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Input
              label="RUT Empresa"
              autoComplete="new-password"
              value={rutEmpresa}
              onChange={e => handleInputChange('rutEmpresa', e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <Input
              label="Clave SII"
              type="password"
              autoComplete="new-password"
              value={claveSII}
              onChange={e => handleInputChange('claveSII', e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <div className={classes.wrapper}>
              <GradientButton disabled={submit} type="submit">
                Registrarme
              </GradientButton>
              {submit && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </ValidatorForm>
        </Grid>
      </>
    );
  }
}

ClaveSii.propTypes = {
  rutEmpresa: PropTypes.string.isRequired,
  submit: PropTypes.string.isRequired,
  claveSII: PropTypes.string.isRequired,
  classes: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    rutEmpresa: state.register.rutEmpresa,
    claveSII: state.register.claveSII,
    submit: state.register.submit,
    errors: state.register.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleInputChange: (field, value) => {
      dispatch({ type: 'REGISTER_INPUT_CHANGE', field, value });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ClaveSii));
