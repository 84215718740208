import React from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon, Link, Icon } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const SideButton = withStyles({
  root: {
    color: '#A8A4B3',
    margin: 0,
    width: '100%',
    whiteSpace: 'nowrap',
    borderRadius: 100,
    textTransform: 'none',
    marginRight: 0,
    backgroundColor: 'rgba(243, 242, 245, 0)',
    boxShadow: 'none',
    transition: '.2s',
    fontSize: 14,
    fontWeight: 600,
    position: 'relative',
    padding: '10px 18px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#565360',
    },
    '&:active': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    justifyContent: 'flex-start',
  },
})(Button);

const useStyles = makeStyles({
  buttonsActive: {
    backgroundColor: 'rgba(243, 242, 245, 1)',
    transition: '.2s',
    fontSize: 14,
    color: '#575360',
    boxShadow: 'none',
    fontWeight: 600,
    '&:hover': {
      color: '#575360',
      backgroundColor: 'rgb(243, 242, 245)',
    },
    '&:active': {
      backgroundColor: 'rgb(243, 242, 245)',
    },
    '&:focus': {
      backgroundColor: 'rgb(243, 242, 245)',
    },
  },
  notification: {
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      height: 8,
      width: 8,
      borderRadius: 100,
      backgroundColor: '#F87348',
      left: 8,
      top: 'calc(50% - 4px)',
      zIndex: 1,
    },
  },
  disabled: {
    backgroundColor: 'transparent',
  },
  hasIcon: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: 'min-content 1fr',
    justifyItems: 'flex-start',
  },
  indicator: {
    marginLeft: -120,
    marginTop: -8,
    borderLeft: '26px solid #8100FF',
    borderTop: '13px solid transparent',
    borderBottom: '13px solid transparent',
    transform: 'translate(-50%,-50%)',
    overflow: 'hidden',
    animation: '$animate 1.5s linear infinite',
  },
  indicatorCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-505,-20%)',
  },
  '@keyframes animate': {
    '0%': {
      transform: 'translate(-10%,-10%) translatex(-10px)',
    },
    '50%': {
      transform: 'translate(-10%,-10%) translatex(10px)',
    },
    '100%': {
      transform: 'translate(-10%,-10%) translatex(-10px) ',
    },
  },
});

const CollapsedButton = withStyles({
  root: {
    display: 'grid',
    gridGap: 10,
    justifyItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    cursor: 'pointer',
    padding: '20px 10px',
    borderRadius: '6px',
    color: '#5d5966',
    textDecoration: 'none',
    gridAutoRows: 'min-content',
    height: 'min-content',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
      color: '#F87C54',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&.active': {
      background: 'rgb(243, 242, 245)',
      color: '#F87C54',
    },
  },
})(Link);

const SideBarNavigationButton = ({
  collapsed,
  icon,
  onClick,
  disabled,
  children,
  active,
  notification,
  indicator,
  dataQa,
}) => {
  const classes = useStyles();

  const assistantIndicator = (
    <div className={classes.indicatorCenter}>
      <div className={classes.indicator} />
    </div>
  );

  return (
    <>
      {collapsed ? (
        <CollapsedButton
          color="primary"
          onClick={onClick}
          disabled={disabled}
          className={active ? 'active' : ''}
          data-qa={dataQa}
        >
          {icon && <Icon component={icon} />}
          {children}
        </CollapsedButton>
      ) : (
        <SideButton
          disabled={disabled}
          aria-label="delete"
          classes={{
            label: icon ? classes.hasIcon : '',
          }}
          className={`
        ${active ? classes.buttonsActive : ''} 
        ${notification ? classes.notification : ''}
        ${icon ? classes.hasIcon : ''}
      `}
          onClick={onClick}
          data-qa={dataQa}
        >
          {indicator && assistantIndicator}
          {icon && <SvgIcon fontSize="small" component={icon} />}
          {children}
        </SideButton>
      )}
    </>
  );
};

SideBarNavigationButton.defaultProps = {
  collapsed: false,
  active: false,
  notification: false,
  disabled: false,
  icon: null,
  children: null,
  indicator: false,
  dataQa: '',
};

SideBarNavigationButton.propTypes = {
  collapsed: PropTypes.bool,
  active: PropTypes.bool,
  notification: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  indicator: PropTypes.bool,
  dataQa: PropTypes.string,
};

export default SideBarNavigationButton;
