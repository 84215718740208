export const DIRECT_FINANCING = 'DIRECT_FINANCING';
export const EARLY_PAYMENT = 'EARLY_PAYMENT';

export const DIRECT_FINANCING_LABEL = 'Financiamiento directo';
export const EARLY_PAYMENT_LABEL = 'Pronto pago';

export const LONG_DATE_FORMAT = 'DD MMMM YYYY';

export const PREVIRED_LABEL = 'Previred';
export const DICOM_LABEL = 'Dicom';
export const TGR_LABEL = 'TGR';

const FLOW_STEPS_ORDER = {
  IN_REVIEW: {
    index: 0,
    active: 1,
    label: 'En revisión',
    warning: false,
    error: false,
  },
  APPROVED_SUCCESS: {
    index: 1,
    active: 1,
    label: 'Aprobado',
    warning: false,
    error: false,
  },
  APPROVED_DEFAULT: {
    index: 1,
    active: 0,
    label: 'Aprobado',
    warning: false,
    error: false,
  },
  REJECTED: {
    index: 1,
    active: 1,
    label: 'Rechazado',
    warning: false,
    error: true,
  },
  TRANSFERRED_SUCCESS: {
    index: 2,
    active: 1,
    label: 'Transferido',
    warning: false,
    error: false,
  },
  TRANSFERRED_DEFAULT: {
    index: 2,
    active: 0,
    label: 'Transferido',
    warning: false,
    error: false,
  },
  CRITICAL_STATE: {
    index: 2,
    active: 1,
    label: 'Estado Crítico',
    warning: true,
    error: false,
  },
  DELINQUENT: {
    index: 2,
    active: 1,
    label: 'Mora',
    warning: true,
    error: false,
  },
  PAID_SUCCESS: {
    index: 3,
    active: 1,
    label: 'Pagado',
    warning: false,
    error: false,
  },
  DEFAULT: {
    index: 3,
    active: 1,
    label: 'No pagado',
    warning: false,
    error: true,
  },
  PAID_DEFAULT: {
    index: 3,
    active: 0,
    label: 'Pagado',
    warning: false,
    error: false,
  },
  RENEGOTIATION: {
    index: 3,
    active: 1,
    label: 'Renegociado',
    warning: true,
    error: false,
  },
};

export const FLOWS_ORDER = {
  IN_REVIEW: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_DEFAULT,
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  APPROVED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  REJECTED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.REJECTED,
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  ACTIVE_CRITICAL_STATE: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.CRITICAL_STATE,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  ACTIVE_DELINQUENT: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.DELINQUENT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  ACTIVE: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  TRANSFERRED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  PAID: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    FLOW_STEPS_ORDER.PAID_SUCCESS,
  ],
  RENEGOTIATION: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    FLOW_STEPS_ORDER.RENEGOTIATION,
  ],
  DEFAULT: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    FLOW_STEPS_ORDER.DEFAULT,
  ],
};

export const ORDER_STATUS_GROUPS = {
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  TRANSFERRED: 'TRANSFERRED',
  PAID: 'PAID',
  REJECTED: 'REJECTED',
  RENEGOTIATION: 'RENEGOTIATION',
};

const BASE_STATUSES = {
  IN_REVIEW: 'IN_REVIEW',
  REVIEWED: 'REVIEWED',
  APPEALED: 'APPEALED',
  APPROVED: 'APPROVED',
  APPROVED_FUND: 'APPROVED_FUND',
  VERIFIED: 'VERIFIED',
  TO_DEPOSIT: 'TO_DEPOSIT',
  ACTIVE: 'ACTIVE',
  PAID: 'PAID',
  COMPLETE: 'COMPLETE',
  DEFAULT: 'DEFAULT',
  REJECTED: 'REJECTED',
  POST_VERIFIED: 'POST_VERIFIED',
};

export const ORDER_FACTURAS_STATUSES = {
  ...BASE_STATUSES,
  CEDE: 'CEDE',
  SOFT_DELINQUENCY: 'SOFT_DELINQUENCY',
  HARD_DELINQUENCY: 'HARD_DELINQUENCY',
  DELINQUENT: 'DELINQUENT',
  HARD_COLLECTION: 'HARD_COLLECTION',
  REPLACED: 'REPLACED',
  REJECTED: 'REJECTED',
};

export const ORDER_STATUSES = {
  ...BASE_STATUSES,
  PV_APPROVED: 'PV_APPROVED',
  TRANSFERRED: 'TRANSFERRED',
  RENEGOTIATION: 'RENEGOTIATION',
};

export const SNACKBAR_SUCCESS = 'success';
export const SNACKBAR_ERROR = 'error';
export const SNACKBAR_INFO = 'info';
export const SNACKBAR_WARNING = 'warning';

export const CUENTA_CORRIENTE = 'CUENTA_CORRIENTE';
export const CUENTA_VISTA = 'CUENTA_VISTA';
export const CUENTA_AHORRO = 'CUENTA_AHORRO';

export const EARLY_PAYMENT_TAB = 'prontoPago';
export const DIRECT_FINANCING_TAB = 'finDirecto';

export const BUTTON_LABEL = {
  ELIMINAR: 'Eliminar',
  AGREGAR: 'Agregar',
  EDITAR: 'Editar',
  GUARDAR: 'Guardar',
  CAMBIAR: 'Cambiar',
  DESCARGAR_PDF: 'Descargar PDF',
  VOLVER: 'Volver',
  SIGUIENTE: 'Siguiente',
  COMENZAR: 'Comenzar',
};
// TYPE FILES
export const CONTRACT = 'CONTRACT';
export const LEGAL_REPORT = 'LEGAL_REPORT';
export const TERMS = 'TERMS';
export const POLICY = 'POLICY';
export const CONTRACT_LABEL = 'Contrato';
export const LEGAL_REPORT_LABEL = 'Informe legal';
export const TERMS_LABEL = 'Términos y condiciones';
export const POLICY_LABEL = 'Politicas de privacidad';

export const VALIDATION_TYPES = {
  REQUIRED: 'required',
  VALID_RUT: 'isValidRut',
  VALID_EMAIL: 'isEmail',
  VALID_PHONE: 'isValidPhone',
  MATCH_CONFIRM_PASSWORD: 'isPasswordMatch',
};

export const VALIDATION_MESSAGES = {
  REQUIRED_MSG: 'Campo requerido',
  VALID_RUT_MSG: 'Rut incorrecto',
  VALID_EMAIL_MSG: 'Email incorrecto',
  VALID_PHONE_MSG: 'Por favor ingresa un número válido (ejemplo: +56123456789)',
  MATCH_CONFIRM_PASSWORD_MSG: 'La contraseña no coincide',
};

export const JOB_STATUSES = {
  QUEUE: 'QUEUE',
  WORKING: 'WORKING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const DEFAULT_STATE_CASES = {
  DF_DEFAULT: 'DF_DEFAULT',
  DF_FAILED: 'DF_FAILED',
  DF_LOADING: 'DF_LOADING',
  DF_UPDATE: 'DF_UPDATE',
};

export const SERVICE_WHATSAPP = 'WHATSAPP';
export const SERVICE_NOTIFICATIONS = 'NOTIFICATIONS';
export const SERVICE_UPDATES = 'UPDATES';
export const SERVICE_EMAIL = 'EMAIL';

export const DIALOG_TYPE_NORMAL = 'normal';
export const DIALOG_TYPE_ASSISTANT = 'assistant';
export const DIALOG_TYPE_BANKACCOUNT = 'bankaccount';
export const DIALOG_TYPE_WHATSAPP = 'whatsapp';

export const PDF = 'pdf';
export const PFX = 'pfx';

export const ASSISTANT_TYPES = { OPERATION: 'operation', PROFILE: 'profile' };
export const ASSISTANT_STEPS = {
  STEP_OPERATION_SII: 0,
  STEP_OPERATION_LOADING: 1,
  STEP_OPERATION_TYPE: 2,
  STEP_OPERATION_SELECT_INVOICES: 3,
  STEP_OPERATION_SELECT_DATES: 4,
  STEP_PROFILE_SII: 0,
  STEP_PROFILE_LEGAL: 1,
  STEP_PROFILE_BANK_ACCOUNT: 2,
  STEP_PROFILE_PREVIRED: 3,
  STEP_PROFILE_FINISH: 4,
};
export const ASSISTANT_STEPS_CONFIG = {
  operation: [
    {
      selector: '.MuiDialog-paperFullWidth',
      title: 'Ingresa tus credenciales',
    },
    {
      selector: '#default-state-loading',
      title: 'Estamos cargando tus facturas ☕️',
      stepInteraction: false,
    },
    {
      selector: '#grid-tabs',
      title: 'Selecciona el financiamento',
    },
    {
      selector: '#main-table',
      title: 'Elige tus facturas',
    },
    {
      selector: '#date-table',
      title: 'Selecciona las fechas',
    },
  ],
  profile: [
    {
      selector: '#profile',
      title: 'SII',
    },
    {
      selector: '#profile',
      title: 'Información Legal',
    },
    {
      selector: '#profile',
      title: 'Cuentas Bancarias',
    },
    {
      selector: '#profile',
      title: 'Previred',
    },
  ],
};
