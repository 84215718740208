import { TOGGLE_DRAWER_FINANC, WAS_IN_FINANCE } from '../actions/types';

const initialState = {
  isOpenDrawerFinanc: false,
  wasInFinance: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_DRAWER_FINANC:
      return {
        ...state,
        isOpenDrawerFinanc: !state.isOpenDrawerFinanc,
      };
    case WAS_IN_FINANCE:
      return { ...state, wasInFinance: payload };
    default:
      return state;
  }
};
