import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import clsx from 'clsx';

import Container from './Container';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 9,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#d4d1df',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#d4d1df',
    },
  },
  line: {
    borderTopWidth: 28,
    width: 2,
    margin: '0 15px',
    borderColor: '#d4d1df',
  },
})(StepConnector);

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 'max-content',
    margin: '0 auto',
  },
  stepper: {
    backgroundColor: 'white',
    borderRadius: 100,
    padding: '8px 8px 8px 0',
  },
  root: {
    width: '100%',
  },
  step: {
    '& $completed': {
      color: '#85c000',
      fontWeight: '600',
      textDecoration: 'line-through',
    },
    '& $active': {
      color: '#f07d4a',
      fontWeight: '600',
    },
  },
  stepLabel: {
    color: '#d4d1df',
    fontWeight: '600',
  },
  alternativeLabel: {},
  active: {}, // needed so that the &$active tag works
  completed: {},
  disabled: {},
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#d4d1df',
    zIndex: 1,
    color: '#fff',
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  },
  active: {
    background: '#f07d4a',
  },
  completed: {
    background: '#85c000',
  },
});

const ColorlibStepIcon = props => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: <DescriptionOutlinedIcon />,
    2: <EventOutlinedIcon />,
    3: <NearMeOutlinedIcon />,
  };

  return (
    <span
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </span>
  );
};

const HomeSteps = props => {
  const classes = useStyles();
  const { steps, step } = props;

  return (
    <Container gridTemplate="1fr 280px" gridGap={30}>
      <Container className={classes.container}>
        <Stepper
          activeStep={step}
          connector={<QontoConnector />}
          className={classes.stepper}
          classes={{
            root: classes.step,
          }}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  label: classes.stepLabel,
                  completed: classes.completed,
                  active: classes.active,
                }}
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.active,
                    active: classes.active,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Container>
  );
};

HomeSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  step: PropTypes.number.isRequired,
};

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default HomeSteps;
