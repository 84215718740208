// authentication.js
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  SET_CURRENT_USER,
  LOGOUT_USER,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  UPDATE_USER_INFO_START,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  UPDATE_BUSINESS_INFO_START,
  UPDATE_BUSINESS_INFO_SUCCESS,
  UPDATE_BUSINESS_INFO_FAILURE,
} from './types';
import setAuthToken from '../helpers/setAuthToken';
import {
  trackSegment,
  identifyLoggedUser,
  analyticsReset,
} from '../helpers/trackingSegment';
import configureStore from '../modules/store';
import { addNotification } from './notificationsAction';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../helpers/Constants';

const { persistor } = configureStore();

const identifyAndTrackUpdatedUser = userData => {
  const {
    oldUserData: { name: oldName, phone: oldPhone },
    newUserData: { name: newName, phone: newPhone },
  } = userData;
  let trackMsg;
  let trackPayload;

  if (newName !== oldName) {
    trackMsg = 'Name edit';
    trackPayload = { name: newName };
  } else if (newPhone !== oldPhone) {
    trackMsg = 'Phone Edit';
    trackPayload = {
      oldPhone,
      phone: newPhone,
    };
  }
  trackSegment(trackMsg, trackPayload);
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutClearUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const loginWithToken =
  (token, trackSegmentInfo = {}) =>
  dispatch => {
    const decoded = jwtDecode(token);
    localStorage.setItem('jwtToken', token);
    setAuthToken(token);
    persistor.persist();
    const {
      utmCampaign = null,
      utmMedium = null,
      utmSource = null,
    } = trackSegmentInfo;
    identifyLoggedUser(decoded.data, utmCampaign, utmMedium, utmSource);
    dispatch({
      type: LOGIN_SUCCESS,
    });
    dispatch(setCurrentUser({ decoded: decoded.data, token }));
  };

export const loginError = err => dispatch =>
  dispatch({
    type: LOGIN_ERROR,
    payload: err.response ? err.response.data : 'empty error',
  });

export const loginUser = (user, trackSegmentInfo) => dispatch => {
  dispatch({
    type: LOGIN_BEGIN,
  });
  axios
    .post('/api/user/login', user)
    .then(res => {
      const { token } = res.data;
      dispatch(loginWithToken(token, trackSegmentInfo, dispatch));
    })
    .then(() => {
      const { utmCampaign, utmMedium, utmSource } = trackSegmentInfo;
      trackSegment('User Signed In', { utmCampaign, utmMedium, utmSource });
    })
    .catch(err => {
      dispatch(loginError(err, dispatch));
    });
};

export const logoutUser = (history, email) => dispatch => {
  persistor.purge().then(() => {
    trackSegment('User Signed Out', { email });
    analyticsReset();
    window.Intercom('shutdown'); // Closes intercom session .
    persistor.pause();
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(logoutClearUser());
    history.push('/start');
  });
};

export const updateUserInfo = (userInfo, oldUserData) => dispatch => {
  dispatch({ type: UPDATE_USER_INFO_START });
  axios
    .put('/api/user/', { ...userInfo })
    .then(res => {
      const { token } = res.data;
      const decoded = jwtDecode(token);

      const { nombre: name = '', telefono: phone = '' } = decoded.data;
      const userData = {
        oldUserData,
        newUserData: { name, phone },
      };
      identifyAndTrackUpdatedUser(userData);
      dispatch(loginWithToken(token, dispatch));
      dispatch({ type: UPDATE_USER_INFO_SUCCESS });
      dispatch(addNotification('Información actualizada', SNACKBAR_SUCCESS));
    })
    .catch(error => {
      dispatch({
        type: UPDATE_USER_INFO_FAILURE,
        payload: error.response.data.error,
      });
      if (!userInfo.currentPassword) {
        dispatch(
          addNotification('Error al actualizar la información', SNACKBAR_ERROR)
        );
      }
    });
};

export const updateBusinessInfo = business => dispatch => {
  const { address, id } = business;
  dispatch({ type: UPDATE_BUSINESS_INFO_START });
  axios
    .put(`/api/business/${id}`, { address })
    .then(res => {
      const { token } = res.data;
      dispatch(loginWithToken(token, dispatch));
      dispatch({ type: UPDATE_BUSINESS_INFO_SUCCESS });
      dispatch(addNotification('Información actualizada', SNACKBAR_SUCCESS));
    })
    .catch(error => {
      dispatch({ type: UPDATE_BUSINESS_INFO_FAILURE, payload: error });
      dispatch(
        addNotification('Error al actualizar la información', SNACKBAR_ERROR)
      );
    });
};
