import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TopBar from './components/TopBar';
import { logoutUser } from './actions/authentication';
import Container from './components/elements/Container';
import { initGA } from './helpers/trackingGA';
import { toggleDrawerFinanciamiento } from './actions/uiStatesAction';
import { loadSiiDataStart } from './actions/sii';
import { wsConnect } from './actions/websocket';

const useStyles = {};

export default function (ComposedComponent) {
  class Layout extends Component {
    constructor(props) {
      super(props);

      const {
        email,
        nombre,
        Businesses: [{ id } = {}] = [],
      } = this.props.auth.user;
      initGA();
      if (process.env.NODE_ENV === 'production') {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_KEY,
          user_id: id,
          name: nombre,
          email,
          created_at: 1234567890,
        });
      }
    }

    componentDidMount = () => {
      const { wsconnected, wsConnect: wsCx } = this.props;
      if (!wsconnected) {
        wsCx();
      }
    };

    componentDidUpdate = prevProps => {
      const { wsconnected, auth, loadSiiDataStart } = this.props;
      const { token = '' } = auth;
      if (!prevProps.wsconnected && wsconnected) {
        loadSiiDataStart(token);
      }
    };

    handleShowDrawer = () => {
      this.props.toggleDrawerFinanciamiento();
    };

    render() {
      const { auth, logoutUser, history } = this.props;

      return (
        <Container padding={0} gridGap="0px" id="pageContainer">
          <Container sticky stickyTop={0} zIndex={1000}>
            {auth && auth.user && auth.user.Businesses && (
              <TopBar
                user={auth.user}
                logoutUser={() => logoutUser(history)}
                handleShowDrawer={this.handleShowDrawer}
              />
            )}
          </Container>
          <ComposedComponent />
        </Container>
      );
    }
  }

  Layout.propTypes = {
    auth: PropTypes.objectOf(Object).isRequired,
    history: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
    loadSiiDataStart: PropTypes.func.isRequired,
    toggleDrawerFinanciamiento: PropTypes.func.isRequired,
    wsconnected: PropTypes.bool.isRequired,
    wsConnect: PropTypes.func.isRequired,
  };

  function mapStateToProps(state) {
    return {
      auth: state.auth,
      wsconnected: state.ws.wsconnected,
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
      logoutUser: history => dispatch(logoutUser(history)),
      toggleDrawerFinanciamiento: () => dispatch(toggleDrawerFinanciamiento()),
      wsConnect: () => dispatch(wsConnect()),
      loadSiiDataStart: token => dispatch(loadSiiDataStart(token)),
    };
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(useStyles),
    withRouter
  )(Layout);
}
