import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Hidden, Grid } from '@material-ui/core';
import { toggleDrawerFinanciamiento } from './actions/uiStatesAction';
import HomeSideBar from './components/HomeSideBar';
import Container from './components/elements/Container';
import HomeMobileSideBar from './components/HomeMobileSideBar';
import HomeFunding from './components/HomeFunding';
import OrderStatus from './components/OrderStatus';
import OrderDetails from './components/OrderDetails';
import XtrackerPage from './components/XtrackerPage';
import { fetchSettingsResume } from './actions/businessAction';
import { fetchStakeholders } from './actions/stakeholders';
import { fetchHolidays } from './actions/holidaysAction';

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOpenDrawer = useSelector(state => state.uiStates.isOpenDrawerFinanc);
  const wasInFinance = useSelector(state => state.uiStates.wasInFinance);
  const authState = useSelector(state => state.auth);

  useEffect(() => {
    const {
      user: { id: userId, Businesses = [] },
    } = authState;
    const [{ id } = {}] = Businesses;
    if (!wasInFinance) {
      dispatch(fetchSettingsResume(id, userId));
      dispatch(fetchHolidays());
    }
    dispatch(fetchStakeholders(id));
  }, [authState, location, wasInFinance, dispatch]);

  const handleShowDrawer = () => {
    dispatch(toggleDrawerFinanciamiento());
  };

  return (
    <Container gridTemplate="min-content 1fr" gridGap="0px">
      <Hidden smUp>
        <HomeMobileSideBar
          open={isOpenDrawer}
          handleShowDrawer={handleShowDrawer}
        />
      </Hidden>
      <Container sticky stickyTop={50}>
        <HomeSideBar />
      </Container>
      <Container gridGap="0px">
        <Grid container direction="column">
          <Grid item xs={12}>
            <Switch>
              <Route exact path="/orders" component={OrderStatus} />
              <Route
                exact
                path="/orders/:id"
                render={({ match }) => {
                  return /^\d+$/.test(match.params.id) ? (
                    <OrderDetails orderId={match.params.id} />
                  ) : null;
                }}
              />
              <Route path="/finance" component={HomeFunding} />
              <Route
                exact
                path="/xtracker"
                component={() => (
                  <XtrackerPage
                    businessId={authState?.user?.Businesses?.[0].id}
                  />
                )}
              />
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Home;
