import axios from 'axios';
import {
  SET_FACTURAS_ACTIVE_FILTERS,
  UPLOAD_FACTURA_PDF_START,
  UPLOAD_FACTURA_PDF_SUCCESS,
  UPLOAD_FACTURA_PDF_FAILURE,
  SET_FILTERED_ORDER_FACTURAS,
  DELETE_FACTURA_PDF_START,
  DELETE_FACTURA_PDF_SUCCESS,
  DELETE_FACTURA_PDF_FAILURE,
} from './types';
import { fetchSettingsResume } from './businessAction';

export const uploadFacturaPdf = (id, pdf, businessId, userId) => dispatch => {
  dispatch({
    type: UPLOAD_FACTURA_PDF_START,
    payload: { uploadingFacturaId: id },
  });
  const formData = new FormData();
  formData.append('pdf', pdf, pdf.name);
  axios
    .post(`/api/orderfactura/${id}/pdf-factura`, formData)
    .then(res => {
      const resData = res.data;
      dispatch({ type: UPLOAD_FACTURA_PDF_SUCCESS, payload: resData });
      dispatch(fetchSettingsResume(businessId, userId));
      return resData;
    })
    .catch(err => {
      dispatch({ type: UPLOAD_FACTURA_PDF_FAILURE, payload: err });
    });
};

export const deletePdf =
  (orderFacturaId, pdfId, businessId, userId) => dispatch => {
    dispatch({ type: DELETE_FACTURA_PDF_START });
    axios
      .delete(`/api/orderfactura/${orderFacturaId}/pdf-factura?pdfId=${pdfId}`)
      .then(res => {
        const resData = res.data;
        dispatch({ type: DELETE_FACTURA_PDF_SUCCESS, payload: resData });
        dispatch(fetchSettingsResume(businessId, userId));
        return resData;
      })
      .catch(err => {
        dispatch({ type: DELETE_FACTURA_PDF_FAILURE, payload: err });
      });
  };

export const setFacturasActiveFilters = activeFilters => dispatch => {
  dispatch({
    type: SET_FACTURAS_ACTIVE_FILTERS,
    payload: { facturasActiveFilters: activeFilters },
  });
};

export const setFilteredFacturas = filteredOrderFacturas => dispatch => {
  dispatch({
    type: SET_FILTERED_ORDER_FACTURAS,
    payload: { filteredOrderFacturas },
  });
};
