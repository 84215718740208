import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Tab as MuiTab } from '@material-ui/core';
import Pill from './Pill';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: '1fr min-content',
    gridAutoRows: 'min-content',
    alignItems: 'center',
    padding: '0 30px',
  },
  withIcon: {
    gridTemplateColumns: '1fr min-content',
  },
}));

const Tab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    margin: 0,
    padding: 0,
    opacity: 1,
    minWidth: 'max-content',
    '&:focus': {
      opacity: 1,
    },
    outline: 'none',
  },
  selected: {
    fontWeight: 600,
  },
}))(props => {
  const classes = useStyles();
  const { pillContent = '' } = props;
  const tabProps = { ...props };
  delete tabProps.pillContent;
  return (
    <Box className={`${classes.container} ${pillContent && classes.withIcon}`}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiTab disableRipple {...tabProps} />
      {!Number.isNaN(pillContent) && (
        <Pill pillcontent={pillContent} color="primary" />
      )}
    </Box>
  );
});
export default Tab;
