import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import tour1 from '../../assets/tour1.svg';
import tour2 from '../../assets/tour2.svg';
import tour3 from '../../assets/tour3.svg';
import BaseDialog from './BaseDialog';
import GradientButton from '../form/GradientButton';
import { BUTTON_LABEL } from '../../helpers/Constants';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
  actions: {
    marginBottom: 20,
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  textBody: {
    minHeight: 206,
  },
  action: {
    minWidth: 40,
    height: 40,
    borderRadius: 50,
    color: '#FFF',
    padding: 0,
  },
  forward: {
    background: '#7300FF',
    '&:hover': {
      background: '#7300ffe0',
      borderColor: '#0062cc',
    },
  },
  list: {
    listStyle: 'inside',
    padding: 0,
  },
  imageTour: {
    marginLeft: -25,
    marginTop: -10,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 700,
    color: '#7300FF',
    marginTop: 25,
    marginBottom: 25,
  },
  titleStep2: {
    marginTop: 45,
  },
  body1: {
    font: '16px/19px Muli',
    fontWeight: 700,
    color: '#A2A2A2',
  },
  body2: {
    marginTop: 20,
    font: '16px/19px Muli',
    fontWeight: 700,
    color: '#A2A2A2',
  },
  carousel: {
    background: 'rgb(84, 71, 181, 0.15)',
    borderRadius: 35,
    width: 90,
    height: 20,
    margin: 30,
    padding: '3px 10px',
    justifyContent: 'space-between',
  },
  carouselStep: {
    width: 14,
    height: 14,
    borderRadius: 50,
    background: '#FFF',
  },
  active: {
    background: '#901DFF',
  },
});

const WelcomeDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const { COMENZAR, SIGUIENTE } = BUTTON_LABEL;

  const beginAction = () => {
    handleClose();
    history.push('/finance');
  };

  const go = () => {
    setStep(step + 1);
  };
  const back = () => {
    setStep(step - 1);
  };

  return (
    <BaseDialog isOpen={open} handleClose={handleClose} withCloseButton>
      {step === 1 ? (
        /*
         * BODY STEP 1
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour1}
              width={430}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography variant="body2" className={classes.title}>
              Bienvenido a Xepelin
            </Typography>
            <Typography variant="body2" className={classes.body1}>
              A partir de ahora podrás conseguir el dinero
              <br />
              que necesitas de manera simple, rápida y<br />
              segura.
            </Typography>
            <Typography variant="body2" className={classes.body2}>
              Abróchate el cinturón y despega hacia esta
              <br />
              nueva experencia financiera
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.carousel}>
              <Grid
                item
                className={`${classes.carouselStep} ${classes.active}`}
              />
              <Grid item className={classes.carouselStep} />
              <Grid item className={classes.carouselStep} />
            </Grid>
          </Grid>
          <Grid item xs={9} className={classes.actions}>
            <GradientButton onClick={() => go()}>
              {SIGUIENTE} <ArrowRightAltIcon fontSize="small" />
            </GradientButton>
          </Grid>
        </Grid>
      ) : step === 2 ? (
        /*
         * BODY STEP 2
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour2}
              width={430}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography
              variant="body2"
              className={`${classes.title} ${classes.titleStep2}`}
            >
              ¿Cómo funciona la plataforma?
            </Typography>
            <Typography variant="body2" className={classes.body1}>
              Tú sólo ingresas con tu cuenta del SII y
              <br />
              nosotros nos encargamos de buscar tus
              <br />
              facturas para que puedas financiarte
              <br />
              rápidamente.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.carousel}>
              <Grid item className={classes.carouselStep} />
              <Grid
                item
                className={`${classes.carouselStep} ${classes.active}`}
              />
              <Grid item className={classes.carouselStep} />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button
              variant="contained"
              size="medium"
              className={classes.action}
              onClick={() => back()}
            >
              <ArrowLeftIcon />
            </Button>
            <Button
              variant="contained"
              size="medium"
              className={`${classes.action} ${classes.forward}`}
              onClick={() => go()}
            >
              <ArrowRightIcon />
            </Button>
          </Grid>
        </Grid>
      ) : (
        /*
         * BODY STEP 3
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour3}
              width={430}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography variant="body2" className={classes.title}>
              ¡Estás listo para empezar!
            </Typography>
            <Typography variant="body2" className={classes.body1}>
              Sigue estros tres simples pasos para
              <br />
              financiar tus facturas ¡en minutos!
            </Typography>
            <Typography variant="body2" className={classes.body2}>
              <ul className={classes.list}>
                <li>Selecciona las facturas a financiar.</li>
                <li>Elige sus fechas de pago.</li>
                <li>Cede tus facturas y ¡Listo!</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.carousel}>
              <Grid item className={classes.carouselStep} />
              <Grid item className={classes.carouselStep} />
              <Grid
                item
                className={`${classes.carouselStep} ${classes.active}`}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} direction="row" className={classes.actions}>
            <Button
              variant="contained"
              size="medium"
              className={classes.action}
              onClick={() => back()}
            >
              <ArrowLeftIcon />
            </Button>
            <GradientButton onClick={beginAction} fullWidth={false}>
              {COMENZAR}
            </GradientButton>
          </Grid>
        </Grid>
      )}
    </BaseDialog>
  );
};

WelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default WelcomeDialog;
