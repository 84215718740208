import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles({
  chipSuccess: {
    width: '100%',
    '& span': {
      font: 'bold 13px Muli',
    },
    color: 'white',
    background: '#75B801',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  checkIcon: {
    color: 'white',
  },
});

const SuccessChip = props => {
  const classes = useStyles();
  const { label, variant, size } = props;
  return (
    <Chip
      className={classes.chipSuccess}
      size={size}
      label={label}
      variant={variant}
      icon={<CheckIcon className={classes.checkIcon} />}
    />
  );
};

SuccessChip.defaultProps = {
  variant: 'default',
  size: 'medium',
};

SuccessChip.propTypes = {
  variant: PropTypes.oneOf(['default', 'outlined']),
  size: PropTypes.oneOf(['medium', 'small']),
  label: PropTypes.string.isRequired,
};

export default SuccessChip;
