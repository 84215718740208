import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const Container = props => {
  const useStyles = makeStyles({
    root: {
      display: 'grid',
      position: props.sticky ? 'sticky' : null,
      top: props.stickyTop || 0,
      boxSizing: 'border-box',
      gridTemplateColumns: props.gridTemplate || '1fr',
      gridGap: props.gridGap || null,
      padding: props.padding || null,
      gridAutoRows: 'min-content',
      width: '100%',
      zIndex: props.zIndex || 0,
      alignContent: props.align || null,
      alignItems: props.align || null,
      justifyContent: props.justify || null,
      justifyItems: props.justify || null,
    },
  });
  const classes = useStyles();

  return (
    <Box
      className={`
        ${classes.root}
        ${props.className}
      `}
    >
      {props.children}
    </Box>
  );
};

Container.defaultProps = {
  className: '',
  gridTemplate: '1fr',
  sticky: false,
  stickyTop: null,
  gridGap: null,
  padding: null,
  zIndex: null,
  justify: null,
  align: null,
};

Container.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string]),
  children: PropTypes.node.isRequired,
  gridTemplate: PropTypes.oneOfType([PropTypes.string]),
  sticky: PropTypes.bool,
  stickyTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
  justify: PropTypes.string,
  align: PropTypes.string,
};

export default Container;
