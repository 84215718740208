import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FolderIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Grupo_2750" data-name="Grupo 2750" transform="translate(-51 -476)">
        <g id="Grupo_2749" data-name="Grupo 2749" transform="translate(51 476)">
          <path
            id="Trazado_2938"
            data-name="Trazado 2938"
            d="M0,0H24V24H0Z"
            fill="#fff"
            opacity="0"
          />
          <path
            id="Trazado_2939"
            data-name="Trazado 2939"
            d="M0,0H24V24H0Z"
            fill="#fff"
            opacity="0"
          />
          <g id="Grupo_2748" data-name="Grupo 2748">
            <g id="Grupo_2744" data-name="Grupo 2744">
              <path
                id="Trazado_2940"
                data-name="Trazado 2940"
                d="M20.381,7.418H3.74a.951.951,0,0,1-.965-.965.888.888,0,0,1,.965-.965H20.26a.951.951,0,0,1,.965.965A.86.86,0,0,1,20.381,7.418Z"
              />
            </g>
            <g id="Grupo_2745" data-name="Grupo 2745">
              <path
                id="Trazado_2941"
                data-name="Trazado 2941"
                d="M16.643,22.13H7.478A2.729,2.729,0,0,1,4.7,19.356V6.453a.952.952,0,0,1,.965-.965.855.855,0,0,1,.844.965v12.9a.952.952,0,0,0,.965.965h9.165a.951.951,0,0,0,.964-.965V6.453a.965.965,0,0,1,1.93,0v12.9A2.912,2.912,0,0,1,16.643,22.13ZM15.678,7.418a.952.952,0,0,1-.965-.965V4.644a.952.952,0,0,0-.964-.965H10.131a.952.952,0,0,0-.965.965V6.453a.952.952,0,0,1-.965.965c-.6,0-.723-.362-.723-.965V4.644A2.729,2.729,0,0,1,10.251,1.87h3.618a2.729,2.729,0,0,1,2.774,2.774V6.453A.888.888,0,0,1,15.678,7.418Z"
              />
            </g>
            <g id="Grupo_2746" data-name="Grupo 2746">
              <path
                id="Trazado_2942"
                data-name="Trazado 2942"
                d="M10.251,17.547a.951.951,0,0,1-.964-.965V11.035a.951.951,0,0,1,.964-.964.887.887,0,0,1,.965.964v5.547C11.1,17.065,10.734,17.547,10.251,17.547Z"
              />
            </g>
            <g id="Grupo_2747" data-name="Grupo 2747">
              <path
                id="Trazado_2943"
                data-name="Trazado 2943"
                d="M13.869,17.547a.952.952,0,0,1-.965-.965V11.035a.951.951,0,0,1,.965-.964.887.887,0,0,1,.965.964v5.547A1.038,1.038,0,0,1,13.869,17.547Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FolderIcon;
