import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  Box,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SmileyIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as LogoBlanco } from '../assets/logo-horizontal-blanco.svg';
import TasksDrawer from './TasksDrawer';
import TasksCenterIcon from './icons/TasksCenterIcon';
import { hasPreviredService } from '../helpers/services';
import AssistantTooltip from './elements/AssistantTooltip';
import { setPendingTasks } from '../actions/assistantAction';

const useStyles = makeStyles({
  appBar: {
    background: 'linear-gradient(45deg, #F87C54 30%, #FAB584 100%)',
    height: 56,
    alignContent: 'center',
  },
  buttonIcon: {
    fontSize: '22px !important',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatarButton: {
    boxShadow: 'none',
    borderRadius: 36,
    background: '#FABD98',
    color: 'white',
    padding: '5px 10px',
    height: 30,
  },
  iconConfig: {
    color: 'red',
  },
  rut: {
    margin: '0 10px',
  },
  userName: {
    fontSize: 13,
    fontFamily: 'Muli',
    textTransform: 'capitalize',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  whiteIcon: {
    color: '#fff',
  },
  title: {
    flexGrow: 1,
  },
  profileMenu: {
    marginLeft: 'auto',
    font: '13px/16px Muli',
    fontWeight: 600,
  },
  toolbar: {
    minHeight: 56,
    height: 56,
  },
  logoButton: {
    justifyContent: 'flex-start',
  },
  alertRoot: {
    margin: 15,
    width: 260,
  },
  alertText: {
    font: '13px/16px Muli',
    color: '#6C6776',
  },
  alertItemRoot: {
    display: 'table',
    alignItems: 'center',
    font: '13px/16px Muli',
    color: '#F87348',
    height: 30,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  alertItemText: {
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '100%',
  },
  alertItemIcon: {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: 24,
    height: '100%',
    paddingTop: 6,
  },
});

const TopBar = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useSelector(state => state.auth);
  const pfx = useSelector(state => state.sii.pfxCertificate);
  const stakeholders = useSelector(state => state.stakeholders.stakeholders);
  const settingsResume = useSelector(state => state.business.settingsResume);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const pendingTasksTooltip = useSelector(
    state => state.assistant.pendingTasksTooltip
  );

  const hasPfx = !!pfx;
  const hasStakeHolders = !!stakeholders.length;

  const { user, handleShowDrawer } = props;
  const { nombre: userName, Businesses = [] } = user;
  const [business = {}] = Businesses;
  const {
    name: businessName,
    rut: businessRut,
    BankAccounts = [],
    contract = false,
    marketInvoicer = false,
  } = business;
  const { incompleteOrders = [] } = settingsResume || {};
  const hasBankAccounts = !!BankAccounts.length;

  const hasPrevired = hasPreviredService(auth);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    if (showAssistantStepper) {
      dispatch(setPendingTasks(false));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = path => history.push(path);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const showAlerts =
    (!marketInvoicer && !hasPfx) ||
    !hasStakeHolders ||
    !hasBankAccounts ||
    incompleteOrders.length ||
    !contract ||
    !hasPrevired;

  return (
    <AppBar position="relative" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Hidden smUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleShowDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Box className={classes.logoButton}>
          <Link href="/">
            <LogoBlanco height="20px" />
          </Link>
        </Box>

        <Typography className={classes.profileMenu} variant="body2">
          <span>{businessName}</span>
          <span className={classes.rut}>{businessRut}</span>
        </Typography>

        <Button
          className={classes.avatarButton}
          endIcon={<SmileyIcon className={classes.buttonIcon} />}
          size="small"
          onClick={() => redirectTo('/user/profile')}
          data-qa="button-top-bar-user-profile"
        >
          {user && (
            <Typography
              variant="body2"
              className={classes.userName}
              display="inline"
            >
              Hola, <span className={classes.boldFont}>{userName}</span>
            </Typography>
          )}
        </Button>

        {showAlerts && (
          <>
            <AssistantTooltip
              type="pendingTask"
              open={pendingTasksTooltip}
              element={
                <Tooltip title="Tareas Pendientes">
                  <IconButton onClick={handleClick} aria-describedby={id}>
                    <TasksCenterIcon className={classes.iconConfig} />
                  </IconButton>
                </Tooltip>
              }
            />

            <TasksDrawer open={open} toggleDrawer={handleClose} user={user} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  user: PropTypes.objectOf(Object).isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
};

export default TopBar;
