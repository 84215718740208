import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/EmailOutlined';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  whatsapp: WhatsAppIcon,
  email: EmailIcon,
  loading: CircularProgress,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  whatsapp: {
    backgroundColor: green[600],
  },
  email: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  loading: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    color: 'white',
    maxWidth: 30,
    maxHeight: 30,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
})(LinearProgress);

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <div>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon
              color="white"
              className={clsx(classes.icon, classes.iconVariant)}
            />
            {message} {props.showPercentage ? `( ${props.completed}% )` : null}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
      {props.completed ? (
        <div style={{ flexGrow: 1, marginTop: -3 }}>
          <ColorLinearProgress variant="determinate" value={other.completed} />
        </div>
      ) : null}
    </div>
  );
}

MySnackbarContentWrapper.defaultProps = {
  className: null,
  message: null,
  completed: null,
  onClose: null,
  showPercentage: null,
};

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  completed: PropTypes.number,
  onClose: PropTypes.func,
  showPercentage: PropTypes.bool,
  variant: PropTypes.oneOf([
    'error',
    'info',
    'success',
    'warning',
    'loading',
    'whatsapp',
    'email',
  ]).isRequired,
};

export default function CustomSnackBar(props) {
  const [state, setState] = React.useState(true);

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setState(false);
  }

  return (
    <Snackbar
      anchorOrigin={
        props.anchor
          ? props.anchor
          : {
              vertical: 'bottom',
              horizontal: 'left',
            }
      }
      open={state}
      autoHideDuration={props.duration ? props.duration : null}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={props.variant}
        message={props.message}
        completed={props.completed}
      />
    </Snackbar>
  );
}

CustomSnackBar.defaultProps = {
  anchor: null,
  duration: null,
  message: null,
  completed: null,
};

CustomSnackBar.propTypes = {
  anchor: PropTypes.string,
  message: PropTypes.string,
  completed: PropTypes.string,
  duration: PropTypes.bool,
  variant: PropTypes.oneOf([
    'error',
    'info',
    'success',
    'warning',
    'loading',
    'whatsapp',
    'email',
  ]).isRequired,
};
