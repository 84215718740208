import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    borderRadius: 17,
    border: '1px solid #E7E5EC',
  },
  gridContainer: {
    flexWrap: 'nowrap',
  },
  panelTitle: {
    padding: '25px 30px !important',
    margin: 0,
  },
  panelBody: {
    padding: '0 30px 25px 30px !important',
    margin: 0,
  },
  title: {
    verticalAlign: 'middle',
    display: 'inline-block',
    padding: props => (props.buttons ? '6px 0' : 0),
    textAlign: props => props.titleAlign || 'left',
    color: props => (props.variant === 'green-title' ? '#75B801' : '#575360'),
    font: props =>
      props.variant === 'green-title' ? 'normal 16px Muli' : 'bold 20px Muli',
  },
});

const CustomPanel = props => {
  const classes = useStyles(props);
  const { title, buttons, children } = props;
  return (
    <Paper spacing={3} elevation={0} className={classes.root}>
      <Grid container direction="column" className={classes.gridContainer}>
        <Grid item xs={12} className={classes.panelTitle}>
          <Grid container direction="row">
            <Grid item xs={buttons ? 6 : 12}>
              <Typography variant="body1" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            {buttons && (
              <Grid item xs={6} align="right">
                {buttons}
              </Grid>
            )}
          </Grid>
        </Grid>
        {children && (
          <Grid item xs={12} className={classes.panelBody}>
            {children}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

CustomPanel.defaultProps = {
  variant: 'normal',
  titleAlign: 'left',
  children: null,
  buttons: null,
};

CustomPanel.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOf(['green-title', 'normal']),
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  titleAlign: PropTypes.oneOf(['left', 'center', 'rigth']),
  buttons: PropTypes.objectOf(Object),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CustomPanel;
