import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const InnerSnackbarUtilsConfigurator = props => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = useSnackbarRefProp => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

const handleAlerts = (msg, variant = 'info', duration = 3000) => {
  useSnackbarRef.enqueueSnackbar(msg, {
    variant,
    autoHideDuration: duration,
    action: key => {
      return (
        <IconButton
          aria-label="Ignorar"
          style={{ color: 'white' }}
          onClick={() => {
            useSnackbarRef.closeSnackbar(key);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      );
    },
  });
};

export default { handleAlerts };
