import {
  FETCH_USER_ORDERS_START,
  FETCH_USER_ORDERS_SUCCESS,
  FETCH_USER_ORDERS_FAILURE,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  SET_FILTERED_ORDER_FACTURAS,
  SET_FACTURAS_AVAILABLE_FILTERS,
  SET_FACTURAS_ACTIVE_FILTERS,
  SET_SELECTED_ORDER,
  RESET_SELECTED_ORDER,
  UPLOAD_BULK_FACTURAS_START,
  UPLOAD_BULK_FACTURAS_SUCCESS,
  UPLOAD_BULK_FACTURAS_FAILURE,
  RESET_BULK_FACTURAS_UPLOAD_SNACKBAR,
  RESET_BULK_FACTURAS_UPLOAD_DIALOG,
  UPLOAD_FACTURA_PDF_START,
  UPLOAD_FACTURA_PDF_SUCCESS,
  UPLOAD_FACTURA_PDF_FAILURE,
  DELETE_FACTURA_PDF_START,
  DELETE_FACTURA_PDF_SUCCESS,
  DELETE_FACTURA_PDF_FAILURE,
} from '../actions/types';

const initialState = {
  loadingUserOrders: false,
  loadingOrder: false,
  selectedOrder: {},
  orderError: '',
  userOrders: [],
  filteredOrderFacturas: [],
  statusStepMap: {
    IN_REVIEW: '1',
    APPROVED: '2',
    TRANSFERRED: '3',
    PAID: '4',
    REJECTED: '5',
  },
  facturasAvailableFilters: [],
  facturasActiveFilters: [],
  bulkfacturasError: false,
  uploadingBulkFacturas: false,
  uploadedBulkFacturas: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_ORDERS_START:
      return {
        ...state,
        loadingUserOrders: true,
      };
    case FETCH_USER_ORDERS_SUCCESS:
      return {
        ...state,
        loadingUserOrders: false,
        userOrders: payload.orders,
      };
    case FETCH_USER_ORDERS_FAILURE:
      return {
        ...state,
        loadingUserOrders: false,
      };
    case FETCH_ORDER_START:
      return {
        ...state,
        orderError: '',
        loadingOrder: true,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        loadingOrder: false,
        selectedOrder: payload.order,
        filteredOrderFacturas: payload.order.OrderFacturas,
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        orderError: payload.error,
        loadingOrder: false,
      };

    case UPLOAD_BULK_FACTURAS_START:
      return {
        ...state,
        uploadingBulkFacturas: true,
        uploadedBulkFacturas: false,
      };
    case UPLOAD_BULK_FACTURAS_SUCCESS:
      return {
        ...state,
        uploadingBulkFacturas: false,
        uploadedBulkFacturas: payload.success,
      };
    case UPLOAD_BULK_FACTURAS_FAILURE:
      return {
        ...state,
        bulkfacturasError: payload.error,
        uploadingBulkFacturas: false,
      };
    case SET_FILTERED_ORDER_FACTURAS:
      return {
        ...state,
        filteredOrderFacturas: payload.filteredOrderFacturas,
      };

    case SET_FACTURAS_AVAILABLE_FILTERS:
      return {
        ...state,
        facturasAvailableFilters: payload.facturasAvailableFilters,
      };

    case SET_FACTURAS_ACTIVE_FILTERS:
      return {
        ...state,
        facturasActiveFilters: payload.facturasActiveFilters,
      };

    case SET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: payload.selectedOrder,
      };

    case RESET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: {},
      };

    case RESET_BULK_FACTURAS_UPLOAD_DIALOG:
      return {
        ...state,
        uploadingBulkFacturas: false,
      };

    case RESET_BULK_FACTURAS_UPLOAD_SNACKBAR:
      return {
        ...state,
        uploadedBulkFacturas: false,
      };

    case UPLOAD_FACTURA_PDF_START:
      return {
        ...state,
        uploadingFacturaId: payload.uploadingFacturaId,
        uploadPdfError: false,
        uploadingPdf: true,
      };

    case UPLOAD_FACTURA_PDF_SUCCESS:
      return {
        ...state,
        uploadingFacturaId: null,
        uploadingPdf: false,
        filteredOrderFacturas: payload.orderFacturasData,
      };

    case UPLOAD_FACTURA_PDF_FAILURE:
      return {
        ...state,
        uploadingFacturaId: null,
        uploadingPdf: false,
        uploadPdfError: true,
      };

    case DELETE_FACTURA_PDF_START:
      return {
        ...state,
        deletingPdfError: false,
        deletingPdf: true,
      };

    case DELETE_FACTURA_PDF_SUCCESS:
      return {
        ...state,
        deletingPdf: false,
        filteredOrderFacturas: payload.orderFacturasData,
      };

    case DELETE_FACTURA_PDF_FAILURE:
      return {
        ...state,
        deletingPdf: false,
        deletingPdfError: true,
      };

    default:
      return state;
  }
};
