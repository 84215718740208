import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getFirstBusinessDayFrom = (startDate, holidays = []) => {
  if (!startDate) return startDate;
  const dateToEvaluate = moment(startDate);
  const isHoliday = holidays.some(h => {
    return dateToEvaluate.isSame(moment(h.date), 'day');
  });
  if (
    dateToEvaluate.isoWeekday() !== 6 &&
    dateToEvaluate.isoWeekday() !== 7 &&
    !isHoliday
  ) {
    return startDate;
  }
  return getFirstBusinessDayFrom(
    dateToEvaluate.add(1, 'day').format('YYYY-MM-DD')
  );
};
