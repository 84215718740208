// authentication.js
import moment from 'moment';
import axios from 'axios';
import {
  CART_CALCULATE_BEGIN,
  CART_CALCULATE_SUCCESS,
  CART_CALCULATE_ERROR,
  SUBMIT_ORDER_BEGIN,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_ERROR,
  CLEAN_CART,
  REMOVE_FROM_CART,
} from './types';
import { trackSegment } from '../helpers/trackingSegment';
import { addNotification } from './notificationsAction';

const DIRECT_FINANCING = 'DIRECT_FINANCING';
const EARLY_PAYMENT = 'EARLY_PAYMENT';

const trackOperationResume = (selectedFacturas, totals, operation) => {
  const {
    retentionPct: retention,
    anticipo: advanceAmount,
    intereses: interest,
    tasaBase: rate,
  } = totals;
  const invoiceQuantity = selectedFacturas.length;
  const totalSelectedFacturas = selectedFacturas.reduce(
    (sum, factura) => sum + parseInt(factura.mntTotal, 10),
    0
  );

  let trackPayload;
  if (operation === EARLY_PAYMENT) {
    trackPayload = {
      operationType: 'PP',
      invoiceQuantity,
      retention: 0,
      advanceAmount: totalSelectedFacturas,
      interest,
      rate,
      totalAmount: totalSelectedFacturas,
    };
  } else {
    trackPayload = {
      operationType: 'FD',
      invoiceQuantity,
      retention,
      advanceAmount,
      interest,
      rate,
      totalAmount: totalSelectedFacturas,
    };
  }
  trackSegment('Order Cart Review', trackPayload);
};
export const facturasCalc =
  (facturas, operation = DIRECT_FINANCING) =>
  dispatch => {
    dispatch({
      type: CART_CALCULATE_BEGIN,
    });
    axios
      .post(`/api/cart/calculate?operation=${operation}`, { facturas })
      .then(res => {
        const { response } = res.data;
        dispatch({
          type: CART_CALCULATE_SUCCESS,
          payload: response,
        });
        trackOperationResume(facturas, response, operation);
      })
      .catch(err => {
        dispatch({
          type: CART_CALCULATE_ERROR,
          payload: err,
        });
      });
  };

export const removeFromCart = (facturas, flow) => ({
  type: REMOVE_FROM_CART,
  payload: { facturas, flow },
});

export const submitOrder =
  (
    selectedFacturas,
    selectedFacturasByAlgorithm,
    trackSegmentPayload,
    operation = DIRECT_FINANCING
  ) =>
  dispatch => {
    const folios = selectedFacturas.reduce((acc, val) => {
      if (val.example) return acc;
      return {
        ...acc,
        [val.folio]: {
          fechaVencimiento: moment(val.fechaVencimiento).format('YYYY-MM-DD'),
          siiId: val.id,
          rate: val.rate,
          mntFinal: val.mntFinal,
        },
      };
    }, {});
    dispatch({
      type: SUBMIT_ORDER_BEGIN,
    });
    axios
      .post(`/api/order?operation=${operation}`, {
        selectedFacturas: folios,
        selectedFacturasByAlgorithm,
      })
      .then(res => {
        const { orderId } = res.data.data;

        trackSegment('Order Submitted', {
          operationId: orderId,
          ...trackSegmentPayload,
        });

        dispatch({
          type: SUBMIT_ORDER_SUCCESS,
          payload: orderId,
        });
        dispatch({
          type: CLEAN_CART,
        });
      })
      .catch(err => {
        dispatch({
          type: SUBMIT_ORDER_ERROR,
          payload: err,
        });
        dispatch(addNotification(err.response.data.error, 'error'));
      });
  };
