import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import GradientButton from './form/GradientButton';
import imgWhatsapp from '../assets/whatsapp.svg';
import TopImageModalDialog from './elements/TopImageModalDialog';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
});

const FacturasSentWhatsappDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/user/profile/notifications');
  };

  return (
    <TopImageModalDialog
      isOpen={open}
      withCloseButton
      handleClose={handleClose}
      imageSource={imgWhatsapp}
      imageWidth={252}
      title="Recibe notificaciones vía Whatsapp"
      subtitle="¡Felicidades! Tu solicitud ha sido enviada."
      content={
        <Typography className={classes.content}>
          Puedes recibir <strong>notificaciones vía WhatsApp</strong>
          <br />
          con los <strong>avances y estados de tus operaciones.</strong>
        </Typography>
      }
      actions={
        <Grid xs={5}>
          <GradientButton onClick={handleConfirm}>
            Ir a notificaciones
          </GradientButton>
        </Grid>
      }
    />
  );
};

FacturasSentWhatsappDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FacturasSentWhatsappDialog;
