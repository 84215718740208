import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core/';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Section from './elements/Section';
import Container from './elements/Container';
import FacturasSentDialog from './FacturasSentDialog';
import FacturasSentWhatsappDialog from './FacturasSentWhatsappDialog';
import FacturasSentBankAccountDialog from './FacturasSentBankAccountDialog';
import FacturasSentPfxDialog from './FacturasSentPfxDialog';
import FacturasSentPdfDialog from './FacturasSentPdfDialog';
import 'moment/locale/es';
import {
  facturasCalc,
  submitOrder,
  removeFromCart,
} from '../actions/cartAction';
import GradientButton from './form/GradientButton';
import CartTotals from './elements/CartTotals';
import CustomInfoCard from './elements/CustomInfoCard';
import SelectDateFacturasTable from './elements/SelectDateFacturasTable';
import { showAssistant, checkActiveDOM } from '../actions/assistantAction';
import AssistantDialog from './elements/AssistantDialog';

import {
  DIRECT_FINANCING,
  DIALOG_TYPE_NORMAL,
  DIALOG_TYPE_ASSISTANT,
  DIALOG_TYPE_BANKACCOUNT,
  DIALOG_TYPE_WHATSAPP,
  PDF,
  PFX,
  ASSISTANT_TYPES,
  ASSISTANT_STEPS,
} from '../helpers/Constants';

const useStyles = {
  root: {
    flexGrow: 1,
  },
  smallTitle: {
    font: 'Bold 17px/21px Muli',
    fontFamily: 'Muli',
    color: '#6c6776',
    textAlign: 'right',
  },
  mainContainer: {
    minHeight: 'fit-content',
  },
  main: {
    minHeight: '60vh',
    borderRadius: 17,
    padding: 20,
    paddingBottom: 0,
    border: '1px solid #E4E1E9',
    height: '100%',
  },
  title: {
    color: '#575360',
    fontWeight: 'bold',
    fontSize: 17,
    marginBottom: 10,
  },
  containerHeader: {
    marginBottom: 40,
  },
  underline: {
    color: '#1A73E7',
    '&:before': {
      borderBottom: '2px solid #1A73E7',
    },
    '&:after': {},
    '&:hover:not($disabled):not($focused):not($error):before': {},
  },
  underlineDisabled: {
    color: '#CECAD9',
    '&:before': {
      borderBottom: '2px solid #CECAD9',
    },
  },
  annotation: {
    textAlign: 'right',
    fontSize: 11,
    color: '#F58F69',
    marginTop: 17,
  },
  tableInvoices: {
    margin: '0 -30px -15px -30px',
  },
};

class HomeCartPaymentDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFacturasSentDialog: false,
      facturaSentDialog: '',
      assistantFinished: false,
    };
  }

  componentDidMount = () => {
    const {
      handleDateChange,
      selectedFacturas,
      facturasCalc: fCalc,
      operationType,
    } = this.props;

    handleDateChange();
    if (selectedFacturas.length > 0) {
      this.setState(prevState => ({
        ...prevState,
      }));
      fCalc(selectedFacturas, operationType);
    }
  };

  getDialogChoice = () => {
    const { settingsResume } = this.props;
    const { assistantFinished } = this.state;
    const {
      hasPfxCertificate,
      hasMarketInvoicer,
      hasBankAccount,
      ordersQuantity,
      hasActivatedWhatsappNotifications,
    } = settingsResume || {};
    let facturaSentDialog = '';
    if (!settingsResume) {
      facturaSentDialog = DIALOG_TYPE_NORMAL;
      if (assistantFinished) facturaSentDialog = DIALOG_TYPE_ASSISTANT;
    } else if (!hasPfxCertificate && ordersQuantity <= 4) {
      if (hasMarketInvoicer) {
        facturaSentDialog = PDF;
      } else {
        facturaSentDialog = PFX;
      }
    } else if (!hasBankAccount && ordersQuantity <= 4) {
      facturaSentDialog = DIALOG_TYPE_BANKACCOUNT;
    } else if (!hasActivatedWhatsappNotifications && ordersQuantity <= 4) {
      facturaSentDialog = DIALOG_TYPE_WHATSAPP;
    } else {
      facturaSentDialog = DIALOG_TYPE_NORMAL;
    }

    this.setState(prevState => ({ ...prevState, facturaSentDialog }));
  };

  componentDidUpdate(prevProps) {
    const {
      orderSubmitted,
      selectedFacturas,
      facturasCalc: fCalc,
      allFacturasWithDate,
      handleStepChange,
      orderSubmittedResult: orderId,
      operationType,
    } = this.props;
    if (
      selectedFacturas.length &&
      prevProps.selectedFacturas !== selectedFacturas
    ) {
      fCalc(selectedFacturas, operationType);
    }

    // if order is submitted correctly, show dialog
    if (
      prevProps.orderSubmitted !== orderSubmitted &&
      prevProps.orderSubmitted &&
      !orderSubmitted &&
      !!orderId
    ) {
      handleStepChange(3);
      this.getDialogChoice();
      this.openDialog();
    }

    // if every factura has a date, go to step 2 : "Enviar solicitud"
    if (
      prevProps.allFacturasWithDate !== allFacturasWithDate &&
      allFacturasWithDate
    ) {
      handleStepChange(2);
    }
  }

  handleCloseFacturasDialog = () => {
    const { operationType, history } = this.props;
    const path =
      operationType === DIRECT_FINANCING
        ? '/finance/directo'
        : '/finance/prontopago';
    history.push(path);
  };

  renderFacturaSentDialog = () => {
    const { facturaSentDialog, showFacturasSentDialog } = this.state;
    const { orderSubmittedResult: orderId } = this.props;

    switch (facturaSentDialog) {
      case DIALOG_TYPE_BANKACCOUNT:
        return (
          <FacturasSentBankAccountDialog
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
          />
        );
      case DIALOG_TYPE_NORMAL:
        return (
          <FacturasSentDialog
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
          />
        );
      case DIALOG_TYPE_WHATSAPP:
        return (
          <FacturasSentWhatsappDialog
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
          />
        );
      case PFX:
        return (
          <FacturasSentPfxDialog
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
          />
        );
      case PDF:
        return (
          <FacturasSentPdfDialog
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
            orderId={orderId}
          />
        );
      case DIALOG_TYPE_ASSISTANT:
        return (
          <AssistantDialog
            step={1}
            open={showFacturasSentDialog}
            handleClose={this.handleCloseFacturasDialog}
          />
        );
      default:
        return null;
    }
  };

  handleSubmitOrder = () => {
    const {
      submitOrder: sOrder,
      selectedFacturas,
      selectedFacturasByAlgorithm,
      totalSelectedFacturas,
      totals: {
        retentionPct: retention,
        montoTransferencia: advanceAmount,
        intereses,
        tasaBase: rate,
      },
      operationType,
      showAssistant,
      showAssistantStepper,
    } = this.props;
    // TRACK SEGMENT
    const products = selectedFacturas.map(factura => {
      const {
        folio,
        fechaEmision,
        fechaVencimiento: fechaVcto,
        rutReceptor: debtorRut,
        rznSocRecep: debtorName,
        mntTotal: totalAmount,
        dehDescripcion,
      } = factura;
      return {
        folio,
        issueDate: fechaEmision
          ? moment.utc(new Date(fechaEmision)).locale('es').format('DD-MM-YYYY')
          : null,
        paymentDate: fechaVcto
          ? moment.utc(new Date(fechaVcto)).locale('es').format('DD-MM-YYYY')
          : null,
        debtorRut,
        debtorName,
        totalAmount,
        dehDescripcion,
      };
    });

    let trackSegmentPayload = {
      operationType: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
      advanceAmount,
      intereses,
      products,
      totalAmount: totalSelectedFacturas,
    };

    if (operationType === DIRECT_FINANCING) {
      trackSegmentPayload = {
        ...trackSegmentPayload,
        retention,
        rate,
      };
    }

    sOrder(
      selectedFacturas,
      selectedFacturasByAlgorithm,
      trackSegmentPayload,
      operationType
    );

    if (showAssistantStepper) {
      showAssistant(false);
      this.setState({ assistantFinished: true });
    }

    // this.setState({ showFacturasSentDialog: true });
  };

  handleDisabledDates = date => {
    const { holidays } = this.props;
    return (
      moment(date).isoWeekday() === 6 ||
      moment(date).isoWeekday() === 7 ||
      holidays.some(h => moment(date).isSame(moment(h.date), 'day'))
    );
  };

  handleCloseSiiDialog = () => {
    this.setState(prevState => ({
      ...prevState,
    }));
  };

  handleDateChange = (date, facturas) => {
    const { handleDateChange } = this.props;
    handleDateChange(facturas, date.toDate());
  };

  openDialog = () => {
    this.setState({
      showFacturasSentDialog: true,
    });
  };

  render = () => {
    const {
      operationType,
      classes,
      selectedFacturas,
      handleStepChange,
      totalSelectedFacturas,
      totals,
      orderSubmitted,
      removeFromCart,
      allFacturasWithDate,
      assistantActiveStep,
      checkActiveDOM,
      assistantType,
    } = this.props;

    const dataTable = selectedFacturas.map(factura => {
      const newFactura = { ...factura };
      delete newFactura.tableData;
      return newFactura;
    });

    const { STEP_OPERATION_SELECT_DATES } = ASSISTANT_STEPS;

    if (assistantActiveStep === STEP_OPERATION_SELECT_DATES) {
      checkActiveDOM(assistantActiveStep, document, assistantType);
    }

    return (
      <Grid
        id="date-table"
        className={classes.mainContainer}
        container
        justify="center"
      >
        {this.renderFacturaSentDialog()}
        <Container gridGap={30} gridTemplate="1fr 280px">
          {selectedFacturas.length === 0 ? (
            <Paper spacing={3} elevation={0} className={classes.main}>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                style={{
                  height: '100%',
                }}
              >
                <ShoppingCartIcon
                  style={{ fontSize: '9rem', color: 'rgb(168, 164, 179)' }}
                />
                <Typography variant="body2">
                  No hay facturas seleccionadas
                </Typography>
                <Grid item xs={3} style={{ marginTop: 20 }}>
                  <GradientButton onClick={() => handleStepChange(0)}>
                    Volver
                  </GradientButton>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Section
              title="Elige las fechas de pago de tus facturas y envía tu solicitud a un ejecutivo."
              bordered
            >
              <div className={classes.tableInvoices}>
                <SelectDateFacturasTable
                  invoices={dataTable}
                  totalSelectedFacturas={totalSelectedFacturas}
                  removeFromCart={removeFromCart}
                  flow={operationType}
                  handleDisabledDates={this.handleDisabledDates}
                  handleDateChange={this.handleDateChange}
                />
              </div>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.smallTitle}>
                    Monto Total:{' '}
                    <NumberFormat
                      className={classes.smallTitle}
                      value={totalSelectedFacturas}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      displayType="text"
                      prefix="$"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.annotation}>
                    * Fecha de pago sugerida en relación al historial de
                    <br />
                    pago del proveedor. Esta fecha puede ser modificada.
                  </Typography>
                </Grid>
              </Grid>
            </Section>
          )}
          {selectedFacturas.length > 0 && (
            <Container sticky stickyTop={140} gridGap={30}>
              <CustomInfoCard
                title="¡No te cobramos otros gastos!"
                justify="center"
              >
                No cobramos gastos de comisión, transferencia, notificación o
                cobranzas.
              </CustomInfoCard>
              <CartTotals
                title="Resumen de operación"
                totalSelectedFacturas={totalSelectedFacturas}
                totals={totals}
                operation={operationType}
                submit={orderSubmitted}
                onClick={() => this.handleSubmitOrder()}
                disabled={!allFacturasWithDate}
              />
            </Container>
          )}
        </Container>
      </Grid>
    );
  };
}

HomeCartPaymentDate.propTypes = {
  operationType: PropTypes.string.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  facturasCalc: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  allFacturasWithDate: PropTypes.bool.isRequired,
  selectedFacturas: PropTypes.shape([]).isRequired,
  selectedFacturasByAlgorithm: PropTypes.shape([]).isRequired,
  totalSelectedFacturas: PropTypes.number.isRequired,
  orderSubmitted: PropTypes.bool.isRequired,
  orderSubmittedResult: PropTypes.objectOf().isRequired,
  settingsResume: PropTypes.objectOf().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      Businesses: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    mainContainer: PropTypes.shape({}).isRequired,
    main: PropTypes.shape({}).isRequired,
    containerHeader: PropTypes.shape({}).isRequired,
    smallTitle: PropTypes.shape({}).isRequired,
    root: PropTypes.shape({}).isRequired,
    underline: PropTypes.shape({}).isRequired,
    underlineDisabled: PropTypes.shape({}).isRequired,
    annotation: PropTypes.shape({}).isRequired,
    tableInvoices: PropTypes.shape({}).isRequired,
  }).isRequired,
  removeFromCart: PropTypes.func.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.objectOf(Object).isRequired,
  showAssistant: PropTypes.func.isRequired,
  checkActiveDOM: PropTypes.func.isRequired,
  showAssistantStepper: PropTypes.bool.isRequired,
  assistantActiveStep: PropTypes.number.isRequired,
  assistantType: PropTypes.oneOf([
    ASSISTANT_TYPES.OPERATION,
    ASSISTANT_TYPES.PROFILE,
  ]).isRequired,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    selectedFacturas: state.sii.inCartFacturas || [],
    selectedFacturasByAlgorithm: state.sii.selectedFacturasByAlgorithm,
    totalSelectedFacturas: state.sii.totalInCartFacturas,
    allFacturasWithDate: state.sii.allFacturasWithDate,
    totals: state.cart.totals,
    orderSubmitted: state.cart.orderSubmitted,
    orderSubmittedError: state.cart.orderSubmittedError,
    orderSubmittedResult: state.cart.orderSubmittedResult,
    holidays: state.holidays.yearHolidays,
    showAssistantStepper: state.assistant.active,
    assistantActiveStep: state.assistant.activeStep,
    assistantType: state.assistant.type,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    facturasCalc: (facturas, operationType) =>
      dispatch(facturasCalc(facturas, operationType)),
    submitOrder: (
      selectedFacturas,
      selectedFacturasByAlgorithm,
      trackSegmentPayload,
      operationType
    ) =>
      dispatch(
        submitOrder(
          selectedFacturas,
          selectedFacturasByAlgorithm,
          trackSegmentPayload,
          operationType
        )
      ),
    removeFromCart: (facturas, operationType) =>
      dispatch(removeFromCart(facturas, operationType)),
    handleDateChange: (facturas = null, newDate = null) =>
      dispatch({
        type: 'HANDLE_FACTURA_DATE_CHANGE',
        payload: { newDate, facturas },
      }),
    showAssistant: (active, type) => dispatch(showAssistant(active, type)),
    checkActiveDOM: (step, document, type) => {
      dispatch(checkActiveDOM(step, document, type));
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles),
  withRouter
)(HomeCartPaymentDate);
