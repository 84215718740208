import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import GradientButton from './form/GradientButton';
import imgPdf from '../assets/pdf.svg';
import TopImageModalDialog from './elements/TopImageModalDialog';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
});

const FacturasSentPdfDialog = ({ open, handleClose, orderId }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleConfirm = () => {
    history.push(`/orders/${orderId}`);
  };

  return (
    <TopImageModalDialog
      isOpen={open}
      withCloseButton
      handleClose={handleClose}
      imageSource={imgPdf}
      imageWidth={122}
      title="Sube los PDFs cedibles"
      subtitle="¡Felicidades! Tu solicitud ha sido enviada."
      content={
        <Typography className={classes.content}>
          Para completar tu operación necesitamos
          <br />
          los <strong>PDFs cedibles de cada factura.</strong>
        </Typography>
      }
      actions={
        <Grid xs={5}>
          <GradientButton onClick={handleConfirm}>
            Ir a la operación
          </GradientButton>
        </Grid>
      }
    />
  );
};

FacturasSentPdfDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
};

export default FacturasSentPdfDialog;
