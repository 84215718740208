import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrendingDownIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="24" height="24" />
          <path
            id="Trazado_2892"
            data-name="Trazado 2892"
            d="M16.4,16.69l1.37-1.36L13.14,10.7,10,13.82a1,1,0,0,1-1.34,0h0L3,8.13A.95.95,0,0,1,4.34,6.79l5,5,3.12-3.12a1,1,0,0,1,1.34,0h0L19.1,14l1.37-1.36a.47.47,0,0,1,.67,0,.51.51,0,0,1,.13.33V17a.46.46,0,0,1-.46.48H16.73a.47.47,0,0,1-.46-.47.46.46,0,0,1,.13-.33Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default TrendingDownIcon;
