import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import ReactDropzoneUploader from 'react-dropzone-uploader';
import { Typography, Grid } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const styles = {
  dropzone: {
    background: '#FBFAFC 0% 0% no-repeat padding-box',
    border: '2px dashed #A8A4B3',
    borderRadius: 28,
    width: '100%',
    height: 225,
    textAlign: 'center',
    minHeight: 200,
    maxHeight: 250,
    overflow: 'hidden',
  },
  inputLabelWithFiles: {
    margin: 0,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#FBFAFC 0% 0% no-repeat padding-box',
    color: '#6C6776',
    fontFamily: 'Muli',
    fontWeight: 'regular',
    '& p': { fontSize: '12px' },
  },
  inputLabel: {
    color: '#6C6776',
    fontFamily: 'Muli',
    fontWeight: 'regular',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
  },
  submitButton: {
    color: '#FFF',
    fontFamily: 'Muli',
    background: '#7300FF',
    borderRadius: '40px',
    borderColor: '#007bff',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '7px 40px',
  },
  previewStatusContainer: {
    '& progress': { color: '#75B801' },
  },
  dropzoneRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewsRoot: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    height: '225px',
    width: '100%',
    marginLeft: '20px',
  },
  submitRoot: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
};

const Layout = ({
  input: InputComponent,
  previews: PreviewsComponent,
  submitButton: SubmitButtonComponent,
  dropzoneProps,
  files,
  extra: { maxFiles: maxSelectableFiles },
}) => {
  const selectedFiles = files.length;
  return (
    // InputRoot
    //   |_ Dropzone lateral sólo si son +1 archivos solicitados
    //   |_ Vista previa sólo si ya hay +1 archivo seleccionado
    // SubmitButton
    <>
      <div style={styles.inputRoot}>
        {selectedFiles < maxSelectableFiles && maxSelectableFiles > 1 && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div style={styles.dropzoneRoot} {...dropzoneProps}>
            {InputComponent}
          </div>
        )}
        {!!selectedFiles && (
          <div style={styles.previewsRoot}>{PreviewsComponent}</div>
        )}
      </div>
      <div style={styles.submitRoot}>
        {!!selectedFiles && SubmitButtonComponent}
      </div>
    </>
  );
};

Layout.propTypes = {
  input: PropTypes.func.isRequired,
  previews: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.func.isRequired,
  dropzoneProps: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  extra: PropTypes.shape({
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
};

const Input = (files, { maxFiles }) => {
  const textItem = maxFiles > 1 ? 'los archivos' : 'el archivo';
  const textAction = maxFiles > 1 ? 'subirlos' : 'subirlo';
  return (
    <Grid>
      <SaveAltIcon fontSize="large" />
      <Typography>
        {`Arrastra ${textItem} aquí o haz click`}
        <br />
        {`para ${textAction} desde tu computador.`}
      </Typography>
    </Grid>
  );
};

const DropzoneGeneric = props => {
  // eslint-disable-next-line react/prop-types
  const { multiple = true, accept, maxFiles, handleSubmit } = props;

  const handleSubmitFiles = (files, allFiles) => {
    if (handleSubmit) {
      handleSubmit(files);
    }
    allFiles.forEach(f => f.remove());
  };

  return (
    <ReactDropzoneUploader
      LayoutComponent={multiple ? Layout : null}
      inputContent={Input}
      inputWithFilesContent={Input}
      onSubmit={handleSubmitFiles}
      styles={styles}
      accept={accept}
      maxFiles={maxFiles}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

DropzoneGeneric.defaultProps = {
  handleSubmit: null,
};

DropzoneGeneric.propTypes = {
  accept: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func,
};

export default DropzoneGeneric;
