import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrendingFlatIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="24" height="24" />
          <path
            id="Trazado_2894"
            data-name="Trazado 2894"
            d="M21.21,11.65,18.43,8.88a.49.49,0,0,0-.7,0,.51.51,0,0,0-.15.36V11H3.65a1,1,0,1,0,0,2H17.58v1.78a.49.49,0,0,0,.48.5.51.51,0,0,0,.36-.15l2.78-2.78a.49.49,0,0,0,0-.69Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default TrendingFlatIcon;
