// authentication.js
import axios from 'axios';
import {
  CHECK_SII_SUCCESS,
  CHECK_SII_FAILURE,
  CHECK_SII_START,
  FETCH_SIIUSER_TOTALS_DATA_START,
  FETCH_SIIUSER_TOTALS_DATA_SUCCESS,
  FETCH_SIIUSER_TOTALS_DATA_FAILURE,
  FETCH_SIIUSER_SELECTED_DTES_START,
  FETCH_SIIUSER_SELECTED_DTES_SUCCESS,
  FETCH_SIIUSER_SELECTED_DTES_FAILURE,
  HANDLE_FACTURA_SELECTION_CONFIRM,
  REGISTER_SII_START,
  REGISTER_SII_SUCCESS,
  REGISTER_SII_FAILURE,
  LOAD_SII_DATA_START,
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REGISTER_PFX_FAILURE,
  CHECK_PFX_START,
  CHECK_PFX_SUCCESS,
  CHECK_PFX_FAILURE,
  FETCH_COMPANY_NAME_BY_RUT_START,
  FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
  FETCH_COMPANY_NAME_BY_RUT_FAILURE,
} from './types';
import { trackSegment, identifyAndTrack } from '../helpers/trackingSegment';
import { fetchSettingsResume } from './businessAction';

const DIRECT_FINANCING = 'DIRECT_FINANCING';

const checkSiiStart = () => ({
  type: CHECK_SII_START,
});

const checkSiiSuccess = payload => ({
  type: CHECK_SII_SUCCESS,
  payload,
});

const checkSiiFailure = error => ({
  type: CHECK_SII_FAILURE,
  payload: { error },
});

const registerSiiStart = () => ({
  type: REGISTER_SII_START,
});

const registerSiiFailure = error => ({
  type: REGISTER_SII_FAILURE,
  payload: { error },
});

export const loadSiiDataStart = token => ({
  type: LOAD_SII_DATA_START,
  token,
});

export const confirmFacturasSelection = () => ({
  type: HANDLE_FACTURA_SELECTION_CONFIRM,
});

export const fetchSiiUserTotalData = () => dispatch => {
  dispatch({ type: FETCH_SIIUSER_TOTALS_DATA_START });
  axios
    .get('/api/siiuser/getMonthlyInfo')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_SIIUSER_TOTALS_DATA_SUCCESS, payload: resData });
      return resData;
    })
    .catch(() => {
      dispatch({ type: FETCH_SIIUSER_TOTALS_DATA_FAILURE });
    });
};

export const fetchSiiUserSelectedDTEs =
  (operation = DIRECT_FINANCING, holidays) =>
  dispatch => {
    dispatch({ type: FETCH_SIIUSER_SELECTED_DTES_START });
    axios
      .get(`/api/siiuser/getSelectedDTEs?operation=${operation}`)
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_SIIUSER_SELECTED_DTES_SUCCESS,
          payload: { ...resData, holidays },
        });
      })
      .catch(() => {
        dispatch({ type: FETCH_SIIUSER_SELECTED_DTES_FAILURE });
      });
  };

export const checkSii = () => dispatch => {
  dispatch(checkSiiStart());
  axios
    .get('/api/siiuser/check')
    .then(res => {
      const siiStatus = res.data;
      dispatch(checkSiiSuccess(siiStatus));
      return siiStatus;
    })
    .catch(err => {
      dispatch(
        checkSiiFailure(
          err.response && err.response.data ? err.response.data : err
        )
      );
    });
};

export const registerSii = (rut, password) => dispatch => {
  dispatch(registerSiiStart());
  axios
    .post('/api/siiuser/register', { rut, password })
    .then(res => {
      const resData = res.data;
      trackSegment('Account Enrolled Sii');
      dispatch({ type: REGISTER_SII_SUCCESS, payload: { siiUser: res.data } });
      return resData;
    })
    .then(() => {
      dispatch(checkSii());
    })
    .catch(err => {
      dispatch(
        registerSiiFailure(
          err.response && err.response.data ? err.response.data : err
        )
      );
    });
};

export const registerPfx =
  ({ pfx, password, businessId, userId }) =>
  dispatch => {
    dispatch({ type: REGISTER_PFX_START });
    const formData = new FormData();
    formData.append('pfx', pfx, pfx.name);
    formData.append('password', password);
    formData.append('businessId', businessId);
    axios
      .post(`/api/pfx`, formData)
      .then(res => {
        const resData = res.data;
        identifyAndTrack(
          businessId,
          {
            pfx: true,
          },
          'PFX Upload'
        );
        dispatch({ type: REGISTER_PFX_SUCCESS, payload: resData });
        dispatch(fetchSettingsResume(businessId, userId));
        return resData;
      })
      .catch(err => {
        dispatch({ type: REGISTER_PFX_FAILURE, payload: err });
      });
  };

export const checkPfxCertificate = businessId => dispatch => {
  dispatch({ type: CHECK_PFX_START });
  axios
    .get(`/api/pfx/checkPfx/${businessId}`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: CHECK_PFX_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: CHECK_PFX_FAILURE, payload: { err } });
    });
};

export const fetchCompanyNameByRut = rut => dispatch => {
  dispatch({ type: FETCH_COMPANY_NAME_BY_RUT_START });
  axios
    .get('/api/siiuser/infoSiiByRut', {
      params: {
        rut,
      },
    })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
        payload: resData,
      });
      return resData;
    })
    .catch(err => {
      dispatch({
        type: FETCH_COMPANY_NAME_BY_RUT_FAILURE,
        payload: err,
      });
    });
};
