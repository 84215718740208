import {
  HANDLE_STEP_CHANGE,
  REGISTER_SUBMIT,
  REGISTER_INPUT_CHANGE,
  REGISTER_BAD_SII_CREDENTIALS,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FETCH_COMPANY_NAME_BY_RUT_START,
  FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
  FETCH_COMPANY_NAME_BY_RUT_FAILURE,
  RESET_TOUR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESET_VERIFY_EMAIL,
} from '../actions/types';

const initialState = {
  nombre: '',
  nombreEmpresa: '',
  rutEmpresa: '',
  claveSII: '',
  email: '',
  telefono: '',
  password: '',
  passwordCheck: '',
  errors: {},
  submit: false,
  step: 1,
  disabledCompanyName: true,
  tour: false,
  emailSuggestion: null,
};

export default function (state = initialState, action) {
  const { type: typeAction, step, field, value, payload } = action;
  switch (typeAction) {
    case HANDLE_STEP_CHANGE:
      return {
        ...state,
        step,
      };
    case REGISTER_SUBMIT:
      return {
        ...state,
      };
    case REGISTER_BEGIN:
      return {
        ...state,
        submit: true,
        tour: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        submit: false,
        errors: {},
        tour: true,
      };
    case REGISTER_BAD_SII_CREDENTIALS:
      return {
        ...state,
        submit: false,
        errors: {
          BAD_SII_CREDENTIALS: true,
        },
        tour: false,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        submit: false,
        step: 1,
        errors: {
          REGISTER_ERROR: true,
        },
        tour: false,
      };
    case REGISTER_INPUT_CHANGE: {
      return {
        ...state,
        [field]: value,
      };
    }
    case FETCH_COMPANY_NAME_BY_RUT_START:
      return {
        ...state,
        disabledCompanyName: true,
      };
    case FETCH_COMPANY_NAME_BY_RUT_SUCCESS:
      return {
        ...state,
        nombreEmpresa: payload.data?.name || '',
        disabledCompanyName: payload.data?.name !== '',
      };
    case FETCH_COMPANY_NAME_BY_RUT_FAILURE:
      return {
        ...state,
        loadingSiiDataByRut: false,
      };
    case RESET_TOUR:
      return {
        ...state,
        tour: false,
      };
    case VERIFY_EMAIL_START:
      return {
        ...state,
        verifyingEmail: true,
        emailValid: false,
        emailSuggestion: null,
      };
    case VERIFY_EMAIL_SUCCESS: {
      const {
        data: { emailValid, emailSuggestion },
      } = payload;
      return {
        ...state,
        verifyingEmail: false,
        emailValid,
        emailSuggestion,
      };
    }
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyingEmail: false,
        emailValid: false,
        emailSuggestion: null,
      };
    case RESET_VERIFY_EMAIL:
      return {
        ...state,
        verifyingEmail: false,
        emailValid: false,
        emailSuggestion: null,
      };
    default:
      return state;
  }
}
