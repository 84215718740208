import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography, FormGroup } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/CheckCircle';
import logoSii from '../../assets/logosii.png';
import Input from '../form/Input';
import { registerPfx } from '../../actions/sii';
import Container from './Container';
import ModalDialog from './ModalDialog';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import DropzoneGeneric from './DropzoneGeneric';
import { setActiveStep, setPendingTasks } from '../../actions/assistantAction';
import { ASSISTANT_STEPS } from '../../helpers/Constants';

const useStyles = makeStyles({
  root: {
    width: 400,
    justify: 'center',
  },
  form: {
    width: '100%',
  },
  logoSii: {
    width: 160,
  },
  subTitle: {
    textAlign: 'center',
    color: '#575360',
    font: 'Bold 26px/33px Muli',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '0px 90px 50px 90px',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    background: '#F3F2F5',
    borderRadius: '17px',
  },
  button: {
    padding: '10px',
    fontSize: '14px',
    fontFamily: 'Muli',
    color: 'white',
    borderRadius: '30px',
    width: '150px',
    '&:not(.Mui-disabled)': {
      background: '#7300FF',
      '&:hover': {
        background: '#4d00ab',
      },
    },
  },
  chipSuccess: {
    background: 'green',
    color: 'white',
    fontWeight: 'bold',
  },
  link: {
    cursor: 'pointer',
    color: '#7300FF',
  },
  error: {
    paddingTop: '10px',
    fontWeight: 'bold',
    color: 'red',
  },
  skipDialog: {
    color: '#CECAD9',
  },
});

const PfxUploadDialog = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const businessId = useSelector(state => state.auth.user.Businesses[0].id);
  const userId = useSelector(state => state.auth.user.id);
  const uploadingPfx = useSelector(state => state.sii.uploadingPfx);
  const pfxSuccess = useSelector(state => state.sii.pfxSuccess);
  const pfxError = useSelector(state => state.sii.pfxError);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const [password, setPassword] = useState('');
  const [pfxFile, setPfxFile] = useState('');
  const { STEP_PROFILE_LEGAL } = ASSISTANT_STEPS;

  useEffect(() => {
    dispatch({ type: 'HANDLE_CLOSE_PFX_DIALOG' });
  }, [open, dispatch]);

  const handleChangeStatus = (file, status) => {
    if (status === 'done') {
      setPfxFile(file.file);
    } else if (status === 'removed') {
      setPfxFile(null);
    }
  };

  const handleSubmit = () => {
    dispatch(registerPfx({ businessId, pfx: pfxFile, password, userId }));
    if (showAssistantStepper) dispatch(setActiveStep(STEP_PROFILE_LEGAL));
  };

  const handleClose = () => {
    handleCloseDialog();
    dispatch({ type: 'HANDLE_CLOSE_PFX_DIALOG' });
    if (showAssistantStepper) {
      dispatch(setActiveStep(STEP_PROFILE_LEGAL));
      dispatch(setPendingTasks(true));
    }
    setPassword('');
    setPfxFile('');
  };

  return (
    <ModalDialog
      open={open}
      close={handleClose}
      dialogContent={
        <Container justify="center" gridGap={20}>
          <img src={logoSii} className={classes.logoSii} alt="Logo" />
          {uploadingPfx ? (
            <CircularProgress />
          ) : pfxSuccess ? (
            <Container justify="center">
              <Chip
                color="primary"
                icon={<CheckIcon />}
                className={classes.chipSuccess}
                label="Pfx registrado con éxito"
              />
            </Container>
          ) : (
            <Container gridGap={20} justify="center">
              <Typography variant="body1" className={classes.subTitle}>
                Sube tu .pfx e ingresa tu contraseña
              </Typography>

              <ValidatorForm onSubmit={handleSubmit} className={classes.form}>
                <Container justify="center" width="100%">
                  <DropzoneGeneric
                    accept=".pfx"
                    onChangeStatus={handleChangeStatus}
                    SubmitButtonComponent={null}
                    maxFiles={1}
                    multiple={false}
                  />
                </Container>
                <Container justify="center" gridGap={20}>
                  <FormGroup className={classes.root}>
                    <Input
                      label="Clave certificado"
                      type="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  </FormGroup>
                  {pfxError && (
                    <Typography variant="body2" className={classes.error}>
                      {pfxError}
                    </Typography>
                  )}

                  <SubmitLoadingGradientButton
                    label="Subir"
                    disabled={!password || !pfxFile}
                  />
                  <Typography variant="body1" className={classes.skipDialog}>
                    Despues lo ingreso.{' '}
                    <Link
                      target="_blank"
                      className={classes.link}
                      onClick={handleClose}
                    >
                      Saltar
                    </Link>
                  </Typography>
                </Container>
              </ValidatorForm>
            </Container>
          )}
        </Container>
      }
    />
  );
};

PfxUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default PfxUploadDialog;
