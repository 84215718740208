import {
  SHOW_ASSISTANT,
  SET_ASSISTANT_CURRENT_STEP,
  SET_ASSISTANT_ACTIVE_DOM,
  SET_ASSISTANT_PENDING_TASKS,
} from '../actions/types';

const initialState = {
  active: false,
  activeStep: 0,
  activeDOM: false,
  type: 'operation',
  pendingTasksTooltip: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ASSISTANT: {
      const { active, type } = payload;
      return {
        ...state,
        active,
        type: type || initialState.type,
        activeStep: 0,
        activeDOM: false,
        pendingTasksTooltip: false,
      };
    }
    case SET_ASSISTANT_CURRENT_STEP:
      return {
        ...state,
        activeStep: payload.step,
        activeDOM: false,
        pendingTasksTooltip: false,
      };
    case SET_ASSISTANT_ACTIVE_DOM:
      return {
        ...state,
        activeDOM: payload.activeDOM,
      };
    case SET_ASSISTANT_PENDING_TASKS:
      return {
        ...state,
        pendingTasksTooltip: payload.activeTooltip,
      };
    default:
      return state;
  }
};
