import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  color: {
    color: '#7300FF',
  },
  font: {
    fontFamily: 'Muli',
  },
});

const CircularProgressWithLabel = props => {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CircularProgress variant="static" {...props} className={classes.color} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          className={classes.font}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
