import { Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tour from 'reactour';
import assistanIcon from '../../assets/xepelin-blanco.svg';
import {
  ASSISTANT_STEPS,
  ASSISTANT_STEPS_CONFIG,
  ASSISTANT_TYPES,
} from '../../helpers/Constants';

const useStyles = makeStyles({
  assistanIcon: {
    marginLeft: '-22px',
  },
  stepTitle: {
    textAlign: 'left',
    font: 'normal normal bold 12px/14px Muli',
    letterSpacing: '0px',
    color: '#B0AEBB',
    opacity: 1,
  },
  activeStepTitle: {
    textAlign: 'left',
    font: 'normal normal bold 12px/14px Muli',
    letterSpacing: '0px',
    color: '#F87348',
    opacity: 1,
  },
  backDrop: { color: 'transparent' },
  gridStepper: {
    margin: 0,
  },
  stepper: {
    padding: 0,
  },
  menuStepperIcon: {
    color: 'white',
    background: '#7300ff',
    marginLeft: '-5px',
    borderRadius: '20px',
    padding: '6px',
    '&:hover': {
      color: 'white',
      background: '#7300ff',
    },
  },
  circleActive: {
    height: 13,
    width: 13,
    background: '#F87348',
    borderRadius: 50,
    position: 'relative',
    left: '38%',
    marginRight: '4px',
  },
  circleDisabled: {
    height: 13,
    width: 13,
    background: '#B0AEBB',
    borderRadius: 50,
    position: 'relative',
    left: '38%',
    marginRight: '4px',
  },
  closeButton: {
    position: 'absolute',
    top: -4,
    right: -2,
  },
});

const AssistantStepper = ({ open, handleClose }) => {
  const classes = useStyles();
  const activeStep = useSelector(state => state.assistant.activeStep);
  const type = useSelector(state => state.assistant.type);
  const [showMenu, setShowMenu] = useState(false);
  const stepConfig = ASSISTANT_STEPS_CONFIG[type];
  const { STEP_OPERATION_SII, STEP_OPERATION_SELECT_INVOICES } =
    ASSISTANT_STEPS;

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const getStepIcon = (totalSteps, index) => {
    //  active step
    if (activeStep === index) {
      return (
        <img src={assistanIcon} className={classes.assistanIcon} alt="Logo" />
      );
    }
    // last step
    if (index === totalSteps - 1) {
      return (
        <IconButton
          disableFocusRipple={false}
          className={classes.menuStepperIcon}
          onClick={handleShowMenu}
        >
          {showMenu ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      );
    }

    return (
      <div
        className={
          activeStep > index ? classes.circleActive : classes.circleDisabled
        }
      />
    );
  };

  const steps = stepConfig.map(config => {
    const { selector, stepInteraction = true } = config;
    const menuSteps = [...stepConfig, { title: '' }];
    return {
      selector,
      // eslint-disable-next-line react/prop-types
      content: ({ inDOM = null }) => (
        <Grid
          className={classes.gridStepper}
          container
          justify="center"
          direction="row"
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CancelRoundedIcon />
          </IconButton>
          <Grid item>
            {inDOM ? (
              <Stepper
                classes={{ root: classes.stepper }}
                activeStep={activeStep}
                orientation="vertical"
              >
                {menuSteps.map((step, index) => (
                  <Step key={step.title}>
                    <StepLabel icon={getStepIcon(menuSteps.length, index)}>
                      {showMenu && (
                        <Typography
                          className={
                            activeStep >= index
                              ? classes.activeStepTitle
                              : classes.stepTitle
                          }
                        >
                          {step.title}
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Typography variant="body1">Cargando...</Typography>
            )}
          </Grid>
        </Grid>
      ),
      stepInteraction,
      style: {
        borderRadius: 17,
        minWidth: 50,
      },
      position: [8, 600],
    };
  });

  const getMaskClassName = () => {
    let className = '';
    if (type === ASSISTANT_TYPES.PROFILE || activeStep === STEP_OPERATION_SII) {
      className = classes.backDrop;
    }
    return className;
  };

  const getMaskSpace = () => {
    if (
      type === ASSISTANT_TYPES.OPERATION &&
      activeStep === STEP_OPERATION_SELECT_INVOICES
    ) {
      return 78;
    }
    if (type === ASSISTANT_TYPES.PROFILE) {
      return 300;
    }
    return 0;
  };

  // needed to catch input focus
  setTimeout(() => {
    const elements = document.querySelectorAll('#___reactour button');
    elements.forEach(el => {
      // eslint-disable-next-line no-param-reassign
      el.tabIndex = -1;
    });
  }, 400);

  return (
    <Tour
      startAt={activeStep}
      goToStep={activeStep}
      steps={steps}
      isOpen={open}
      onRequestClose={handleClose}
      showNumber={false}
      showButtons={false}
      showNavigation={false}
      rounded={17}
      disableDotsNavigation
      disableKeyboardNavigation
      closeWithMask={false}
      showCloseButton={false}
      maskClassName={getMaskClassName()}
      maskSpace={getMaskSpace()}
    />
  );
};

AssistantStepper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AssistantStepper;
