import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  IconButton,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DescriptionIcon from '@material-ui/icons/DescriptionTwoTone';
import TransferStatusSteps from './elements/TransferStatusSteps';
import SurplusChip from './elements/SurplusChip';
import { fetchUserOrders } from '../actions/orderAction';
import { trackSegment } from '../helpers/trackingSegment';
import {
  EARLY_PAYMENT,
  EARLY_PAYMENT_LABEL,
  DIRECT_FINANCING,
  DIRECT_FINANCING_LABEL,
  LONG_DATE_FORMAT,
} from '../helpers/Constants';

const useStyles = makeStyles({
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 20,
    minHeight: '60vh',
    borderRadius: 17,
    padding: 20,
    border: '1px solid #E7E5EC',
    height: '100%',
  },
  tableHeaderCell: {
    color: '#A8A4B3',
    fontSize: 12,
    fontWeight: 'bold',
  },
  icon: {
    color: '#FC8463',
  },
});

const OrderStatus = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(state => state.order.userOrders);

  useEffect(() => {
    dispatch(fetchUserOrders());
  }, [dispatch]);

  const handleOperationDetails = operation => {
    // TRACK SEGMENT
    const {
      id: operationId,
      type,
      statusGroup: operationStatus,
      OrderDetails = [],
    } = operation;

    const [orderDetail = {}] = OrderDetails;
    const {
      retencion: surplus = 0,
      montoTransferencia: advanceAmount = 0,
      intereses: interest = 0,
      tasaBase: rate = 0,
    } = orderDetail;

    const trackPayload = {
      operationId,
      operationType: type === EARLY_PAYMENT ? 'PP' : 'FD',
      operationStatus,
      surplus,
      advanceAmount,
      interest,
      rate,
    };
    trackSegment('View Order Detail', trackPayload);
    history.push(`/orders/${operation.id}`);
  };

  return (
    <Fade timeout={400} in>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Paper spacing={3} elevation={0} className={classes.paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell className={classes.tableHeaderCell}>
                      Operación
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Tipo
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Fecha Solicitud
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Excedentes vigentes
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Estado
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders &&
                    orders.map(operation => {
                      const {
                        id,
                        type,
                        OrderDetails = [],
                        createdAt,
                        currentSurplus,
                        percentageSurplus,
                      } = operation;
                      const [orderDetail = {}] = OrderDetails;
                      const { issuedDate } = orderDetail;
                      return (
                        <TableRow key={id}>
                          <TableCell>
                            <Tooltip title="Ver detalles de la operación">
                              <IconButton
                                className={classes.iconButton}
                                onClick={() =>
                                  handleOperationDetails(operation)
                                }
                              >
                                <DescriptionIcon className={classes.icon} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{id}</TableCell>
                          <TableCell>
                            {type === DIRECT_FINANCING
                              ? DIRECT_FINANCING_LABEL
                              : EARLY_PAYMENT_LABEL}
                          </TableCell>
                          <TableCell>
                            {issuedDate
                              ? moment(issuedDate)
                                  .utc()
                                  .locale('es')
                                  .format(LONG_DATE_FORMAT)
                              : moment(createdAt)
                                  .utc()
                                  .locale('es')
                                  .format(LONG_DATE_FORMAT)}
                          </TableCell>
                          <TableCell>
                            {' '}
                            <SurplusChip
                              surplus={currentSurplus}
                              percentage={percentageSurplus}
                              showTooltip
                            />
                          </TableCell>
                          <TableCell>
                            <TransferStatusSteps operation={operation} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default OrderStatus;
