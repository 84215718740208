import {
  CHANGE_CART_STEP,
  CART_CALCULATE_BEGIN,
  CART_CALCULATE_SUCCESS,
  SUBMIT_ORDER_BEGIN,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_ERROR,
  CLEAN_CART,
} from '../actions/types';

const initialState = {
  step: 0,
  // steps: ['Selecciona tus facturas', 'Elige fecha de pago', 'Selecciona cuenta de destino', 'Verifica tu solicitud'],
  steps: [
    'Selecciona tus facturas',
    'Elige fecha de pago',
    'Envía la solicitud',
  ],
  totals: {},
  totalsLoading: false,
  orderSubmitted: false,
  orderSubmittedResult: null,
  orderSubmittedError: false,
  flow: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_CART_STEP:
      return {
        ...state,
        step: action.payload.nextStep,
        flow: action.payload.flow,
      };
    case CART_CALCULATE_SUCCESS:
      return {
        ...state,
        totals: action.payload,
        totalsLoading: false,
      };
    case CART_CALCULATE_BEGIN:
      return {
        ...state,
        totalsLoading: true,
      };
    case SUBMIT_ORDER_BEGIN:
      return {
        ...state,
        orderSubmitted: true,
        orderSubmittedError: false,
        orderSubmittedResult: null,
      };
    case SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        orderSubmitted: false,
        orderSubmittedResult: action.payload,
      };
    case SUBMIT_ORDER_ERROR:
      return {
        ...state,
        orderSubmitted: false,
        orderSubmittedError: true,
        orderSubmittedResult: null,
      };
    case CLEAN_CART:
      return {
        ...state,
        totals: initialState.totals,
      };
    default:
      return state;
  }
}
