import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const BootstrapButton = withStyles({
  root: {
    color: '#FFF',
    background: '#7300FF',
    borderRadius: '40px',
    borderColor: '#007bff',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '7px 40px',
    '& span': {
      fontSize: 14,
    },
    '&:hover': {
      background: '#8923F5',
      borderColor: '#0062cc',
    },
    '&:active': {
      backgroundColor: '#3B44DB',
    },
  },
})(Button);

const GradientButton = ({
  children,
  onClick,
  color,
  variant,
  fullWidth,
  size,
  disabled,
  type,
  startIcon,
  dataQa,
}) => {
  return (
    <BootstrapButton
      onClick={onClick}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      type={type}
      startIcon={startIcon}
      data-qa={dataQa}
    >
      {children}
    </BootstrapButton>
  );
};

GradientButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  fullWidth: true,
  size: 'small',
  disabled: false,
  type: null,
  startIcon: null,
  onClick: null,
  dataQa: '',
};

GradientButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  startIcon: PropTypes.string,
  dataQa: PropTypes.string,
};

export default GradientButton;
