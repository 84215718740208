import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeFirstNotification } from '../../actions/notificationsAction';
import SnackbarUtils from '../../helpers/SnackbarUtils';

export default function (ComposedComponent) {
  // eslint-disable-next-line react/prefer-stateless-function
  class NotificationHandler extends Component {
    render() {
      const { notifications = [], removeFirstNotification } = this.props;
      if (notifications?.length > 0) {
        const [notification] = notifications;
        if (notification) {
          const { message, variant } = notification;
          SnackbarUtils.handleAlerts(message, variant);
          removeFirstNotification();
        }
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      notifications: state.notifications.notifications,
    };
  };

  NotificationHandler.propTypes = {
    notifications: PropTypes.array.isRequired,
    removeFirstNotification: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = dispatch => {
    return {
      removeFirstNotification: () => dispatch(removeFirstNotification()),
    };
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSnackbar
  )(NotificationHandler);
}
