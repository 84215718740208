import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import GradientButton from './GradientButton';

const useStyles = makeStyles({
  label: {
    // padding: '10px 20px',
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  wrapper: {
    position: 'relative',
  },
});

const SubmitLoadingGradientButton = props => {
  const { submit, disabled, label, onClick } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <GradientButton
        classes={{
          root: classes.label,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disabled={submit || disabled}
        type="submit"
        onClick={onClick}
      >
        {label}
      </GradientButton>
      {submit && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

SubmitLoadingGradientButton.defaultProps = {
  onClick: null,
  disabled: false,
  submit: null,
};

SubmitLoadingGradientButton.propTypes = {
  label: PropTypes.string.isRequired,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SubmitLoadingGradientButton;
