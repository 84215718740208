import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withSnackbar } from 'notistack';
import Input from './form/Input';
import logoSii from '../assets/logosii.png';
import SubmitLoadingGradientButton from './form/SubmitLoadingGradientButton';
import { registerSii } from '../actions/sii';
import CustomInfoCard from './elements/CustomInfoCard';
import Container from './elements/Container';
import ModalDialog from './elements/ModalDialog';
import AssistantTooltip from './elements/AssistantTooltip';
import { VALIDATION_TYPES, VALIDATION_MESSAGES } from '../helpers/Constants';

const useStyles = {
  logoSii: {
    width: 160,
  },
  subTitle: {
    textAlign: 'center',
    color: '#575360',
  },
  form: {
    width: '100%',
  },
  formContainer: {
    maxWidth: '80%',
    margin: '0 auto',
  },
  skipDialog: {
    color: '#CECAD9',
  },
  link: {
    cursor: 'pointer',
    color: '#1A73E7',
  },
  errorInput: {
    border: '1px solid red',
    boxShadow: '0px 0px 0px 1px red',
    borderRadius: '20px',
  },
  dialogActions: {
    padding: '8px 0px 8px 0px',
  },
  errorCard: {
    border: '1px solid #ef5350',
    background: '#ffcdd2',
    color: '#b71c1c',
    borderRadius: 20,
  },
  errorCardHeader: {
    paddingBottom: 0,
  },
  errorTitle: {
    fontWeight: 700,
    fontSize: 15,
  },
  errorCardContent: {
    paddingTop: 5,
    fontSize: 10,
  },
};

class SiiDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.counter = 0;
  }

  componentDidUpdate(prevProps) {
    const { loadingSiiData, handleCloseSiiDialog } = this.props;
    if (loadingSiiData !== prevProps.loadingSiiData && loadingSiiData) {
      handleCloseSiiDialog();
    }
  }

  handleSubmit() {
    const { registerSii: register, rutEmpresa, claveSII, token } = this.props;
    register(rutEmpresa, claveSII, token);
  }

  render() {
    const {
      classes,
      open,
      handleInputChange,
      submit,
      handleSkipSiiDialog,
      rutEmpresa,
      claveSII,
      siiRegisterErrors,
      showAssistantStepper,
    } = this.props;

    const { REQUIRED } = VALIDATION_TYPES;
    const { REQUIRED_MSG } = VALIDATION_MESSAGES;

    return (
      <ModalDialog
        open={open}
        dialogContent={
          <Container justify="center" gridGap={20}>
            <img src={logoSii} className={classes.logoSii} alt="Logo" />
            <Typography variant="body1" className={classes.subTitle}>
              Leemos la información de la empresa directamente del SII para
              darte la mejor alternativa de financiamiento y mostrarla en tiempo
              real en tu plataforma.
            </Typography>

            <ValidatorForm
              onSubmit={() => this.handleSubmit()}
              className={classes.form}
            >
              <Container
                className={classes.formContainer}
                gridGap={20}
                justify="center"
              >
                <Container>
                  <Input
                    label="RUT Empresa"
                    autoComplete="new-password"
                    value={rutEmpresa}
                    validators={[REQUIRED]}
                    errorMessages={[REQUIRED_MSG]}
                    hasError={!!siiRegisterErrors}
                  />
                  <AssistantTooltip
                    type="sii"
                    open={showAssistantStepper}
                    element={
                      <Grid>
                        <Input
                          label="Clave SII"
                          type="password"
                          autoComplete="new-password"
                          value={claveSII}
                          onChange={e =>
                            handleInputChange('claveSII', e.target.value)
                          }
                          validators={[REQUIRED]}
                          errorMessages={[REQUIRED_MSG]}
                          hasError={!!siiRegisterErrors}
                        />
                      </Grid>
                    }
                  />
                </Container>
                {siiRegisterErrors && (
                  <CustomInfoCard
                    emphasis="danger"
                    title="¡Error de credenciales!"
                    justify="center"
                  >
                    El RUT y/o la clave ingresada no coinciden. Por favor
                    intenta nuevamente.
                  </CustomInfoCard>
                )}

                <CustomInfoCard title="¡No te preocupes!" justify="center">
                  Sólo podemos leer información, no realizamos ni podemos hacer
                  algún cambio. Además, la contraseña se guarda encriptada.
                </CustomInfoCard>
                <SubmitLoadingGradientButton
                  submit={submit}
                  label="Cargar datos"
                />
                <Typography variant="body1" className={classes.skipDialog}>
                  Despues lo ingreso.{' '}
                  <Link
                    color="primary"
                    target="_blank"
                    className={classes.link}
                    onClick={handleSkipSiiDialog}
                  >
                    Saltar
                  </Link>
                </Typography>
              </Container>
            </ValidatorForm>
          </Container>
        }
      />
    );
  }
}

SiiDialog.propTypes = {
  classes: PropTypes.shape({
    logoSii: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    formContainer: PropTypes.string.isRequired,
    errorCard: PropTypes.string.isRequired,
    errorCardHeader: PropTypes.string.isRequired,
    errorTitle: PropTypes.string.isRequired,
    errorCardContent: PropTypes.string.isRequired,
    skipDialog: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  registerSii: PropTypes.func.isRequired,
  siiRegisterErrors: PropTypes.string.isRequired,
  rutEmpresa: PropTypes.string.isRequired,
  claveSII: PropTypes.string.isRequired,
  loadingSiiData: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseSiiDialog: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  submit: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  showAssistantStepper: PropTypes.bool.isRequired,
  handleSkipSiiDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    rutEmpresa:
      state.auth.user && state.auth.user.Businesses.length > 0
        ? state.auth.user.Businesses[0].rut
        : null,
    claveSII: state.register.claveSII,
    submit: state.sii.credentialsSubmit,
    loadingSiiData: state.sii.loadingSiiData,
    errors: state.register.errors,
    siiRegisterErrors: state.sii.errors,
    token: state.auth.token,
    showAssistantStepper: state.assistant.active,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleInputChange: (field, value) => {
      dispatch({ type: 'REGISTER_INPUT_CHANGE', field, value });
    },
    registerSii: (rut, password) => dispatch(registerSii(rut, password)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles),
  withSnackbar
)(SiiDialog);
