import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    border: '1px solid #1A73E7',
    background: '#EDF5FF',
    color: '#1054AD',
    borderRadius: 17,
  },
  danger: {
    background: '#ffeded',
    color: '#e91e63',
    border: '1px solid #e91e63',
  },
  cardHeader: {
    paddingBottom: 0,
    '& span': {
      fontSize: '14px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
  },
  cardContent: {
    paddingTop: 5,
    fontSize: 12,
    '& span': {
      fontSize: '14px',
    },
  },
});

const CustomInfoCard = ({ title, emphasis, children, justify }) => {
  const classes = useStyles();

  return (
    <>
      <Card
        elevation={0}
        className={`${classes.card} ${emphasis && classes[`${emphasis}`]}`}
        style={{ textAlign: justify || 'flex-start' }}
      >
        <CardHeader
          className={classes.cardHeader}
          classes={{ root: classes.title }}
          title={title || 'Card title'}
        />
        <CardContent
          className={classes.cardContent}
          classes={{
            root: classes.cardContent,
          }}
        >
          {children || 'Card Content'}
        </CardContent>
      </Card>
    </>
  );
};

CustomInfoCard.defaultProps = {
  emphasis: null,
};

CustomInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  emphasis: PropTypes.string,
  justify: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default CustomInfoCard;
