import * as actions from '../actions/websocket';
import {
  WS_CONNECT,
  WS_DISCONNECT,
  LOAD_SII_DATA_START,
} from '../actions/types';

const socketMiddleware = () => {
  let socket = null;

  const onOpen = store => event => {
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected());
  };

  const onMessage = store => event => {
    const payload = JSON.parse(event.data);

    if (payload?.message?.command === 'JOB_SII_SCRAPE_DATA') {
      store.dispatch(actions.loadSiiDataUpdate(payload.message));
    }
  };

  // eslint-disable-next-line consistent-return
  return store => next => action => {
    switch (action.type) {
      case WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(process.env.REACT_APP_WS_URL);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case LOAD_SII_DATA_START:
        if (socket) {
          socket.send(
            JSON.stringify({
              command: 'LOAD_SII_DATA_START',
              token: action.token,
            })
          );
        }
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
