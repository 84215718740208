import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { resetPasswordEmailRequest } from '../actions/resetPasswordAction';
import { loginUser } from '../actions/authentication';
import SubmitLoadingGradientButton from './form/SubmitLoadingGradientButton';
import Input from './form/Input';
import CustomInfoCard from './elements/CustomInfoCard';

const useStyles = theme => ({
  formOptions: {
    marginTop: 20,
    fontSize: 12,
    fontFamily: 'Muli',
  },
  grayText: {
    color: '#CECAD9',
  },
  link: {
    cursor: 'pointer',
  },
  smallLink: {
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: 14,
    marginTop: 20,
  },
  smallText: {
    fontSize: 15,
  },
  dangerText: {
    color: 'red',
    fontSize: 14,
    paddingTop: 10,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  marginTop: {
    marginTop: 20,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: false,
      errors: {},
      showPasswordReset: false,
    };
  }

  componentDidMount() {
    this.props.resetLoginSubmitState();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/');
    }
    if (nextProps.errors) {
      this.props.enqueueSnackbar('Email o contraseña inválida', {
        variant: 'error',
      });
      this.setState({
        errors: nextProps.errors,
      });
    } else {
      this.setState({
        errors: {},
      });
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { loginUser, utmTrackSegmentInfo } = this.props;
    const user = {
      email,
      password,
    };
    loginUser(user, utmTrackSegmentInfo);
  };

  handleInputChange = e => {
    const event = { ...e };
    this.setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  handleResetPasswordFormSubmit = e => {
    e.preventDefault();
    const email = { email: this.state.email };

    this.props.resetPasswordEmailRequest(email);
  };

  handleToggleShowReset = () => {
    this.setState(prevState => ({
      ...prevState,
      showPasswordReset: !prevState.showPasswordReset,
    }));
  };

  render() {
    const { classes, submit, emailSent, resetPasswordEmailSubmit } = this.props;
    const { showPasswordReset } = this.state;
    return (
      <>
        {showPasswordReset ? (
          <Grid container justify="center" direction="column">
            {!emailSent && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    className={classes.title}
                    align="center"
                  >
                    Recupera tu contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={this.handleResetPasswordFormSubmit}>
                    <Input
                      label="Email"
                      onChange={e => this.handleInputChange(e)}
                      value={this.state.email}
                      name="email"
                      dontvalidate="true"
                      disabled={resetPasswordEmailSubmit}
                    />
                    <SubmitLoadingGradientButton
                      label="Enviar"
                      disabled={resetPasswordEmailSubmit}
                    />
                  </form>
                </Grid>
              </>
            )}
            {emailSent && (
              <Grid item xs={12} className={classes.marginTop}>
                <CustomInfoCard title="Correo enviado!">
                  Te hemos enviado un email con las instrucciones para que
                  reestablezcas tu contraseña.
                </CustomInfoCard>
              </Grid>
            )}
            <Grid item xs={12} className={classes.justifyCenter}>
              <Link
                color="primary"
                target="_blank"
                className={classes.smallLink}
                onClick={() => this.handleToggleShowReset()}
              >
                Volver al inicio
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" direction="column">
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.title} align="center">
                ¡Que bueno verte de nuevo!
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <form onSubmit={e => this.handleFormSubmit(e)}>
                <Input
                  label="Email"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.email}
                  name="email"
                  dontvalidate="true"
                />
                <Input
                  label="Contraseña"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.password}
                  name="password"
                  type="password"
                  dontvalidate="true"
                />
                <SubmitLoadingGradientButton submit={submit} label="Ingresar" />
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.formOptions}
                >
                  <Grid item>
                    <Link
                      color="primary"
                      target="_blank"
                      className={classes.link}
                      onClick={() => this.handleToggleShowReset()}
                    >
                      Olvidé mi contraseña
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  errors: PropTypes.objectOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  resetPasswordEmailRequest: PropTypes.func.isRequired,
  resetPasswordEmailSubmit: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  submit: PropTypes.func.isRequired,
  emailSent: PropTypes.func.isRequired,
  resetLoginSubmitState: PropTypes.func.isRequired,
  utmTrackSegmentInfo: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.auth.errors,
  submit: state.auth.loginSubmit,
  resetPasswordEmailSubmit: state.reset.resetPasswordEmailSubmit,
  emailSent: state.reset.resetPasswordEmailSent,
});

const mapDispatchToProps = dispatch => ({
  loginUser: (user, trackSegmentInfo) =>
    dispatch(loginUser(user, trackSegmentInfo)),
  resetPasswordEmailRequest: email =>
    dispatch(resetPasswordEmailRequest(email)),
  resetLoginSubmitState: () =>
    dispatch({
      type: 'RESET_LOGIN_SUBMIT_STATE',
    }),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // { loginUser, resetPasswordEmailRequest }
  ),
  withStyles(useStyles),
  withSnackbar
)(Login);
