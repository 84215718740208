import {
  SET_CURRENT_USER,
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGOUT_USER,
  RESET_LOGIN_SUBMIT_STATE,
  UPDATE_USER_INFO_START,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  RESET_USER_PASSWORD_DIALOG,
} from '../actions/types';
import isEmpty from '../helpers/validation/is-empty';

const initialState = {
  loginSubmit: false,
  isAuthenticated: false,
  errors: null,
  token: null,
  user: {},
  updatingUser: false,
  userUpdated: false,
  userUpdatedError: false,
  updatingPassword: false,
  updatedPassword: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.decoded,
        token: action.payload.token,
        errors: null,
      };

    case LOGIN_BEGIN: {
      return {
        ...state,
        loginSubmit: true,
        errors: null,
      };
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        loginSubmit: false,
        errors: action.payload,
      };
    }
    case LOGOUT_USER: {
      return {
        ...initialState,
      };
    }

    case RESET_LOGIN_SUBMIT_STATE: {
      return {
        ...state,
        loginSubmit: false,
        errors: false,
      };
    }

    case UPDATE_USER_INFO_START: {
      return {
        ...state,
        errors: null,
        updatingUser: true,
        userUpdated: false,
      };
    }

    case UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        updatingUser: false,
        userUpdated: true,
      };
    }

    case UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        errors: action.payload,
        updatingUser: false,
        userUpdated: false,
      };
    }

    case RESET_USER_PASSWORD_DIALOG: {
      return {
        ...state,
        errors: null,
        updatingUser: null,
        userUpdated: false,
      };
    }

    default:
      return state;
  }
}
