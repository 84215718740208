import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Chip, Tooltip } from '@material-ui/core';

const CustomChip = withStyles({
  root: {
    fontWeight: 'bold',
    fontFamily: 'Muli',
    fontSize: '12px',
    height: '25px',
  },
})(Chip);

const useStyles = makeStyles({
  chipGreen: {
    color: '#75B801',
    background: '#E6F8C5',
  },
  chipYellow: {
    color: '#E29D02',
    background: '#FFEFC9',
  },
  chipOrange: {
    color: '#FF7F00',
    background: '#FFD6B9',
  },
  chipRed: {
    color: '#D92F24',
    background: '#F8C7C4',
  },
  chipDefault: {
    color: '#bdbdbd',
    background: '#F0EDF5',
  },
});

const SurplusChip = ({ surplus, percentage = 0, showTooltip }) => {
  const classes = useStyles();

  const getClassName = () => {
    let classColor;
    if (percentage >= 100) {
      classColor = classes.chipGreen;
    } else if (percentage >= 75) {
      classColor = classes.chipYellow;
    } else if (percentage >= 50) {
      classColor = classes.chipOrange;
    } else {
      classColor = classes.chipRed;
    }
    return classColor;
  };

  const finalValue = Number.parseFloat(surplus)
    ? parseFloat(surplus).toFixed(0)
    : surplus;

  const formatedNumberComponent = (
    <NumberFormat
      value={finalValue}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      displayType="text"
      prefix="$"
    />
  );

  const chip = (
    <CustomChip label={formatedNumberComponent} className={getClassName()} />
  );
  return surplus ? (
    showTooltip ? (
      <Tooltip title={`${percentage}% disponible de retención`}>{chip}</Tooltip>
    ) : (
      chip
    )
  ) : (
    '-'
  );
};

SurplusChip.defaultProps = {
  showTooltip: false,
};

SurplusChip.propTypes = {
  surplus: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  showTooltip: PropTypes.bool,
};

export default SurplusChip;
