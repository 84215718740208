import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Grid, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Card from './Card';

const useStyles = makeStyles({
  card: {
    padding: '20px 25px',
  },
  title: {
    font: '14px/18px Muli',
    fontWeight: 700,
    color: '#f87348',
    marginBottom: 5,
  },
  task: {
    font: '13px/16px Muli',
    color: '#6C6776',
    textAlign: 'left',
  },
  icon: {
    color: '#f87348',
    fontSize: 13,
  },
});
const NotificationTaskDrawer = props => {
  const classes = useStyles();
  const { title, detail, hasAction = false, onAction = () => {} } = props;

  return (
    <Card>
      <Grid
        container
        className={classes.card}
        direction="row"
        alignItems="center"
        onClick={onAction}
      >
        <Grid item xs={hasAction ? 11 : 12}>
          <Grid container>
            <Grid item>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography className={classes.task}>{detail}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {hasAction && (
          <Grid item xs={1}>
            <IconButton onClick={onAction}>
              <ArrowForwardIosIcon className={classes.icon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

NotificationTaskDrawer.defaultProps = {
  hasAction: false,
  onAction: () => {},
};

NotificationTaskDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  hasAction: PropTypes.bool,
  onAction: PropTypes.func,
};

export default NotificationTaskDrawer;
