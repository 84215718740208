export const DELETE_BANK_ACCOUNT_MSG_SUCCESS = 'Cuenta eliminada exitosamente';
export const DELETE_BANK_ACCOUNT_MSG_ERROR = 'Error al eliminar la cuenta';
export const SET_DEFAULT_BANK_ACCOUNT_MSG_SUCCESS =
  'Actualización realizada con éxito';
export const SET_DEFAULT_BANK_ACCOUNT_MSG_ERROR =
  'Error al actualizar la información';
export const FETCH_STAKEHOLDER_MSG_ERROR =
  'Error al cargar los representantes legales';
export const CREATE_STAKEHOLDER_MSG_SUCCESS =
  'Representante legal creado exitosamente';
export const EDIT_STAKEHOLDER_MSG_SUCCESS =
  'Representante legal actualizado exitosamente';
export const DELETE_STAKEHOLDER_MSG_SUCCESS =
  'Representante legal eliminado exitosamente';
