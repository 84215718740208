import { WS_CONNECTED, LOAD_SII_DATA_UPDATE } from '../actions/types';

const initialState = {
  wsconnected: false,
  loadStatus: '',
  loadPercentage: 1,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WS_CONNECTED:
      return { ...state, wsconnected: true };
    case LOAD_SII_DATA_UPDATE:
      return {
        ...state,
        loadStatus: payload.status,
        loadPercentage: payload.completion,
      };
    default:
      return state;
  }
};
