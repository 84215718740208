import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import OperationDetails from './OperationDetails';

const useStyles = makeStyles({
  callToAction: {
    marginTop: 10,
  },
  orderDetailsContainer: {
    marginTop: 20,
    borderRadius: 17,
    padding: 20,
    border: '1px solid #E7E5EC',
    minWidth: 238,
  },
});

const CartTotals = ({
  totals: {
    retencion = null,
    intereses = null,
    tasaBase = null,
    montoTransferencia = null,
    retentionPct = null,
    operationCost = null,
  },
  submit,
  disabled,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Paper spacing={3} elevation={0} className={classes.orderDetailsContainer}>
      <Grid container direction="column" spacing={1} align="center">
        <OperationDetails
          porcentajeAnticipo={
            !Number.isNaN(retentionPct) ? 100 - retentionPct : null
          }
          excedentes={retencion}
          tasaInteres={tasaBase}
          diferenciaPrecio={intereses}
          costoOperacional={operationCost}
          totalAGirar={montoTransferencia}
          mostrarTooltips={false}
        />
        <Grid item xs={12} className={classes.callToAction}>
          <SubmitLoadingGradientButton
            submit={submit}
            label="Enviar Solicitud"
            onClick={onClick}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

CartTotals.propTypes = {
  totals: PropTypes.objectOf(PropTypes.number).isRequired,
  retencion: PropTypes.number.isRequired,
  intereses: PropTypes.number.isRequired,
  tasaBase: PropTypes.number.isRequired,
  montoTransferencia: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CartTotals;
