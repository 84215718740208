import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Paper, Typography, Link, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import GradientButton from '../form/GradientButton';
import defaultState from '../../assets/default-state.svg';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { DEFAULT_STATE_CASES } from '../../helpers/Constants';

const useStyles = makeStyles({
  card: {
    borderRadius: 17,
    padding: 20,
    border: '1px solid #E7E5EC',
    minWidth: 238,
    width: '100%',
  },
  root: {
    padding: 20,
  },
  title: {
    font: '28px/31px Muli',
    fontWeight: 700,
    color: '#7300FF',
    marginTop: 115,
  },
  body: {
    font: '14px/19px Muli',
    fontWeight: 700,
    color: '#A2A2A2',
    marginTop: 20,
  },
  link: {
    color: '#0F62FF',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  action: {
    marginTop: 30,
  },
  error: {
    font: '12px/15px Muli',
    fontWeight: 700,
    color: '#D92F24',
    display: 'table',
  },
  errorAlign: {
    paddingLeft: 5,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
});

const DefaultState = props => {
  const classes = useStyles();
  const {
    showDemoOnClick,
    loadInvoicesOnClick,
    setting,
    handleCloseAssistant,
  } = props;
  const siiStatus = useSelector(state => state.sii.siiStatus);
  const loadPercentage = useSelector(state => state.ws.loadPercentage);
  const [progress, setProgress] = useState(0);
  const { DF_DEFAULT, DF_FAILED, DF_LOADING, DF_UPDATE } = DEFAULT_STATE_CASES;

  const { jobProgress = 0 } = siiStatus || {};

  useEffect(() => {
    setProgress(Math.max(jobProgress, loadPercentage));
  }, [jobProgress, loadPercentage]);

  useEffect(() => {
    if (setting === DF_FAILED) {
      window.Intercom(
        'showNewMessage',
        `Hola! Necesito ayuda pues hubo un problema al cargar mis facturas. Saludos.`
      );
    }
  }, [setting, DF_FAILED]);

  const getBody = () => {
    switch (setting) {
      case DF_UPDATE:
        return (
          <>
            Tus credenciales del SII no coinciden con nuestros registros.
            <br />
            Ingresa tu clave de consulta actualizada para poder acceder a tus
            <br />
            facturas.
          </>
        );
      default:
        return (
          <>
            Sin tus datos de Servicio de Impuestos Internos no podemos
            <br />
            mostrar tus facturas disponibles.
            <br />
            Puedes ver la plataforma con datos de prueba
            <br />
            en la siguiente{' '}
            <Link className={classes.link} onClick={showDemoOnClick}>
              demo.
            </Link>
          </>
        );
    }
  };

  const getAction = () => {
    let action;
    switch (setting) {
      case DF_DEFAULT:
        action = (
          <GradientButton fullWidth={false} onClick={loadInvoicesOnClick}>
            Carga tus facturas
          </GradientButton>
        );
        break;
      case DF_UPDATE:
        action = (
          <GradientButton fullWidth={false} onClick={loadInvoicesOnClick}>
            Actualizar
          </GradientButton>
        );
        break;
      case DF_LOADING:
        action = <CircularProgressWithLabel value={progress} size={60} />;
        break;
      case DF_FAILED:
        action = (
          <Grid container direction="row" spacing={1} align="center">
            <Grid item xs={12}>
              <Typography className={classes.error}>
                <CancelIcon />
                <span className={classes.errorAlign}>
                  Error al cargar facturas, contacta a soporte.
                </span>
              </Typography>
            </Grid>
          </Grid>
        );
        handleCloseAssistant();
        break;
      default:
        action = null;
        break;
    }
    return action;
  };

  return (
    <Fade timeout={400} in>
      <div>
        <Grid container className={classes.root}>
          <Grid container spacing={2}>
            <Paper spacing={3} elevation={0} className={classes.card}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <img
                    src={defaultState}
                    width={585}
                    className={classes.imageTour}
                    alt="Logo"
                  />
                </Grid>
                <Grid item xs={4} align="center" id="default-state-loading">
                  <Typography variant="body1" className={classes.title}>
                    No podemos visualizar tus <br />
                    facturas
                  </Typography>
                  <Typography variant="body1" className={classes.body}>
                    {getBody()}
                  </Typography>
                  <div className={classes.action}>{getAction()}</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

DefaultState.propTypes = {
  showDemoOnClick: PropTypes.func.isRequired,
  loadInvoicesOnClick: PropTypes.func.isRequired,
  setting: PropTypes.string.isRequired,
  handleCloseAssistant: PropTypes.func.isRequired,
};

export default DefaultState;
