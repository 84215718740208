import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';

const useStyles = makeStyles({
  baseConfirm: {
    borderRadius: '40px',
    background: '#F6F5F7',
    padding: '0 0 0 30px',
    width: 'fit-content',
  },
  labelChecked: {
    color: '#F87348',
    font: 'normal 12px/38px Muli',
  },
  labelUnchecked: {
    color: '#A8A4B3',
    font: 'normal 12px/38px Muli',
  },
});

const ConfirmAction = props => {
  const { buttonLabel, buttonOnAccept, checked, onChangeCheck } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.baseConfirm}
    >
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onChangeCheck}
                size="small"
                value="checked"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                data-qa="radio-button-check-confirm-action"
              />
            }
            label={
              <Typography
                className={
                  checked ? classes.labelChecked : classes.labelUnchecked
                }
              >
                Confirmo
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <SubmitLoadingGradientButton
          label={buttonLabel}
          onClick={buttonOnAccept}
          size="small"
          disabled={!checked}
          dataQa="button-submit-confirm-action"
        />
      </Grid>
    </Grid>
  );
};

ConfirmAction.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonOnAccept: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onChangeCheck: PropTypes.func.isRequired,
};

export default ConfirmAction;
