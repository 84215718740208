import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core/';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as LogoBlanco } from '../assets/logo-blanco.svg';

const useStyles = makeStyles({
  menuItem: {
    fontWeight: 'bold',
    fontSize: 15,
  },
});

const HomeMobileSideBar = ({ open, handleShowDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const menuItems = [
    {
      name: 'Financiamiento',
      icon: <MonetizationOnOutlinedIcon />,
      path: '/finance',
    },
    {
      name: 'Estado de Operaciones',
      icon: <AssignmentIcon />,
      path: '/orders',
    },
  ];

  const handleClick = newLocation => {
    history.push(newLocation);
  };

  return (
    <Drawer open={open} onClose={() => handleShowDrawer()}>
      <List>
        <ListItem>
          <Grid container justify="center">
            <Grid item>
              <LogoBlanco style={{ height: 70 }} />
            </Grid>
          </Grid>
        </ListItem>
        {menuItems.map(({ name, icon, path }) => (
          <ListItem button key={name} onClick={() => handleClick(path)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="h5" className={classes.menuItem}>
                {name}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

HomeMobileSideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
};

export default HomeMobileSideBar;
