import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import Card from './Card';
import TrendingUpIcon from '../icons/TrendingUpIcon';
import TrendingFlatIcon from '../icons/TrendingFlatIcon';
import TrendingDownIcon from '../icons/TrendingDownIcon';
import { LONG_DATE_FORMAT } from '../../helpers/Constants';

const useStyles = makeStyles({
  rootCard: {
    padding: '15px 10px',
    minHeight: 145,
    height: 145,
    minWidth: 300,
    width: 300,
  },
  action: {
    color: '#F87348',
    font: '12px Muli',
    fontWeight: 700,
  },
  icon: {
    color: '#F87348',
    paddingLeft: 14,
    paddingRight: 23,
  },
  title: {
    font: '16px/20px Muli',
    fontWeight: 700,
    color: '#575360',
    marginBottom: 6,
  },
  body: {
    font: '12px/15px Muli',
    color: '#6C6776',
    marginBottom: 25,
  },
  availableLabel: {
    font: '13px/16px Muli',
    color: '#75B801',
    marginBottom: 10,
  },
  amount: {
    font: '18px/23px Muli',
    fontWeight: 700,
    color: '#575360',
  },
  debtAmount: {
    font: '14px/18px Muli',
    fontWeight: 700,
    color: props => (props.debtAmount === 0 ? '#75B801' : '#575360'),
    display: 'table-cell',
    verticalAlign: 'middle',
    margin: 0,
  },
  percentage: {
    font: '11px/14px Muli',
    color: '#7300FF',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  trending: {
    color: '#7300FF',
    paddingTop: 4,
    margin: '0 3px 0 8px',
  },
  label: {
    font: '11px/14px Muli',
    color: '#6C6776',
    marginTop: 30,
  },
  lastCheck: {
    font: '13px/16px Muli',
    color: '#6C6776',
  },
  variationRoot: {
    textAlign: 'center',
    display: 'table',
    padding: 0,
    height: 32,
  },
  variantItem: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
});

const HomeCard = props => {
  const classes = useStyles(props);
  const {
    title,
    bodyText,
    icon,
    loading,
    hasSetting,
    hasDebt,
    debtAmount,
    variationType,
    variationPercentage,
    lastCheck,
    withAction,
    showVariation,
  } = props;

  return (
    <Card>
      <Grid container direction="row" className={classes.rootCard}>
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          {!loading ? (
            <Skeleton variant="text" />
          ) : !hasSetting ? (
            <>
              <Typography variant="body1" className={classes.body}>
                {bodyText}
              </Typography>
              {withAction && (
                <Typography variant="body1" className={classes.action}>
                  Ingresar datos
                </Typography>
              )}
            </>
          ) : !hasDebt ? (
            <Typography variant="body1" className={classes.body}>
              Aún no hemos consultado
              <br />
              tu estado de {title}.
            </Typography>
          ) : (
            <>
              <Grid item>
                {/* eslint-disable-next-line no-restricted-globals */}
                {!isNaN(debtAmount) ? (
                  <div className={classes.variationRoot}>
                    <NumberFormat
                      value={debtAmount}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      displayType="text"
                      prefix="$"
                      className={classes.debtAmount}
                    />
                    {showVariation &&
                      (variationType === '+' ? (
                        <TrendingUpIcon className={classes.trending} />
                      ) : variationType === '-' ? (
                        <TrendingDownIcon className={classes.trending} />
                      ) : (
                        <TrendingFlatIcon className={classes.trending} />
                      ))}
                    {showVariation && (
                      <Typography
                        variant="body1"
                        className={classes.percentage}
                      >
                        {`${variationPercentage}%`}
                      </Typography>
                    )}
                  </div>
                ) : (
                  <Typography variant="body1" className={classes.debtAmount}>
                    {debtAmount}
                  </Typography>
                )}
              </Grid>
              <Typography variant="body1" className={classes.label}>
                Última consulta
              </Typography>
              <Typography variant="body1" className={classes.lastCheck}>
                {moment(lastCheck).format(LONG_DATE_FORMAT)}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

HomeCard.defaultProps = {
  debtAmount: 0,
  variationType: '=',
  variationPercentage: 0,
  lastCheck: moment(),
  showVariation: true,
  withAction: true,
  hasSetting: false,
  bodyText: null,
};

HomeCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(String).isRequired,
  loading: PropTypes.bool.isRequired,
  hasDebt: PropTypes.bool.isRequired,
  hasSetting: PropTypes.bool,
  bodyText: PropTypes.objectOf(String),
  withAction: PropTypes.bool,
  showVariation: PropTypes.bool,
  debtAmount: PropTypes.number,
  variationType: PropTypes.oneOf(['+', '=', '-']),
  variationPercentage: PropTypes.number,
  lastCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default HomeCard;
