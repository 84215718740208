import {
  SHOW_ASSISTANT,
  SET_ASSISTANT_CURRENT_STEP,
  SET_ASSISTANT_ACTIVE_DOM,
  SET_ASSISTANT_PENDING_TASKS,
} from './types';
import { ASSISTANT_STEPS_CONFIG } from '../helpers/Constants';

export const showAssistant = (active, type) => dispatch => {
  dispatch({ type: SHOW_ASSISTANT, payload: { active, type } });
};

export const setActiveStep = step => dispatch => {
  dispatch({ type: SET_ASSISTANT_CURRENT_STEP, payload: { step } });
};

export const checkActiveDOM = (step, document, type) => dispatch => {
  const config = ASSISTANT_STEPS_CONFIG[type];
  let activeDOM = false;
  const selectedElement = document.body.querySelectorAll(config[step].selector);
  if (selectedElement.length || step === 0) activeDOM = true;
  dispatch({ type: SET_ASSISTANT_ACTIVE_DOM, payload: { activeDOM } });
};

export const setPendingTasks = activeTooltip => dispatch => {
  dispatch({ type: SET_ASSISTANT_PENDING_TASKS, payload: { activeTooltip } });
};
