import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    borderRadius: 100,
    background: '#7300FF',
    fontSize: '12px',
    fontWeight: 900,
    height: 24,
    width: 24,
    lineHeight: '24px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
});

const Pill = props => {
  const classes = useStyles();
  return <Box className={classes.container}>{props.pillcontent}</Box>;
};

export default Pill;
