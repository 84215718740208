import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import verifyRut from '../../helpers/validation/rut';
import BaseDialog from './BaseDialog';
import GreenSwitch from './GreenSwitch';
import {
  VALIDATION_MESSAGES,
  VALIDATION_TYPES,
  BUTTON_LABEL,
} from '../../helpers/Constants';
import { rutCleaner } from '../../helpers/rutClearner';

const useStyles = makeStyles({
  error: {
    color: '#FFF',
    background: '#D92F24',
    padding: 8,
    borderRadius: 5,
    textAlign: 'center',
    fontSize: '12px',
  },
  rootForm: {
    minWidth: '83%',
  },
  selectOutlined: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: '40px',
      background: 'transparent',
    },
  },
  customInput: {
    '& .MuiFormHelperText-root': {
      font: 'bold 11px Muli',
    },
    background: 'transparent',
    font: 'normal 12px Muli',
    '& input': {
      font: 'normal 12px Muli',
    },
    '& label': {
      font: 'normal 12px Muli',
    },
    marginBottom: 12,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '40px',
      },
    },
    '& .MuiFormControlLabel-label': {
      font: 'normal 12px Muli',
    },
  },
  button: {
    marginTop: 30,
    marginBottom: 34,
  },
});

const StakeholderDialog = props => {
  const classes = useStyles();
  const [stakeholder, setStateholder] = useState({
    id: null,
    name: '',
    rut: '',
    conjugal: false,
    email: '',
  });
  const {
    handleCloseDialog,
    handleSubmit,
    open,
    originalStakeholder,
    title,
    stakeholdersError,
    loading,
  } = props;
  const { REQUIRED, VALID_RUT, VALID_EMAIL } = VALIDATION_TYPES;
  const { REQUIRED_MSG, VALID_RUT_MSG, VALID_EMAIL_MSG } = VALIDATION_MESSAGES;

  useEffect(() => {
    ValidatorForm.addValidationRule(VALID_RUT, value => {
      const validation = verifyRut(value);
      if (validation) return true;
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { rut, dv, name, email, conjugal, id } = originalStakeholder || {};
    setStateholder({
      rut: rut ? `${rut}-${dv}` : '',
      name,
      email,
      conjugal,
      id,
    });
  }, [originalStakeholder]);

  const handleInputChange = event => {
    const {
      target: { name, type, checked, value },
    } = event;
    setStateholder({
      ...stakeholder,
      [name]:
        type === 'checkbox'
          ? checked
          : name === 'rut'
          ? rutCleaner(value)
          : value,
    });
  };
  const onSubmit = () => {
    if (originalStakeholder) {
      handleSubmit(stakeholder);
    } else {
      const tempStakeholder = { ...stakeholder };
      delete tempStakeholder.id;
      handleSubmit(tempStakeholder);
    }
  };

  const handleClose = () => {
    handleCloseDialog();
    setStateholder({
      id: null,
      name: '',
      rut: '',
      conjugal: false,
      email: '',
    });
  };

  return (
    <BaseDialog title={title} isOpen={open} handleClose={handleClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={10} className={classes.rootForm}>
          <ValidatorForm onSubmit={onSubmit}>
            <TextValidator
              fullWidth
              size="small"
              variant="outlined"
              label="Rut"
              type="text"
              name="rut"
              value={stakeholder.rut}
              onChange={e => handleInputChange(e)}
              validators={[REQUIRED, VALID_RUT]}
              className={classes.customInput}
              errorMessages={[REQUIRED_MSG, VALID_RUT_MSG]}
              data-qa="input-rut-stakeholder-dialog"
            />
            <TextValidator
              fullWidth
              size="small"
              variant="outlined"
              label="Nombre"
              type="text"
              name="name"
              value={stakeholder.name}
              onChange={e => handleInputChange(e)}
              validators={[REQUIRED]}
              className={classes.customInput}
              errorMessages={[REQUIRED_MSG]}
              data-qa="input-name-stakeholder-dialog"
            />

            <TextValidator
              fullWidth
              size="small"
              variant="outlined"
              label="Email"
              name="email"
              value={stakeholder.email}
              onChange={e => handleInputChange(e)}
              validators={[REQUIRED, VALID_EMAIL]}
              className={classes.customInput}
              errorMessages={[REQUIRED_MSG, VALID_EMAIL_MSG]}
              data-qa="input-email-stakeholder-dialog"
            />

            <FormControlLabel
              control={
                <GreenSwitch
                  checked={stakeholder.conjugal}
                  onChange={e => handleInputChange(e)}
                  name="conjugal"
                />
              }
              label="¿Casado por sociedad conyugal?"
              labelPlacement="start"
              className={classes.customInput}
              data-qa="switch-conjugal-sakeholder-dialog"
            />

            {stakeholdersError && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.error}>
                  Ha ocurrido un error. Revisa los campos e intenta nuevamente
                </Typography>
              </Grid>
            )}
            <div className={classes.button}>
              <SubmitLoadingGradientButton
                label={BUTTON_LABEL.GUARDAR}
                submit={loading}
                dataQa="button-submit-stakeholder-dialog"
              />
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

StakeholderDialog.defaultProps = {
  originalStakeholder: null,
  loading: false,
};

StakeholderDialog.propTypes = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stakeholdersError: PropTypes.bool.isRequired,
  originalStakeholder: PropTypes.object,
  loading: PropTypes.bool,
};

export default StakeholderDialog;
