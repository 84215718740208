import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import HomeLanding from './HomeLanding';
import HomeCartPaymentDate from './HomeCartPaymentDate';
import HomeSteps from './elements/HomeSteps';
import Loader from './elements/Loader';
import { ASSISTANT_STEPS } from '../helpers/Constants';

const useStyles = () => ({
  content: {
    paddingTop: 30,
  },
});

class HomeCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
  }

  componentDidMount() {
    const { STEP_OPERATION_SELECT_DATES } = ASSISTANT_STEPS;
    const { showAssistantStepper, assistantActiveStep } = this.props;
    if (
      showAssistantStepper &&
      assistantActiveStep === STEP_OPERATION_SELECT_DATES
    ) {
      this.setState({ step: 3 });
    }
  }

  handleStepChange(nextStep) {
    this.setState(prevState => ({
      ...prevState,
      step: nextStep,
    }));
  }

  navigationRender(step) {
    const { operationType, demo } = this.props;
    switch (step) {
      case 0:
        return (
          <HomeLanding
            operationType={operationType}
            demo={demo}
            handleStepChange={currStep => this.handleStepChange(currStep)}
          />
        );
      case 1:
        return (
          <HomeCartPaymentDate
            operationType={operationType}
            handleStepChange={currStep => this.handleStepChange(currStep)}
          />
        );
      case 2:
        return (
          <HomeCartPaymentDate
            operationType={operationType}
            handleStepChange={currStep => this.handleStepChange(currStep)}
          />
        );

      case 3:
        return (
          <HomeCartPaymentDate
            operationType={operationType}
            handleStepChange={currStep => this.handleStepChange(currStep)}
          />
        );
      default:
        return (
          <HomeLanding
            operationType={operationType}
            demo={demo}
            handleStepChange={currStep => this.handleStepChange(currStep)}
          />
        );
    }
  }

  render() {
    const {
      cartSteps,
      loadingSiiUserTotalData,
      loadingSiiUserSelectedDTEs,
      classes,
    } = this.props;

    const { step } = this.state;

    return (
      <>
        {loadingSiiUserTotalData || loadingSiiUserSelectedDTEs ? (
          <div style={{ width: '100%' }}>
            <Loader />
          </div>
        ) : (
          <Fade in>
            <div>
              {step !== 4 && <HomeSteps step={step} steps={cartSteps} />}
              <div id="main-table" className={classes.content}>
                {this.navigationRender(step)}
              </div>
            </div>
          </Fade>
        )}
      </>
    );
  }
}

HomeCart.propTypes = {
  operationType: PropTypes.string.isRequired,
  cartSteps: PropTypes.arrayOf().isRequired,
  loadingSiiUserTotalData: PropTypes.bool.isRequired,
  loadingSiiUserSelectedDTEs: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  demo: PropTypes.bool.isRequired,
  assistantActiveStep: PropTypes.number.isRequired,
  showAssistantStepper: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    cartSteps: state.cart.steps,
    loadingSiiUserTotalData: state.sii.loadingSiiUserTotalData,
    loadingSiiUserSelectedDTEs: state.sii.loadingSiiUserSelectedDTEs,
    assistantActiveStep: state.assistant.activeStep,
    showAssistantStepper: state.assistant.active,
  };
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(useStyles)
)(HomeCart);
