import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { DICOM_LABEL, PREVIRED_LABEL, TGR_LABEL } from '../helpers/Constants';
import HomeCard from './elements/HomeCard';
import WalletCard from './elements/WalletCard';
import DicomIcon from './icons/DicomIcon';
import PreviredIcon from './icons/PreviredIcon';
import TgrIcon from './icons/TgrIcon';

const useStyles = makeStyles({
  root: {
    padding: '21px 30px 14px 30px',
  },
  rootContainer: {
    justifyContent: 'space-between',
  },
  hidden: {
    display: 'none',
  },
});

const HomeCards = () => {
  const classes = useStyles();
  const ordersState = useSelector(state => state.order);
  const summaryState = useSelector(state => state.business.settingsResume);
  const user = useSelector(state => state.auth.user);
  const { loadingUserOrders } = ordersState;
  const { dicomInfo = {}, tgrInfo = {}, accountsInfo = {} } =
    summaryState || {};
  const {
    hasDebt: hasTgrDebt = false,
    debt: tgrDebt,
    lastCheck: tgrLastCheck,
  } = tgrInfo;
  const {
    hasDebt: hasDicomDebt = false,
    debt: dicomDebt,
    lastCheck: dicomLastCheck,
    variation: dicomVariation = {},
  } = dicomInfo;
  const {
    type: dicomVariationType,
    percentage: dicomVariationPerc,
  } = dicomVariation;
  const { balance = 0 } = accountsInfo;
  const {
    Businesses: [business],
  } = user;

  const dicomDebtText = dicomDebt === -1 ? 'Rut sin giro comercial' : dicomDebt;

  const bodyTextHomeCard = (
    <>
      Para mostrarte aquí tu
      <br />
      información en tiempo real debes
      <br />
      ingresar tus datos de Previred.
    </>
  );

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.rootContainer}
      >
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item className={classes.hidden}>
              <HomeCard
                title={PREVIRED_LABEL}
                icon={<PreviredIcon />}
                loading={!!summaryState}
                hasDebt={false}
                debtAmount={dicomDebt}
                variationType={dicomVariationType}
                variationPercentage={dicomVariationPerc}
                lastCheck={dicomLastCheck}
                bodyText={bodyTextHomeCard}
              />
            </Grid>
            <Grid item>
              <HomeCard
                title={TGR_LABEL}
                icon={<TgrIcon />}
                loading={!!summaryState}
                hasSetting
                hasDebt={hasTgrDebt}
                debtAmount={tgrDebt}
                showVariation={false}
                lastCheck={tgrLastCheck}
                withAction={false}
              />
            </Grid>
            <Grid item>
              <HomeCard
                title={DICOM_LABEL}
                icon={<DicomIcon />}
                loading={!!summaryState}
                hasSetting
                hasDebt={hasDicomDebt}
                debtAmount={dicomDebtText}
                variationType={dicomVariationType}
                variationPercentage={dicomVariationPerc}
                lastCheck={dicomLastCheck}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <WalletCard
                balance={balance}
                business={business}
                loading={loadingUserOrders}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeCards;
