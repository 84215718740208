import React from 'react';
import InfoField from './InfoField';

const FeaturedAmmount = ({ title, ammount }) => {
  return (
    <InfoField
      gridGap={10}
      align="center"
      label={title}
      labelStyles={{
        fontSize: 16,
        fontWeight: 400,
        color: '#75B801',
      }}
      dataStyles={{
        fontSize: 27,
        fontWeight: 800,
      }}
      data={ammount}
    />
  );
};

export default FeaturedAmmount;
