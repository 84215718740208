import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';
import { withSnackbar } from 'notistack';
import Login from './components/Login';
import Register from './components/Register';
import ClaveSii from './components/ClaveSii';
import StartSideBarNavigationButton from './components/elements/StartSideBarNavigationButton';
import MobileStartMenu from './components/MobileStartMenu';
import { registerUser } from './actions/user';
import { ReactComponent as LogoColor } from './assets/logo-color.svg';
import logoFinmas from './assets/logofinmas.png';
import { trackSegment } from './helpers/trackingSegment';

const useStyles = {
  root: {
    flexGrow: 1,
  },
  leftContainer: {
    minHeight: '100vh',
    background: 'linear-gradient(90deg, #F87C54 30%, #FAB584 100%)',
    borderRight: '9px solid',
    borderImageSlice: 1,
    borderImageSource: 'linear-gradient(0deg, #A739E3, #7300FF)',
  },
  logoXepelin: {
    width: 200,
    margin: 40,
  },
  logoFinmas: {
    width: 200,
    margin: '5px 40px 40px 40px',
  },
};

class Startup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'register',
      errors: '',
      utmTrackSegmentInfo: {},
    };
  }

  componentDidMount = () => {
    const queryParams = queryString.parse(this.props.location.search);
    const {
      utm_campaign: utmCampaign = null,
      utm_medium: utmMedium = null,
      utm_source: utmSource = null,
    } = queryParams;

    const utmTrackSegmentInfo = {
      utmCampaign,
      utmMedium,
      utmSource,
    };

    this.setState(prevState => ({
      ...prevState,
      utmTrackSegmentInfo,
    }));
  };

  componentDidUpdate = prevProps => {
    const { errors: prevErrors } = prevProps;
    const { errors: propsErrors, enqueueSnackbar } = this.props;
    if (
      prevErrors !== propsErrors &&
      prevErrors &&
      propsErrors &&
      propsErrors.REGISTER_ERROR
    ) {
      enqueueSnackbar('Error en el registro', { variant: 'error' });
    }
  };

  handleNext = step => {
    this.props.handleStepChange(step);
  };

  handleActive = (e, id) => {
    if (id === 'login') {
      const { utmCampaign, utmMedium, utmSource } =
        this.state.utmTrackSegmentInfo;
      trackSegment('Login Clicked', { utmCampaign, utmMedium, utmSource });
    }
    this.setState(prevState => ({
      ...prevState,
      active: id,
    }));
  };

  handleSubmit = () => {
    const {
      nombre,
      nombreEmpresa,
      rutEmpresa,
      claveSII,
      email,
      telefono,
      password,
    } = this.props;

    const sendData = {
      nombre,
      nombreEmpresa,
      rutEmpresa: rutEmpresa.trim(),
      claveSII,
      email,
      telefono,
      password,
    };

    this.props.registerUser(sendData, this.state.utmTrackSegmentInfo);
  };

  render() {
    const { classes, step } = this.props;
    const { active, utmTrackSegmentInfo, queryParams } = this.state;
    return (
      <>
        <Hidden mdUp>
          <MobileStartMenu active={active} handleActive={this.handleActive} />
        </Hidden>
        <Grid container spacing={0}>
          <Hidden smDown>
            <Grid item md={4} className={classes.leftContainer}>
              <Grid item xs={12} container direction="row-reverse">
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  style={{ marginTop: '50%' }}
                >
                  <StartSideBarNavigationButton
                    active={active === 'login' ? 1 : 0}
                    onClick={e => this.handleActive(e, 'login')}
                  >
                    Ingreso
                  </StartSideBarNavigationButton>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row-reverse">
                <Grid item xs={4} container direction="column">
                  <StartSideBarNavigationButton
                    active={active === 'register' ? 1 : 0}
                    onClick={e => this.handleActive(e, 'register')}
                  >
                    Registro
                  </StartSideBarNavigationButton>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid
              alignItems="center"
              justify="center"
              direction="column"
              container
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Hidden smDown>
                      <LogoColor className={classes.logoXepelin} />
                    </Hidden>
                  </Grid>
                  <Grid item xs={6}>
                    <Hidden smDown>
                      <img
                        src={logoFinmas}
                        className={classes.logoFinmas}
                        alt="Finmas"
                      />
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {active === 'login' ? (
                  <Fade in={active === 'login'} timeout={500}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Login utmTrackSegmentInfo={utmTrackSegmentInfo} />
                    </Grid>
                  </Fade>
                ) : null}
                {active !== 'login' && step === 1 ? (
                  <Fade in={step === 1} timeout={500}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Register
                        queryParams={queryParams}
                        handleSubmit={() => this.handleSubmit()}
                      />{' '}
                    </Grid>
                  </Fade>
                ) : null}
                {active !== 'login' && step === 2 ? (
                  <Slide direction="left" in={step === 2} timeout={500}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                      xs={12}
                    >
                      <ClaveSii
                        handleNext={step => this.handleNext(step)}
                        handleSubmit={() => this.handleSubmit()}
                      />
                    </Grid>
                  </Slide>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

Startup.propTypes = {
  errors: PropTypes.objectOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  nombre: PropTypes.string.isRequired,
  nombreEmpresa: PropTypes.string.isRequired,
  rutEmpresa: PropTypes.string.isRequired,
  claveSII: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  telefono: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  registerUser: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  step: PropTypes.number.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    nombre: state.register.nombre,
    nombreEmpresa: state.register.nombreEmpresa,
    rutEmpresa: state.register.rutEmpresa,
    claveSII: state.register.claveSII,
    email: state.register.email,
    telefono: state.register.telefono,
    password: state.register.password,
    errors: state.register.errors,
    step: state.register.step,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (data, utmTrackSegmentInfo) =>
      dispatch(registerUser(data, utmTrackSegmentInfo)),
    handleStepChange: step => {
      dispatch({ type: 'HANDLE_STEP_CHANGE', step });
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles),
  withSnackbar
)(Startup);
