import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ErrorCircleIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Grid,
} from '@material-ui/core';
import {
  ORDER_STATUSES,
  ORDER_FACTURAS_STATUSES,
  FLOWS_ORDER,
} from '../../helpers/Constants';

const QontoConnector = withStyles({
  alternativeLabel: {
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
    top: 4,
  },
  active: {
    '& $line': {
      borderColor: '#F0EDF5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#F0EDF5',
    },
  },
  error: {
    '& $line': {
      borderColor: 'red',
    },
  },
  line: {
    borderTopWidth: 24,
    borderRadius: 0,
    borderColor: '#F0EDF5',
  },
})(StepConnector);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  step: {
    padding: '2px 2px',
    '& $completed': {
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
      color: '#75B801',
    },
    '& $active': {
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
      color: '#75B801',
    },
    '& $warning': {
      color: '#FF7F00',
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
    },
    '& $error': {
      color: '#D92F24',
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
    },
    '& $disabled': {
      color: 'white',
      background: 'white',
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      padding: '3px 2px 4px 2px',
    },
  },
  date: {
    color: '#A8A4B3',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  disabledIcon: {
    fontSize: '15px',
    fontWeight: 'bold',
    fontFamily: 'Muli',
    background: '#CECAD9',
    padding: '0px 6px',
    border: '1px solid white',
    borderRadius: '20px',
  },
  labelWarning: {
    color: '#FF7F00',
  },
  alternativeLabel: {},
  active: {}, // needed so that the &$active tag works
  completed: {},
  disabled: {},
  error: {},
  warning: {},
}));

const someInvoiceWithStatuses = (invoices, statuses) => {
  return invoices.some(({ status }) => statuses.includes(status));
};

const getFlowKey = operation => {
  const { statusGroup, status, OrderFacturas = [] } = operation;
  const { HARD_COLLECTION, HARD_DELINQUENCY, SOFT_DELINQUENCY, DELINQUENT } =
    ORDER_FACTURAS_STATUSES;
  const { ACTIVE, DEFAULT } = ORDER_STATUSES;

  const hasCriticalStateInvoices = someInvoiceWithStatuses(OrderFacturas, [
    HARD_COLLECTION,
  ]);
  const hasDelinquentInvoices = someInvoiceWithStatuses(OrderFacturas, [
    SOFT_DELINQUENCY,
    HARD_DELINQUENCY,
    DELINQUENT,
  ]);

  let key;
  if (status === ACTIVE && hasCriticalStateInvoices) {
    key = 'ACTIVE_CRITICAL_STATE';
  } else if (status === ACTIVE && hasDelinquentInvoices) {
    key = 'ACTIVE_DELINQUENT';
  } else if ([ACTIVE, DEFAULT].includes(status)) {
    key = status;
  } else {
    key = statusGroup;
  }
  return key;
};

const getStepIcon = (props, warning, classes) => {
  const { error, completed, active, icon: defaultIcon } = props;

  let icon;
  if (warning) {
    icon = <ErrorCircleIcon className={classes.warning} />;
  } else if (error) {
    icon = <ErrorCircleIcon className={classes.error} />;
  } else if (completed || active) {
    icon = <CheckCircleIcon className={classes.completed} />;
  } else {
    icon = (
      <span className={classes.disabled}>
        <div className={classes.disabledIcon}>{defaultIcon}</div>
      </span>
    );
  }
  return icon;
};

const TransferStatusSteps = ({ operation }) => {
  const classes = useStyles();
  const flowKey = getFlowKey(operation);
  const flowSteps = FLOWS_ORDER[flowKey];
  const activeStep = flowSteps.reduce((sum, step) => sum + step.active, -1);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        classes={{
          root: classes.step,
        }}
      >
        {flowSteps.map(step => {
          const { label, error, warning } = step;

          return (
            <Step key={label}>
              <StepLabel
                error={error}
                classes={{
                  root: classes.step,
                  completed: classes.active,
                  active: warning ? classes.warning : classes.active,
                  error: classes.error,
                }}
                StepIconComponent={props =>
                  getStepIcon(props, warning, classes)
                }
              >
                <Grid container direction="column" justify="center">
                  <Grid item xs={12} className={classes.boldFont}>
                    <span className={warning ? classes.labelWarning : null}>
                      {label}
                    </span>
                  </Grid>
                </Grid>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

TransferStatusSteps.propTypes = {
  operation: PropTypes.objectOf(Object).isRequired,
};

export default TransferStatusSteps;
