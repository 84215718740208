import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DicomIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="25.9" height="25.9" />
          <path
            id="Trazado_2491"
            data-name="Trazado 2491"
            d="M21.62,7.21a9.62,9.62,0,0,0-3.37-4.12A9.17,9.17,0,0,0,13,1.55H5.54a2.36,2.36,0,0,0-2.4,2.33v18a2.36,2.36,0,0,0,2.32,2.4H13a9.16,9.16,0,0,0,5.3-1.53,9.68,9.68,0,0,0,3.37-4.12,14.81,14.81,0,0,0,0-11.43Zm-3.91,5.71a8.62,8.62,0,0,1-1.31,4.94,4.19,4.19,0,0,1-3.76,1.84H8V6.14h4.61A4.23,4.23,0,0,1,16.39,8a8.63,8.63,0,0,1,1.32,4.94Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default DicomIcon;
