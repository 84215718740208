import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import GradientButton from './form/GradientButton';
import imgPfx from '../assets/pfx.svg';
import TopImageModalDialog from './elements/TopImageModalDialog';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
});

const FacturasSentPfxDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/user/profile/sii');
  };

  return (
    <TopImageModalDialog
      isOpen={open}
      withCloseButton
      handleClose={handleClose}
      imageSource={imgPfx}
      imageWidth={122}
      title="Ingresa tu certificado digital (.PFX)"
      subtitle="¡Felicidades! Tu solicitud ha sido enviada."
      content={
        <Typography className={classes.content}>
          Con tu PFX podemos realizar{' '}
          <strong>
            la cesión de tus
            <br />
            facturas de forma automática y rápida.
          </strong>
        </Typography>
      }
      actions={
        <Grid xs={5}>
          <GradientButton onClick={handleConfirm}>
            Ir al Perfil de usuario
          </GradientButton>
        </Grid>
      }
    />
  );
};

FacturasSentPfxDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FacturasSentPfxDialog;
