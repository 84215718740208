import { combineReducers } from 'redux';
import authReducer from './authReducer';
import registerReducer from './registerReducer';
import siiReducer from './siiReducer';
import homeReducer from './homeReducer';
import webSocketReducer from './webSocketReducer';
import cartReducer from './cartReducer';
import bankReducer from './bankReducer';
import orderReducer from './orderReducer';
import resetPasswordReducer from './resetPasswordReducer';
import simulationReducer from './simulationReducer';
import holidaysReducer from './holidaysReducer';
import businessReducer from './businessReducer';
import userReducer from './userReducer';
import uiStatesReducer from './uiStatesReducer';
import notificationsReducer from './notificationsReducer';
import stakeholdersReducer from './stakeholdersReducer';
import assistantReducer from './assistantReducer';
import { LOGOUT_USER } from '../actions/types';
import walletReducer from './walletReducer';

const siteReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  reset: resetPasswordReducer,
  home: homeReducer,
  ws: webSocketReducer,
  sii: siiReducer,
  cart: cartReducer,
  bank: bankReducer,
  order: orderReducer,
  simulation: simulationReducer,
  holidays: holidaysReducer,
  business: businessReducer,
  user: userReducer,
  banks: bankReducer,
  uiStates: uiStatesReducer,
  notifications: notificationsReducer,
  stakeholders: stakeholdersReducer,
  assistant: assistantReducer,
  walletRequest: walletReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // eslint-disable-next-line no-param-reassign
    state = {};
  }
  return siteReducer(state, action);
};

export default rootReducer;
