import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Grid, IconButton, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomPanel from './elements/CustomPanel';
import OrangeButton from './elements/OrangeButton';
import {
  createStakeholders,
  deleteStakeholders,
  resetActions,
  editStakeholders,
  fetchLegalFile,
  fetchStakeholders,
} from '../actions/stakeholders';
import { fetchBusinessLegalFile } from '../actions/businessAction';
import StakeholderDialog from './elements/StakeholderDialog';
import ConfirmDialog from './elements/ConfirmDialog';
import DeleteIcon from './icons/DeleteIcon';
import AssistantTooltip from './elements/AssistantTooltip';
import { setActiveStep, setPendingTasks } from '../actions/assistantAction';
import {
  CONTRACT,
  LEGAL_REPORT,
  TERMS,
  POLICY,
  BUTTON_LABEL,
  ASSISTANT_STEPS,
} from '../helpers/Constants';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '32px',
  },
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
  },
  paddedContainer: {
    padding: 20,
  },
  document: {
    padding: '6px 0',
    font: 'bold 14px Muli',
    color: '#575360',
  },
  documentItem: {
    paddingLeft: 20,
    marginBottom: 15,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#E7E5EC',
    margin: '16px 0',
  },
  info: {
    fontSize: '12px',
    padding: '10px 0',
    fontWeight: 'bold',
    color: '#575360',
  },
  label: {
    color: '#6C6776',
    fontSize: '12px',
  },
  conjugalIcon: {
    color: '#6c6776b5',
    '& svg': {
      fontSize: 16,
    },
    padding: 3,
  },
  editButton: {
    color: '#6c6776b5',
    '& svg': {
      fontSize: 16,
    },
    marginLeft: '5px',
  },
  gridTable: {
    display: 'grid',
    gridTemplateColumns: '15% 40% 25% 10% 10%',
  },
  rut: {
    whiteSpace: 'nowrap',
  },
  header: {
    alignItems: 'end',
    display: 'grid',
    marginBottom: 15,
    padding: '0 5px',
  },
  email: {
    whiteSpace: 'nowrap',
  },
  conjugal: {
    textAlign: 'center',
  },
  actions: {
    textAlign: 'right',
  },
  noInfo: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#6C6776',
  },
  bodyDocuments: {
    height: 195,
  },
});

const UserLegal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    openCreateStakeholderDialog,
    setOpenCreateStakeholderDialog,
  ] = useState(false);
  const [openEditStakeholderDialog, setOpenEditStakeholderDialog] = useState(
    false
  );
  const [
    openDeleteStakeholderDialog,
    setOpenDeleteStakeholderDialog,
  ] = useState(false);
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const [showAssistantTooltip, setShowAssistantTooltip] = useState(
    showAssistantStepper
  );
  const stakeholdersState = useSelector(state => state.stakeholders);
  const {
    stakeholders = [],
    stakeholdersError,
    operationCompleted,
    loadingStakeholders,
    downloadingLegalReport,
    downloadingContract,
    downloadingTerms,
    downloadingPolicy,
  } = stakeholdersState;
  const user = useSelector(state => state.auth.user);
  const { Businesses = [], id: userId } = user;
  const [business] = Businesses;
  const { id: businessId, contract = false, legalReport = false } = business;
  const { STEP_PROFILE_BANK_ACCOUNT } = ASSISTANT_STEPS;

  useEffect(() => {
    dispatch(fetchStakeholders(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      (openDeleteStakeholderDialog ||
        openCreateStakeholderDialog ||
        openEditStakeholderDialog) &&
      operationCompleted &&
      !stakeholdersError
    ) {
      setOpenDeleteStakeholderDialog(false);
      setOpenEditStakeholderDialog(false);
      setOpenCreateStakeholderDialog(false);
    }
  }, [
    operationCompleted,
    stakeholdersError,
    openDeleteStakeholderDialog,
    openCreateStakeholderDialog,
    openEditStakeholderDialog,
  ]);

  const toggleCreateStakeholderDialog = () => {
    if (showAssistantStepper && openCreateStakeholderDialog) {
      dispatch(setActiveStep(STEP_PROFILE_BANK_ACCOUNT));
      dispatch(setPendingTasks(true));
    } else if (!openCreateStakeholderDialog) {
      dispatch(resetActions());
    }
    setOpenCreateStakeholderDialog(!openCreateStakeholderDialog);
    setShowAssistantTooltip(false);
  };

  const toggleEditStakeholderDialog = () => {
    if (!openEditStakeholderDialog) {
      dispatch(resetActions());
    }
    setOpenEditStakeholderDialog(!openEditStakeholderDialog);
  };

  const toggleDeleteStakeholderDialog = () => {
    if (!openDeleteStakeholderDialog) {
      dispatch(resetActions());
    }
    setOpenDeleteStakeholderDialog(!openDeleteStakeholderDialog);
  };

  const deleteStakeholder = stakeholder => {
    setSelectedStakeholder(stakeholder);
    toggleDeleteStakeholderDialog();
  };

  const editStakeholder = stakeholder => {
    setSelectedStakeholder(stakeholder);
    toggleEditStakeholderDialog();
  };

  const handleCreateStakeholders = stakeholder => {
    dispatch(createStakeholders(stakeholder, businessId, userId));
    if (showAssistantStepper)
      dispatch(setActiveStep(STEP_PROFILE_BANK_ACCOUNT));
  };

  return (
    <Fade in timeout={400}>
      <Box className={classes.container}>
        <StakeholderDialog
          title="Agregar representante legal"
          open={openCreateStakeholderDialog}
          handleCloseDialog={toggleCreateStakeholderDialog}
          stakeholdersError={stakeholdersError}
          loading={loadingStakeholders}
          handleSubmit={stakeholder => {
            handleCreateStakeholders(stakeholder);
          }}
        />
        <StakeholderDialog
          title="Editar representante legal"
          open={openEditStakeholderDialog}
          originalStakeholder={selectedStakeholder}
          handleCloseDialog={toggleEditStakeholderDialog}
          stakeholdersError={stakeholdersError}
          loading={loadingStakeholders}
          handleSubmit={stakeholder => {
            dispatch(editStakeholders(stakeholder));
          }}
        />

        <ConfirmDialog
          isOpen={openDeleteStakeholderDialog}
          handleClose={toggleDeleteStakeholderDialog}
          title="Eliminar representante legal"
          message="¿Estás seguro que deseas eliminar al representante legal?"
          buttonLabel="Eliminar"
          buttonOnAccept={() =>
            dispatch(
              deleteStakeholders(selectedStakeholder.id, businessId, userId)
            )
          }
        />

        <Typography variant="body2" className={classes.title}>
          Información Legal
        </Typography>

        <Typography variant="body2" className={classes.subtitle}>
          Edita o completa la información de los representantes legales para
          poder enviar el contrato para la firma del mismo. Esto es necesario
          para poder transferir el dinero de tus operaciones.
        </Typography>

        <CustomPanel
          title="Representantes legales"
          variant="green-title"
          buttons={
            <AssistantTooltip
              type="legal"
              open={showAssistantTooltip}
              element={
                <Grid item xs={6} align="center">
                  <OrangeButton
                    onClick={toggleCreateStakeholderDialog}
                    text={BUTTON_LABEL.AGREGAR}
                    data-qa="button-create-stakeholder-legal"
                  />
                </Grid>
              }
            />
          }
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.gridTable}>
                <Grid item className={classes.header}>
                  <span className={classes.label}>Rut</span>
                </Grid>
                <Grid item className={classes.header}>
                  <span className={classes.label}>Nombre</span>
                </Grid>
                <Grid item className={classes.header}>
                  <span className={classes.label}>Email</span>
                </Grid>
                <Grid item className={classes.header} align="center">
                  <span className={classes.label}>Sociedad conyugal</span>
                </Grid>
                <Grid item className={classes.header} align="right">
                  <span className={classes.label} />
                </Grid>
              </Grid>
            </Grid>

            {stakeholders.map(stakeholder => {
              const { name, email, conjugal, rut, dv, id } = stakeholder;
              const rutLabel =
                !Number.isNaN(rut) && (!!dv || dv === 0) ? `${rut}-${dv}` : '-';
              return (
                <Grid item xs={12} key={id}>
                  <Grid container direction="row" className={classes.gridTable}>
                    <Grid item>
                      <span
                        className={classes.info}
                        data-qa="stakeholder-table-rut-string"
                      >
                        {rutLabel}
                      </span>
                    </Grid>
                    <Grid item>
                      <span
                        className={classes.info}
                        data-qa="stakeholder-table-name-string"
                      >
                        {name}
                      </span>
                    </Grid>
                    <Grid item>
                      {email ? (
                        <span
                          className={classes.info}
                          data-qa="stakeholder-table-email-string"
                        >
                          {email}
                        </span>
                      ) : (
                        <span
                          className={classes.info}
                          data-qa="stakeholder-table-email-string"
                        >
                          Sin información
                        </span>
                      )}
                    </Grid>
                    <Grid item className={classes.conjugal}>
                      {conjugal ? (
                        <CheckCircleIcon
                          fontSize="small"
                          className={classes.conjugalIcon}
                          data-qa="stakeholder-table-conjugal-check"
                        />
                      ) : (
                        <span
                          className={classes.info}
                          data-qa="stakeholder-table-conjugal-string"
                        >
                          -
                        </span>
                      )}
                    </Grid>
                    <Grid item className={classes.actions}>
                      <IconButton
                        size="small"
                        className={classes.editButton}
                        onClick={() => editStakeholder(stakeholder)}
                        data-qa="button-edit-stakeholder"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        className={classes.editButton}
                        onClick={() => deleteStakeholder(stakeholder)}
                        data-qa="button-delete-stakeholder"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </CustomPanel>

        <CustomPanel title="Documentos" variant="green-title">
          <Grid container direction="column" className={classes.bodyDocuments}>
            <Grid item>
              <Grid container direction="row" className={classes.documentItem}>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.document}>
                    Informe legal
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <OrangeButton
                    disabled={!legalReport}
                    loading={downloadingLegalReport}
                    width={159}
                    onClick={() => {
                      dispatch(
                        fetchBusinessLegalFile(businessId, LEGAL_REPORT)
                      );
                    }}
                    text={BUTTON_LABEL.DESCARGAR_PDF}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.documentItem}>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.document}>
                    Contratos
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <OrangeButton
                    disabled={!contract}
                    loading={downloadingContract}
                    width={159}
                    onClick={() => {
                      dispatch(fetchBusinessLegalFile(businessId, CONTRACT));
                    }}
                    text={BUTTON_LABEL.DESCARGAR_PDF}
                  />
                </Grid>
              </Grid>
              <div className={classes.separator} />
              <Grid container direction="row" className={classes.documentItem}>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.document}>
                    Términos y condiciones
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <OrangeButton
                    loading={downloadingTerms}
                    width={159}
                    onClick={() => {
                      dispatch(fetchLegalFile(TERMS));
                    }}
                    text={BUTTON_LABEL.DESCARGAR_PDF}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.documentItem}>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.document}>
                    Políticas de privacidad
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <OrangeButton
                    loading={downloadingPolicy}
                    width={159}
                    onClick={() => {
                      dispatch(fetchLegalFile(POLICY));
                    }}
                    text={BUTTON_LABEL.DESCARGAR_PDF}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomPanel>
      </Box>
    </Fade>
  );
};
export default UserLegal;
