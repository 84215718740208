import {
  FETCH_HOLIDAYS_START,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
} from '../actions/types';

const initialState = {
  fetchingHolidays: false,
  yearHolidays: [],
  holidaysError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HOLIDAYS_START:
      return {
        ...state,
        fetchingHolidays: true,
      };

    case FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        fetchingHolidays: false,
        yearHolidays: payload.holidays,
      };

    case FETCH_HOLIDAYS_FAILURE:
      return {
        ...state,
        fetchingHolidays: false,
        holidaysError: payload.error,
      };

    default:
      return state;
  }
};
