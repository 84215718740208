import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  iconChampagne: {
    margin: 30,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 800,
    color: '#7300FF',
  },
  subtitle: {
    marginTop: 10,
    color: '#7300FF',
    font: '14px/13px Muli',
  },
  actions: {
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 50,
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
});
const TopImageModalDialog = props => {
  const classes = useStyles();
  const {
    isOpen,
    withCloseButton,
    handleClose,
    imageSource,
    imageWidth,
    title,
    subtitle,
    content,
    actions,
  } = props;

  return (
    <Dialog open={isOpen} size="sm" maxSize="sm" close={handleClose}>
      <DialogTitle>
        {withCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Grid container align="center">
          <Grid item xs={12}>
            <img
              src={imageSource}
              width={imageWidth}
              className={classes.iconChampagne}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          </Grid>
          {!!subtitle && (
            <Grid item xs={12}>
              <Typography className={classes.subtitle} variant="body1">
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {!!actions && (
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      )}
    </Dialog>
  );
};

TopImageModalDialog.defaultProps = {
  actions: null,
};

TopImageModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  withCloseButton: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imageSource: PropTypes.object.isRequired,
  imageWidth: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  actions: PropTypes.object,
};

export default TopImageModalDialog;
