import {
  RESET_PASSWORD_EMAIL_REQUEST_BEGIN,
  RESET_PASSWORD_EMAIL_REQUEST_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST_SUCCESS,
  NEW_PASSWORD_SUBMIT_BEGIN,
  NEW_PASSWORD_SUBMIT_FAILURE,
  NEW_PASSWORD_SUBMIT_SUCCESS,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_EMAIL_REQUEST_BEGIN: {
      return {
        ...initialState,
        resetPasswordEmailSubmit: true,
      };
    }
    case RESET_PASSWORD_EMAIL_REQUEST_SUCCESS: {
      return {
        ...initialState,
        resetPasswordEmailSubmit: false,
        resetPasswordEmailSent: true,
      };
    }
    case RESET_PASSWORD_EMAIL_REQUEST_FAILURE: {
      return {
        ...initialState,
        resetPasswordEmailSubmit: false,
        resetPasswordEmailSent: true,
      };
    }
    case NEW_PASSWORD_SUBMIT_BEGIN: {
      return {
        ...initialState,
        newPasswordSubmit: true,
      };
    }
    case NEW_PASSWORD_SUBMIT_SUCCESS: {
      return {
        ...initialState,
        newPasswordSubmit: false,
        newPasswordSuccess: true,
        newPasswordError: false,
      };
    }
    case NEW_PASSWORD_SUBMIT_FAILURE: {
      return {
        ...initialState,
        newPasswordSubmit: false,
        newPasswordError: true,
        newPasswordSuccess: false,
      };
    }
    default:
      return state;
  }
}
