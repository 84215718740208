import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FolderIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g
        id="Grupo_2743"
        data-name="Grupo 2743"
        transform="translate(-126 -215)"
      >
        <rect
          id="Rectángulo_1788"
          data-name="Rectángulo 1788"
          width="24"
          height="24"
          transform="translate(126 215)"
          fill="rgba(255,255,255,0)"
        />
        <g
          id="Grupo_2743-2"
          data-name="Grupo 2743"
          transform="translate(-545.654 -158.212)"
        >
          <g
            id="Trazado_2451"
            data-name="Trazado 2451"
            transform="translate(677.917 376.224)"
          >
            <path
              id="Trazado_2941"
              data-name="Trazado 2941"
              d="M692.633,393.29a.8.8,0,0,1-.739-.5l-3.2-7.9h-10.23a.8.8,0,0,1-.8-.8v-5.908a1.816,1.816,0,0,1,1.565-1.955.923.923,0,0,1,.12-.008h3.766a2.864,2.864,0,0,1,2.25,1.2,4.3,4.3,0,0,1,.74,1.761l6.521.005a.8.8,0,0,1,.795.8v12.51a.8.8,0,0,1-.644.781A.842.842,0,0,1,692.633,393.29Zm-13.368-9.99h9.97a.8.8,0,0,1,.739.5l1.863,4.606v-7.623l-6.454,0a.8.8,0,0,1-.795-.808,2.746,2.746,0,0,0-.51-1.621,1.263,1.263,0,0,0-.982-.531h-3.659a.256.256,0,0,0-.178.276Z"
              transform="translate(-677.66 -376.224)"
            />
          </g>
          <g
            id="Trazado_2452"
            data-name="Trazado 2452"
            transform="translate(673.484 383.301)"
          >
            <path
              id="Trazado_2942"
              data-name="Trazado 2942"
              d="M692.89,392.881h-15.01a.8.8,0,0,1-.731-.482l-3.6-8.4a.8.8,0,0,1,.731-1.109h15.009a.794.794,0,0,1,.731.482l3.6,8.4a.8.8,0,0,1-.731,1.11ZM678.4,391.289h13.277l-2.917-6.806H675.488Z"
              transform="translate(-673.484 -382.891)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FolderIcon;
