import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { loginWithToken, loginError } from '../actions/authentication';

const ColorLinearProgress = withStyles({
  root: { height: 10 },
  colorPrimary: {
    backgroundColor: '#FAB584',
  },
  barColorPrimary: {
    backgroundColor: '#F87C54',
  },
})(LinearProgress);

class Impersonate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      match: { params },
      history,
      loginWithToken,
      loginError,
    } = this.props;

    try {
      loginWithToken(params.token);
      history.push('/');
    } catch (error) {
      loginError(error);
      history.push('/');
    }
  };

  render() {
    return <ColorLinearProgress variant="query" />;
  }
}

Impersonate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loginError: PropTypes.func.isRequired,
  loginWithToken: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => {
  return {
    loginWithToken: token => dispatch(loginWithToken(token)),
    loginError: error => dispatch(loginError(error)),
  };
};

export default connect(null, mapDispatchToProps)(Impersonate);
