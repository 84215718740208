import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PreviredIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="25.9" height="25.9" />
          <path d="M13,1.41A11.55,11.55,0,1,0,24.5,13,11.56,11.56,0,0,0,13,1.41Zm0,20.71a9.17,9.17,0,0,1-.31-18.33H13a9.17,9.17,0,1,1,0,18.33Z" />
          <path d="M14.12,8.15a1.34,1.34,0,1,0-1.33-1.34A1.34,1.34,0,0,0,14.12,8.15Z" />
          <path d="M14.12,17.78a1.34,1.34,0,1,0,1.34,1.34A1.34,1.34,0,0,0,14.12,17.78Z" />
          <path d="M16.92,11.06a1.34,1.34,0,0,0,0-2.67,1.34,1.34,0,1,0,0,2.67Z" />
          <circle cx="19.12" cy="13.04" r="1.33" />
          <path d="M15,11.7A1.34,1.34,0,1,0,16.3,13,1.34,1.34,0,0,0,15,11.7Z" />
          <circle cx="10.92" cy="13.04" r="1.33" />
          <path d="M6.81,11.7A1.34,1.34,0,1,0,8.15,13,1.33,1.33,0,0,0,6.81,11.7Z" />
          <path d="M16.92,14.91a1.34,1.34,0,1,0,1.33,1.33A1.33,1.33,0,0,0,16.92,14.91Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PreviredIcon;
