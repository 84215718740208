export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';

// LOGIN
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_LOGIN_SUBMIT_STATE = 'RESET_LOGIN_SUBMIT_STATE';

// LOGOUT
export const LOGOUT_USER = 'LOGOUT_USER';

// register
export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';
export const REGISTER_INPUT_CHANGE = 'REGISTER_INPUT_CHANGE';
export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_BAD_SII_CREDENTIALS = 'REGISTER_BAD_SII_CREDENTIALS';
export const CHECK_PFX_START = 'CHECK_PFX_START';
export const CHECK_PFX_SUCCESS = 'CHECK_PFX_SUCCESS';
export const CHECK_PFX_FAILURE = 'CHECK_PFX_FAILURE';
export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export const RESET_VERIFY_EMAIL = 'RESET_VERIFY_EMAIL';

// Update user
export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';
export const RESET_USER_PASSWORD_DIALOG = 'RESET_USER_PASSWORD_DIALOG';
export const RESET_SII_DIALOG = 'RESET_SII_DIALOG';

// Update busines
export const UPDATE_BUSINESS_INFO_START = 'UPDATE_BUSINESS_INFO_START';
export const UPDATE_BUSINESS_INFO_SUCCESS = 'UPDATE_BUSINESS_INFO_SUCCESS';
export const UPDATE_BUSINESS_INFO_FAILURE = 'UPDATE_BUSINESS_INFO_FAILURE';

// reset password
export const RESET_PASSWORD_EMAIL_REQUEST_BEGIN =
  'RESET_PASSWORD_EMAIL_REQUEST_BEGIN';
export const RESET_PASSWORD_EMAIL_REQUEST_SUCCESS =
  'RESET_PASSWORD_EMAIL_REQUEST_SUCCESS';
export const RESET_PASSWORD_EMAIL_REQUEST_FAILURE =
  'RESET_PASSWORD_EMAIL_REQUEST_FAILURE';
export const NEW_PASSWORD_SUBMIT_BEGIN = 'NEW_PASSWORD_SUBMIT_BEGIN';
export const NEW_PASSWORD_SUBMIT_SUCCESS = 'NEW_PASSWORD_SUBMIT_SUCCESS';
export const NEW_PASSWORD_SUBMIT_FAILURE = 'NEW_PASSWORD_SUBMIT_FAILURE';

// SII
export const CHECK_SII_SUCCESS = 'CHECK_SII_SUCCESS';
export const CHECK_SII_FAILURE = 'CHECK_SII_FAILURE';
export const CHECK_SII_START = 'CHECK_SII_START';
export const REGISTER_SII_START = 'REGISTER_SII_START';
export const REGISTER_SII_SUCCESS = 'REGISTER_SII_SUCCESS';
export const REGISTER_SII_FAILURE = 'REGISTER_SII_FAILURE';
export const LOAD_SII_DATA_START = 'LOAD_SII_DATA_START';
export const LOAD_SII_DATA_UPDATE = 'LOAD_SII_DATA_UPDATE';
export const LOAD_SII_DATA_SUCCESS = 'LOAD_SII_DATA_SUCCESS';
export const LOAD_SII_DATA_FAILURE = 'LOAD_SII_DATA_FAILURE';
export const FETCH_SIIUSER_TOTALS_DATA_START =
  'FETCH_SIIUSER_TOTALS_DATA_START';
export const FETCH_SIIUSER_TOTALS_DATA_SUCCESS =
  'FETCH_SIIUSER_TOTALS_DATA_SUCCESS';
export const FETCH_SIIUSER_TOTALS_DATA_FAILURE =
  'FETCH_SIIUSER_TOTALS_DATA_FAILURE';
export const FETCH_SIIUSER_SELECTED_DTES_START =
  'FETCH_SIIUSER_SELECTED_DTES_START';
export const FETCH_SIIUSER_SELECTED_DTES_SUCCESS =
  'FETCH_SIIUSER_SELECTED_DTES_SUCCESS';
export const FETCH_SIIUSER_SELECTED_DTES_FAILURE =
  'FETCH_SIIUSER_SELECTED_DTES_FAILURE';
export const HANDLE_FACTURA_SELECTION_CHANGE =
  'HANDLE_FACTURA_SELECTION_CHANGE';
export const HANDLE_FACTURA_SELECTION_CONFIRM =
  'HANDLE_FACTURA_SELECTION_CONFIRM';
export const REGISTER_PFX_START = 'REGISTER_PFX_START';
export const REGISTER_PFX_SUCCESS = 'REGISTER_PFX_SUCCESS';
export const REGISTER_PFX_FAILURE = 'REGISTER_PFX_FAILURE';
export const HANDLE_CLOSE_PFX_DIALOG = 'HANDLE_CLOSE_PFX_DIALOG';
export const FETCH_COMPANY_NAME_BY_RUT_START =
  'FETCH_COMPANY_NAME_BY_RUT_START';
export const FETCH_COMPANY_NAME_BY_RUT_SUCCESS =
  'FETCH_COMPANY_NAME_BY_RUT_SUCCESS';
export const FETCH_COMPANY_NAME_BY_RUT_FAILURE =
  'FETCH_COMPANY_NAME_BY_RUT_FAILURE';

// CART
export const CART_CALCULATE_BEGIN = 'CART_CALCULATE_BEGIN';
export const CART_CALCULATE_SUCCESS = 'CART_CALCULATE_SUCCESS';
export const CART_CALCULATE_ERROR = 'CART_CALCULATE_ERROR';
export const HANDLE_FACTURA_DATE_CHANGE = 'HANDLE_FACTURA_DATE_CHANGE';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SUBMIT_ORDER_BEGIN = 'SUBMIT_ORDER_BEGIN';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_ERROR = 'SUBMIT_ORDER_ERROR';
export const CLEAN_CART = 'CLEAN_CART';

// USER ORDERS
export const FETCH_USER_ORDERS_START = 'FETCH_USER_ORDERS_START';
export const FETCH_USER_ORDERS_SUCCESS = 'FETCH_USER_ORDERS_SUCCESS';
export const FETCH_USER_ORDERS_FAILURE = 'FETCH_USER_ORDERS_FAILURE';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const RESET_SELECTED_ORDER = 'RESET_SELECTED_ORDER';
export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const UPLOAD_BULK_FACTURAS_START = 'UPLOAD_BULK_FACTURAS_START';
export const UPLOAD_BULK_FACTURAS_SUCCESS = 'UPLOAD_BULK_FACTURAS_SUCCESS';
export const UPLOAD_BULK_FACTURAS_FAILURE = 'UPLOAD_BULK_FACTURAS_FAILURE';
export const RESET_BULK_FACTURAS_UPLOAD_SNACKBAR =
  'RESET_BULK_FACTURAS_UPLOAD_SNACKBAR';
export const RESET_BULK_FACTURAS_UPLOAD_DIALOG =
  'RESET_BULK_FACTURAS_UPLOAD_DIALOG';

// STEPS
export const CHANGE_CART_STEP = 'CHANGE_CART_STEP';

// WS
export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';

export const HANDLE_STEP_CHANGE = 'HANDLE_STEP_CHANGE';

//  simulation email
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export const RESET_EMAIL_DIALOG = 'RESET_EMAIL_DIALOG';

// Holidays
export const FETCH_HOLIDAYS_START = 'FETCH_HOLIDAYS_START';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_ERROR';

// Business
export const REGISTER_BUSINESS_SERVICE_START =
  'REGISTER_BUSINESS_SERVICE_START';
export const REGISTER_BUSINESS_SERVICE_SUCCESS =
  'REGISTER_BUSINESS_SERVICE_SUCCESS';
export const REGISTER_BUSINESS_SERVICE_FAILURE =
  'REGISTER_BUSINESS_SERVICE_FAILURE';
export const REGISTER_BANK_ACCOUNT_START = 'REGISTER_BANK_ACCOUNT_START';
export const REGISTER_BANK_ACCOUNT_SUCCESS = 'REGISTER_BANK_ACCOUNT_SUCCESS';
export const REGISTER_BANK_ACCOUNT_FAILURE = 'REGISTER_BANK_ACCOUNT_FAILURE';
export const RESET_BANK_ACCOUNT_DIALOG = 'RESET_BANK_ACCOUNT_DIALOG';
export const RESET_BUSINESS_SERVICE_DIALOG = 'RESET_BUSINESS_SERVICE_DIALOG';
export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';
export const RESET_BANK_ACCOUNT_DELETE_DIALOG =
  'RESET_BANK_ACCOUNT_DELETE_DIALOG';
export const RESET_DELETE_BANK_ACCOUNT_DIALOG =
  'RESET_DELETE_BANK_ACCOUNT_DIALOG';
export const SET_DEFAULT_BANK_ACCOUNT_START = 'SET_DEFAULT_BANK_ACCOUNT_START';
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  'SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  'SET_DEFAULT_BANK_ACCOUNT_FAILURE';
export const RESET_DEFAULT_BANK_ACCOUNT_DIALOG =
  'RESET_DEFAULT_BANK_ACCOUNT_DIALOG';
export const FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE';
export const SELECT_BANK_ACCOUNT = 'SELECT_BANK_ACCOUNT';
export const SETTINGS_RESUME_START = 'SETTINGS_RESUME_START';
export const SETTINGS_RESUME_SUCCESS = 'SETTINGS_RESUME_SUCCESS';
export const SETTINGS_RESUME_FAILURE = 'SETTINGS_RESUME_FAILURE';

// BANKS LIST
export const FETCH_BANKS_START = 'FETCH_BANKS_START';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILURE = 'FETCH_BANKS_FAILURE';

// USER SERVICES
export const FETCH_USER_SERVICES_START = 'FETCH_USER_SERVICES_START';
export const FETCH_USER_SERVICES_SUCCESS = 'FETCH_USER_SERVICES_SUCCESS';
export const FETCH_USER_SERVICES_FAILURE = 'FETCH_USER_SERVICES_FAILURE';
export const REGISTER_USER_SERVICES_START = 'REGISTER_USER_SERVICES_START';
export const REGISTER_USER_SERVICES_SUCCESS = 'REGISTER_USER_SERVICES_SUCCESS';
export const REGISTER_USER_SERVICES_FAILURE = 'REGISTER_USER_SERVICES_FAILURE';
export const RESET_SERVICE_SNACKBAR = 'RESET_SERVICE_SNACKBAR';
export const UPDATE_USER_SERVICES_START = 'UPDATE_USER_SERVICES_START';
export const UPDATE_USER_SERVICES_SUCCESS = 'UPDATE_USER_SERVICES_SUCCESS';
export const UPDATE_USER_SERVICES_FAILURE = 'UPDATE_USER_SERVICES_FAILURE';
//  Facturas
export const SET_FILTERED_ORDER_FACTURAS = 'SET_FILTERED_ORDER_FACTURAS';
export const SET_FACTURAS_AVAILABLE_FILTERS = 'SET_FACTURAS_AVAILABLE_FILTERS';
export const SET_FACTURAS_ACTIVE_FILTERS = 'SET_FACTURAS_ACTIVE_FILTERS';

// Order facturas
export const FETCH_ORDER_FACTURAS_START = 'FETCH_ORDER_FACTURAS_START';
export const FETCH_ORDER_FACTURAS_SUCCESS = 'FETCH_ORDER_FACTURAS_SUCCESS';
export const FETCH_ORDER_FACTURAS_FAILURE = 'FETCH_ORDER_FACTURAS_FAILURE';
export const UPLOAD_FACTURA_PDF_START = 'UPLOAD_FACTURA_PDF_START';
export const UPLOAD_FACTURA_PDF_SUCCESS = 'UPLOAD_FACTURA_PDF_SUCCESS';
export const UPLOAD_FACTURA_PDF_FAILURE = 'UPLOAD_FACTURA_PDF_FAILURE';
export const DELETE_FACTURA_PDF_START = 'DELETE_FACTURA_PDF_START';
export const DELETE_FACTURA_PDF_SUCCESS = 'DELETE_FACTURA_PDF_SUCCESS';
export const DELETE_FACTURA_PDF_FAILURE = 'DELETE_FACTURA_PDF_FAILURE';

// UI states
export const TOGGLE_DRAWER_FINANC = 'TOGGLE_DRAWER_FINANC';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_FIRST_NOTIFICATION = 'REMOVE_FIRST_NOTIFICATION';
export const WAS_IN_FINANCE = 'WAS_IN_FINANCE';

// STAKEHOLDERS
export const FETCH_STAKEHOLDERS_START = 'FETCH_STAKEHOLDERS_START';
export const FETCH_STAKEHOLDERS_SUCCESS = 'FETCH_STAKEHOLDERS_SUCCESS';
export const FETCH_STAKEHOLDERS_FAILURE = 'FETCH_STAKEHOLDERS_FAILURE';
export const EDIT_STAKEHOLDERS_START = 'EDIT_STAKEHOLDERS_START';
export const EDIT_STAKEHOLDERS_SUCCESS = 'EDIT_STAKEHOLDERS_SUCCESS';
export const EDIT_STAKEHOLDERS_FAILURE = 'EDIT_STAKEHOLDERS_FAILURE';
export const CREATE_STAKEHOLDERS_START = 'CREATE_STAKEHOLDERS_START';
export const CREATE_STAKEHOLDERS_SUCCESS = 'CREATE_STAKEHOLDERS_SUCCESS';
export const CREATE_STAKEHOLDERS_FAILURE = 'CREATE_STAKEHOLDERS_FAILURE';
export const DELETE_STAKEHOLDERS_START = 'DELETE_STAKEHOLDERS_START';
export const DELETE_STAKEHOLDERS_SUCCESS = 'DELETE_STAKEHOLDERS_SUCCESS';
export const DELETE_STAKEHOLDERS_FAILURE = 'DELETE_STAKEHOLDERS_FAILURE';
export const RESET_STAKEHOLDERS_ACTIONS = 'RESET_STAKEHOLDERS_ACTIONS';
export const DOWNLOADING_LEGAL_FILE_START = 'DOWNLOADING_LEGAL_FILE_START';
export const DOWNLOADING_LEGAL_FILE_END = 'DOWNLOADING_LEGAL_FILE_END';

// Assistant
export const RESET_TOUR = 'RESET_TOUR';
export const SHOW_ASSISTANT = 'SHOW_ASSISTANT';
export const SET_ASSISTANT_CURRENT_STEP = 'SET_ASSISTANT_CURRENT_STEP';
export const SET_ASSISTANT_ACTIVE_DOM = 'SET_ASSISTANT_ACTIVE_DOM';
export const SET_ASSISTANT_PENDING_TASKS = 'SET_ASSISTANT_PENDING_TASKS';

// Wallet Request
export const CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST';
export const CREATE_WALLET_REQUEST_SUCCESS = 'CREATE_WALLET_REQUEST_SUCCESS';
export const CREATE_WALLET_REQUEST_ERROR = 'CREATE_WALLET_REQUEST_ERROR';
export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_REQUEST_SUCCESS = 'GET_WALLET_REQUEST_SUCCESS';
export const GET_WALLET_REQUEST_ERROR = 'GET_WALLET_REQUEST_ERROR';
