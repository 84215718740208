import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const CustomChip = withStyles({
  root: {
    fontWeight: 'bold',
    fontFamily: 'Muli',
    fontSize: '12px',
    height: '25px',
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
})(Chip);

const useStyles = makeStyles({
  chipInReview: {
    color: '#E29D02',
    background: '#FFEFC9',
    '&:hover, &:focus': {
      background: '#FFEFC9',
    },
  },
  chipApproved: {
    color: '#1A61E7',
    background: '#D3E5FD',
    '&:hover, &:focus': {
      background: '#D3E5FD',
    },
  },
  chipReplaced: {
    width: '100%',
    fontWeight: 'bold',
    color: '#0529F0',
    background: '#D3DDFD',
  },
  chipRejected: {
    color: '#D92F24',
    background: '#F8C7C4',
    '&:hover, &:focus': {
      background: '#F8C7C4',
    },
  },
  chipTransferred: {
    color: '#75B801',
    background: '#E6F8C5',
    '&:hover, &:focus': {
      background: '#E6F8C5',
    },
  },
  chipPaid: {
    color: '#0ABDBD',
    background: '#C5FDFD',
    '&:hover, &:focus': {
      background: '#C5FDFD',
    },
  },
  chipRepacted: {
    width: chipWidth =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    fontWeight: 'bold',
    color: '#901AE7',
    background: '#E0D3FD',
    '&:hover, &:focus': {
      color: '#E0D3FD',
      background: '#901AE7',
    },
  },
});

const StatusChip = ({ status, handleClick = null }) => {
  const classes = useStyles();

  switch (status) {
    case 'IN_REVIEW':
      return (
        <CustomChip
          label={handleClick ? 'En Revisión ×' : '• En Revisión'}
          className={classes.chipInReview}
          onClick={handleClick}
        />
      );
    case 'APPROVED':
      return (
        <CustomChip
          label={handleClick ? 'Aprobado ×' : '• Aprobado'}
          className={classes.chipApproved}
          onClick={handleClick}
        />
      );
    case 'REJECTED':
      return (
        <CustomChip
          label={handleClick ? 'Rechazado ×' : '• Rechazado'}
          className={classes.chipRejected}
          onClick={handleClick}
        />
      );
    case 'TRANSFERRED':
      return (
        <CustomChip
          label={handleClick ? 'Transferido ×' : '• Transferido'}
          className={classes.chipTransferred}
          onClick={handleClick}
        />
      );
    case 'PAID':
      return (
        <CustomChip
          label={handleClick ? 'Pagado ×' : '• Pagado'}
          className={classes.chipPaid}
          onClick={handleClick}
        />
      );
    case 'RENEGOTIATION':
      return (
        <CustomChip
          label={handleClick ? 'Renegociado ×' : '• Renegociado'}
          className={classes.chipRepacted}
          onClick={handleClick}
        />
      );
    case 'REPLACED':
      return (
        <CustomChip
          label={handleClick ? 'Reemplazado ×' : '• Reemplazado'}
          className={classes.chipReplaced}
          onClick={handleClick}
        />
      );
    default:
      return <CustomChip label={status} onClick={handleClick} />;
  }
};

StatusChip.defaultProps = {
  handleClick: null,
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default StatusChip;
