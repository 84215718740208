import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { withSnackbar } from 'notistack';
import { logoutUser } from '../../actions/authentication';
import setAuthToken from '../../helpers/setAuthToken';
import configureStore from '../../modules/store';

const { store } = configureStore();

export default function (ComposedComponent) {
  class Authentication extends Component {
    constructor(props) {
      super(props);
      this.state = {
        authenticated: props.authenticated,
        history: props.history,
      };
      if (props.token) setAuthToken(props.token);
    }

    checkJwtTokenExp() {
      // not logged yet
      if (!localStorage.jwtToken) return true;
      const decoded = jwtDecode(localStorage.jwtToken);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        this.props.enqueueSnackbar('Tu sesión expiró', { variant: 'warning' });
        store.dispatch(logoutUser(this.state.history));
      }
      return true;
    }

    componentWillMount() {
      this.checkJwtTokenExp();
      if (!this.props.authenticated) {
        this.props.history.push('/start');
      }
    }

    componentWillUpdate(nextProps) {
      this.checkJwtTokenExp();
      if (!nextProps.authenticated) {
        this.props.history.push('/start');
      }
    }

    PropTypes = {
      router: PropTypes.object,
    };

    render() {
      return this.state.authenticated ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ComposedComponent {...this.props} />
      ) : (
        <Redirect to="/start" />
      );
    }
  }
  function mapStateToProps(state) {
    return {
      token: state.auth.token,
      authenticated: state.auth.isAuthenticated,
    };
  }
  return withSnackbar(connect(mapStateToProps)(Authentication));
}
