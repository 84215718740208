import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Link,
  Tooltip,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import PfxUploadDialog from './elements/PfxUploadDialog';
import SiiDialog from './SiiDialog';
import { checkPfxCertificate } from '../actions/sii';
import OrangeButton from './elements/OrangeButton';
import SuccessChip from './elements/SuccessChip';
import AssistantTooltip from './elements/AssistantTooltip';

const useStyles = makeStyles({
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
  },
  paddedContainer: {
    padding: 20,
  },
  cardTitle: {
    font: 'normal 16px Muli',
    color: '#75B801',
  },
  cardSubtitle: {
    color: '#A8A4B3',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#A8A4B3',
    font: 'normal 14px Muli',
  },
  tooltip: {
    color: '#F87348',
  },
  paper: {
    border: '1px solid #E3E1E8',
    borderRadius: '17px',
  },
});

const digitalCertificateTooltip =
  'El Certificado Digital es un archivo electrónico autenticado por el SII que te permite firmar Documentos Tributarios electrónicos (DTE), para así poder realizar la Cesión de Facturas.';

const siiPasswordTooltip =
  'La Clave de Consulta del SII te perrmite acceder a información tributaria, operar con Facturars Electrónicas, y consultar sobre tu situación tributaria, entre otras cosas.';

const UserSiiCredentials = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const pfx = useSelector(state => state.sii.pfxCertificate);
  const siiEnabled = useSelector(state => state.sii.siiEnabled);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const [showPfxDialog, setShowPfxDialog] = useState(false);
  const [showSiiDialog, setShowSiiDialog] = useState(false);
  const [showAssistantTooltip, setShowAssistantTooltip] =
    useState(showAssistantStepper);

  useEffect(() => {
    if (auth) {
      dispatch(checkPfxCertificate(auth?.user?.Businesses?.[0]?.id));
    }
  }, [auth, dispatch]);

  const handleShowPfxDialog = () => {
    dispatch({ type: 'HANDLE_CLOSE_PFX_DIALOG' });
    setShowPfxDialog(true);
    setShowAssistantTooltip(false);
  };

  const handleClosePfxDialog = () => {
    dispatch({ type: 'HANDLE_CLOSE_PFX_DIALOG' });
    setShowPfxDialog(false);
  };

  const handleShowSiiDialog = () => {
    dispatch({ type: 'RESET_SII_DIALOG' });
    setShowSiiDialog(true);
  };

  const handleCloseSiiDialog = () => {
    dispatch({ type: 'RESET_SII_DIALOG' });
    setShowSiiDialog(false);
  };

  return (
    <Fade in timeout={400}>
      <div>
        <Grid container direction="column" spacing={4}>
          {auth?.user && (
            <PfxUploadDialog
              open={showPfxDialog}
              handleCloseDialog={handleClosePfxDialog}
            />
          )}
          {auth && !siiEnabled && (
            <SiiDialog
              open={showSiiDialog}
              handleCloseSiiDialog={handleCloseSiiDialog}
              handleSkipSiiDialog={handleCloseSiiDialog}
            />
          )}
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Certificado digital{' '}
              <Tooltip
                title={digitalCertificateTooltip}
                placement="right"
                className={classes.tooltip}
              >
                <HelpIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subtitle}>
              Cede automáticamente tus facturas para comenzar tu financiamiento.
              Para esto necesitamos que subas tu certificado digital (.PFX) en
              esta sección, el mismo será tratado de forma confidencial.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.paddedContainer}
              >
                <Grid item>
                  <Typography variant="body2" className={classes.cardTitle}>
                    Certificado digital (.PFX)
                  </Typography>
                </Grid>
                <AssistantTooltip
                  type="pfx"
                  open={showAssistantTooltip}
                  element={
                    <Grid item>
                      {pfx ? (
                        <SuccessChip label="Certificado subido" />
                      ) : (
                        <OrangeButton
                          onClick={handleShowPfxDialog}
                          text="Subir certificado digital"
                        />
                      )}
                    </Grid>
                  }
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Link
              className={classes.link}
              target="_blank"
              href="http://soporte.xepelin.com/es/articles/3719855-como-ceder-una-factura-desde-el-servicio-de-impuestos-internos-sii"
            >
              Quiero ceder por mi cuenta
            </Link>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Clave consulta{' '}
              <Tooltip
                title={siiPasswordTooltip}
                placement="right"
                className={classes.tooltip}
              >
                <HelpIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subtitle}>
              Si leemos la información de la empresa directamente del SII
              podremos darte la mejor alternativa de financiamiento y
              mostrártela en tiempo real en tu dashboard.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.paddedContainer}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.cardTitle}>
                        Información clave consulta
                      </Typography>
                    </Grid>
                    <Grid item>
                      {siiEnabled ? (
                        <SuccessChip label="Clave ingresada" />
                      ) : (
                        <OrangeButton
                          onClick={handleShowSiiDialog}
                          text="Ingresar clave consulta"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

UserSiiCredentials.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.shape({}).isRequired,
    subtitle: PropTypes.shape({}).isRequired,
    paddedContainer: PropTypes.shape({}).isRequired,
    cardTitle: PropTypes.shape({}).isRequired,
    cardSubtitle: PropTypes.shape({}).isRequired,
    link: PropTypes.shape({}).isRequired,
    tooltip: PropTypes.shape({}).isRequired,
    paper: PropTypes.shape({}).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      Businesses: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default UserSiiCredentials;
