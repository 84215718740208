import { FETCH_HOLIDAYS_START, FETCH_HOLIDAYS_SUCCESS } from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchHolidays = () => dispatch => {
  dispatch({ type: FETCH_HOLIDAYS_START });
  const resData = {
    holidays: [
      { name: 'Encuentro de Dos Mundos', date: '2021-10-11' },
      {
        name: 'Día Nacional de las Iglesias Evangélicas y Protestantes',
        date: '2021-10-31',
      },
      { name: 'Día de Todos los Santos', date: '2021-11-01' },
      {
        name: 'Elecciones Presidenciales y Parlamentarias',
        date: '2021-11-21',
      },
      { name: 'Día de la Inmaculada Concepción', date: '2021-12-08' },
      {
        name: 'Segunda Vuelta Elecciones Presidenciales',
        date: '2021-12-19',
      },
      { name: 'Navidad', date: '2021-12-25' },
      { name: 'Año Nuevo', date: '2022-01-01' },
      { name: 'Viernes Santo', date: '2022-04-15' },
      { name: 'Sábado Santo', date: '2022-04-16' },
      { name: 'Día del Trabajo', date: '2022-05-01' },
      { name: 'Día de las Glorias Navales', date: '2022-05-21' },
      { name: 'Día Nacional de los Pueblos Indígenas', date: '2022-06-20' },
      { name: 'San Pedro y San Pablo', date: '2022-06-27' },
      { name: 'Día de la Virgen del Carmen', date: '2022-07-16' },
      { name: 'La Asunción de la Virgen', date: '2022-08-15' },
      { name: '1ª Junta Nacional de Gobierno', date: '2022-09-18' },
      { name: 'Día de las Glorias del Ejército', date: '2022-09-19' },
      { name: 'Encuentro de Dos Mundos', date: '2022-10-10' },
      {
        name: 'Día Nacional de las Iglesias Evangélicas y Protestantes',
        date: '2022-10-31',
      },
      { name: 'Día de Todos los Santos', date: '2022-11-01' },
      { name: 'Día de la Inmaculada Concepción', date: '2022-12-08' },
      { name: 'Navidad', date: '2022-12-25' },
    ],
  };
  dispatch({ type: FETCH_HOLIDAYS_SUCCESS, payload: resData });
};
