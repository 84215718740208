import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Drawer, Grid, Typography, IconButton } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import NotificationTaskDrawer from './elements/NotificationTaskDrawer';
import { hasPreviredService } from '../helpers/services';

const useStyles = makeStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      background: '#f6f5f7',
    },
  },
  gridRoot: {
    margin: '25px 27px',
    width: 456,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 700,
    color: '#575360',
    marginBottom: 19,
  },
  linePreCircle: {
    width: 2,
    background: '#CECAD9',
    position: 'relative',
    left: '50%',
    top: '-8px',
    height: 'calc(50%)',
  },
  circle: {
    height: 13,
    width: 13,
    background: '#F87348',
    borderRadius: 50,
    position: 'relative',
    left: '50%',
    top: '-8px',
  },
  linePostCircle: {
    width: 2,
    position: 'relative',
    left: '50%',
    background: '#CECAD9',
    height: 'calc(50% + 2.5px)',
    top: '-8px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 12,
    color: '#7300FF',
  },
});

const TasksDrawer = props => {
  const classes = useStyles();
  const { open, toggleDrawer, user } = props;
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const stakeholders = useSelector(state => state.stakeholders.stakeholders);
  const pfx = useSelector(state => state.sii.pfxCertificate);
  const settingsResume = useSelector(state => state.business.settingsResume);

  const hasPfx = !!pfx;
  const hasStakeHolders = !!stakeholders.length;

  const { Businesses = [] } = user;
  const [business = {}] = Businesses;
  const {
    BankAccounts = [],
    contract = false,
    marketInvoicer = false,
  } = business;

  const { incompleteOrders = [] } = settingsResume || {};

  const hasBankAccounts = !!BankAccounts.length;

  const hasPrevired = hasPreviredService(auth);

  const redirectTo = path => history.push(path);
  const countPendingTasks = () => {
    let count = 0;
    if (!hasBankAccounts) count += 1;
    if (!hasStakeHolders) count += 1;
    if (hasStakeHolders && !contract) count += 1;
    if (!marketInvoicer && !hasPfx) count += 1;
    if (!hasPrevired) count += 1;
    count += incompleteOrders.length;
    return count;
  };

  const getPoint = () => {
    return (
      <div style={{ height: '100%' }}>
        <div className={`${classes.linePreCircle} ${classes.notVisible}`} />
        <div className={classes.circle} />
        <div className={classes.linePostCircle} />
      </div>
    );
  };

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
          className={classes.drawer}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={toggleDrawer}
          >
            <CancelRoundedIcon />
          </IconButton>
          <Grid
            container
            direction="column"
            className={classes.gridRoot}
            spacing={2}
          >
            <Grid item>
              <Typography className={classes.title}>
                Tareas Pendientes ({countPendingTasks()})
              </Typography>
            </Grid>
            {!hasStakeHolders && (
              <Grid item>
                <Grid container direction="row" align="center">
                  <Grid item xs={11}>
                    <NotificationTaskDrawer
                      title="Para operar con financiamiento directo 1/2"
                      detail="Agrega los representantes legales aquí."
                      hasAction
                      onAction={() => redirectTo('/user/profile/legal')}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {getPoint()}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {hasStakeHolders && !contract && (
              <Grid item>
                <Grid container direction="row" align="center">
                  <Grid item xs={11}>
                    <NotificationTaskDrawer
                      title="Para operar con financiamiento directo 2/2"
                      detail="Los representantes legales deben firmar el contrato enviado a su correo electrónico."
                      hasAction={false}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {getPoint()}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!marketInvoicer && !hasPfx && (
              <Grid item>
                <Grid container direction="row" align="center">
                  <Grid item xs={11}>
                    <NotificationTaskDrawer
                      title="Para ceder automáticamente tus facturas"
                      detail="Sube tu certificado digital .pfx"
                      hasAction
                      onAction={() => redirectTo('/user/profile/sii')}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {getPoint()}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!hasBankAccounts && (
              <Grid item>
                <Grid container direction="row" align="center">
                  <Grid item xs={11}>
                    <NotificationTaskDrawer
                      title="Para transferirte el dinero de tu operación"
                      detail="Agrega tu cuenta bancaria aquí."
                      hasAction
                      onAction={() => redirectTo('/user/profile/bank-accounts')}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {getPoint()}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {incompleteOrders.map(order => {
              const { id } = order;
              return (
                <Grid item key={id}>
                  <Grid container direction="row" align="center">
                    <Grid item xs={11}>
                      <NotificationTaskDrawer
                        title={`Para transferirte tu operación nº ${id}`}
                        detail="Sube los pdfs cedibles de las facturas de tu operación de financiamiento directo."
                        hasAction
                        onAction={() => redirectTo(`/orders/${id}`)}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {getPoint()}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            {!hasPrevired && (
              <Grid item>
                <Grid container direction="row" align="center">
                  <Grid item xs={11}>
                    <NotificationTaskDrawer
                      title="Para completar tu perfil"
                      detail="Ingresa tus credenciales Previred"
                      hasAction
                      onAction={() => redirectTo('/user/profile/previred')}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {getPoint()}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

TasksDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  user: PropTypes.shape({
    Businesses: PropTypes.arrayOf(Object).isRequired,
  }).isRequired,
};

export default TasksDrawer;
