import {
  FETCH_USER_SERVICES_START,
  FETCH_USER_SERVICES_SUCCESS,
  FETCH_USER_SERVICES_FAILURE,
  REGISTER_USER_SERVICES_START,
  REGISTER_USER_SERVICES_SUCCESS,
  REGISTER_USER_SERVICES_FAILURE,
  UPDATE_USER_SERVICES_START,
  UPDATE_USER_SERVICES_SUCCESS,
  UPDATE_USER_SERVICES_FAILURE,
  RESET_SERVICE_SNACKBAR,
} from '../actions/types';

const initialState = {
  loadingUserServices: false,
  registeringUserServices: false,
  updatingUserServices: false,
  userServices: [],
  userServiceRegistered: false,
  userServicesError: '',
  lastServiceName: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_USER_SERVICES_START:
      return {
        ...state,
        loadingUserServices: true,
        userServicesError: false,
      };

    case FETCH_USER_SERVICES_SUCCESS:
      return {
        ...state,
        loadingUserServices: false,
        userServices: payload.userServices,
      };

    case FETCH_USER_SERVICES_FAILURE:
      return {
        ...state,
        loadingUserServices: false,
        userServicesError: true,
      };

    case REGISTER_USER_SERVICES_START:
      return {
        ...state,
        userServiceRegistered: false,
        registeringUserServices: true,
        userServicesError: false,
        lastServiceName: '',
      };

    case REGISTER_USER_SERVICES_SUCCESS:
      return {
        ...state,
        userServiceRegistered: true,
        registeringUserServices: false,
        userServices: payload.userServices,
        lastServiceName: payload.lastUpdatedName,
      };

    case RESET_SERVICE_SNACKBAR:
      return {
        ...state,
        userServiceRegistered: false,
        userServiceUpdated: false,
      };

    case REGISTER_USER_SERVICES_FAILURE:
      return {
        ...state,
        registeringUserServices: false,
        userServicesError: true,
      };

    case UPDATE_USER_SERVICES_START:
      return {
        ...state,
        userServiceUpdated: false,
        updatingUserServices: true,
        userServicesError: false,
        lastServiceName: '',
      };

    case UPDATE_USER_SERVICES_SUCCESS:
      return {
        ...state,
        updatingUserServices: false,
        userServiceUpdated: true,
        userServices: payload.userServices,
        lastServiceName: payload.lastUpdatedName,
      };

    case UPDATE_USER_SERVICES_FAILURE:
      return {
        ...state,
        updatingUserServices: false,
        userServicesError: true,
      };
    default:
      return state;
  }
}
