import axios from 'axios';
import {
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_REQUEST_SUCCESS,
  CREATE_WALLET_REQUEST_ERROR,
  GET_WALLET_REQUEST,
  GET_WALLET_REQUEST_SUCCESS,
  GET_WALLET_REQUEST_ERROR,
} from './types';
import { addNotification } from './notificationsAction';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../helpers/Constants';

export const createWalletRequest = (rut, activity) => dispatch => {
  dispatch({
    type: CREATE_WALLET_REQUEST,
  });
  axios
    .post('/api/wallet/', { rut, activity })
    .then(res => {
      const { data: resData } = res;
      dispatch({
        type: CREATE_WALLET_REQUEST_SUCCESS,
        payload: resData,
      });
      dispatch(addNotification('Solicitud creada', SNACKBAR_SUCCESS));
    })
    .catch(err => {
      dispatch({
        type: CREATE_WALLET_REQUEST_ERROR,
        payload: err.response ? err.response.data : 'empty error',
      });
      dispatch(addNotification('Error al crear solicitud', SNACKBAR_ERROR));
    });
};

export const fetchWalletRequest =
  (rut, status = null) =>
  dispatch => {
    dispatch({
      type: GET_WALLET_REQUEST,
    });

    let url = `api/wallet/${rut}`;
    if (status) {
      url = `${url}?status=${status}`;
    }

    axios
      .get(url)
      .then(res => {
        const { data: resData } = res;
        dispatch({
          type: GET_WALLET_REQUEST_SUCCESS,
          payload: { walletRequests: resData.walletRequests },
        });
        return resData;
      })
      .catch(err => {
        dispatch({
          type: GET_WALLET_REQUEST_ERROR,
          payload: err.response ? err.response.data : 'empty error',
        });
      });
  };
