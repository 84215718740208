import React from 'react';
import Fab from '@material-ui/core/Fab';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const SideButton = withStyles({
  root: {
    margin: '10px',
    backgroundColor: 'transparent',
    marginRight: '-40px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})(Fab);

const useStyles = makeStyles({
  buttonsActive: {
    margin: '10px',
    backgroundColor: 'white',
    color: '#7300FF',
    fontSize: '22px',
    marginRight: '-40px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  buttonsInactive: {
    margin: '10px',
    fontSize: '20px',
    backgroundColor: 'transparent',
    color: 'white',
    marginRight: '-40px',
    boxShadow: 'none',
  },
});

const StartSideBarNavigationButton = props => {
  const classes = useStyles();
  return (
    <SideButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disableRipple
      variant="extended"
      aria-label="delete"
      className={props.active ? classes.buttonsActive : classes.buttonsInactive}
    >
      {props.children}
    </SideButton>
  );
};

export default StartSideBarNavigationButton;
