import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TasksCenterIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g
        id="surface_card"
        data-name="surface/card"
        transform="translate(7.048 4.397)"
      >
        <rect
          id="fondo_card"
          data-name="fondo card"
          width="11"
          height="15"
          rx="5.5"
          transform="translate(-0.048 -0.397)"
          fill="#fff"
        />
      </g>
      <path
        id="Trazado_6576"
        data-name="Trazado 6576"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Trazado_6577"
        data-name="Trazado 6577"
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V15h2Zm0-4H11V7h2Z"
        fill="#ff171d"
      />
    </SvgIcon>
  );
};

export default TasksCenterIcon;
