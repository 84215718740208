import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrendingUpIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="24" height="24" />
          <path
            id="Trazado_2896"
            data-name="Trazado 2896"
            d="M16.46,7.25l1.38,1.38-4.68,4.69L10,10.16a1,1,0,0,0-1.35,0h0L2.88,15.93a1,1,0,0,0,1.36,1.35L9.32,12.2l3.16,3.15a1,1,0,0,0,1.35,0h0L19.2,10l1.38,1.38a.49.49,0,0,0,.68,0A.47.47,0,0,0,21.4,11V6.91A.47.47,0,0,0,21,6.43H16.81a.49.49,0,0,0-.48.49.47.47,0,0,0,.14.33Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default TrendingUpIcon;
