import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TgrIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect style={{ fill: 'none' }} width="25.9" height="25.9" />
          <path d="M18.89,16.85l-.05,0-4,2.92a.16.16,0,0,0,0,.23l0,0h0l6.61,4.63a.17.17,0,0,0,.24-.06.15.15,0,0,0,0-.13L19.11,17A.18.18,0,0,0,18.89,16.85Z" />
          <path d="M25.18,10.06h-9L13.3,1.26a.17.17,0,0,0-.21-.13.19.19,0,0,0-.13.13L10.61,8.58l-.47,1.48H.72a.14.14,0,0,0-.14.07.17.17,0,0,0,0,.24L8.06,15.6h0a.18.18,0,0,1,.06.2L5.22,24.55a.16.16,0,0,0,0,.16.18.18,0,0,0,.25,0L25.28,10.37a.17.17,0,0,0-.1-.31Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default TgrIcon;
