import {
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
} from '../actions/types';

const initialState = {
  loadingBanks: false,
  banks: [],
  banksError: '',
  accountType: ['Cuenta corriente', 'Cuenta Vista', 'Ahorro'],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_BANKS_START:
      return {
        ...state,
        loadingBanks: true,
        banksError: false,
      };

    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        loadingBanks: false,
        banks: payload.banks,
      };

    case FETCH_BANKS_FAILURE:
      return {
        ...state,
        loadingBanks: true,
        banksError: false,
      };
    default:
      return state;
  }
}
