import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import assistantDialogIcon from '../../assets/assistant.svg';
import assistantProfileFinished from '../../assets/assistant-profile-finished.svg';
import celebration from '../../assets/xepelin-celebration.gif';
import BaseDialog from './BaseDialog';
import GradientButton from '../form/GradientButton';
import { showAssistant } from '../../actions/assistantAction';
import { ASSISTANT_TYPES } from '../../helpers/Constants';

const useStyles = makeStyles({
  actions: {
    marginBottom: 20,
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  textBody: {
    minHeight: 206,
  },
  imageAssistant: {
    marginLeft: -25,
    marginTop: -10,
  },
  gifAssistant: {
    marginLeft: -80,
    marginTop: -10,
    marginBottom: -30,
  },
  profileFinished: {
    marginTop: -80,
    marginLeft: -85,
    marginBottom: -80,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 700,
    color: '#7300FF',
    marginTop: 25,
    marginBottom: 25,
  },
  body1: {
    font: '16px/19px Muli',
    fontWeight: 700,
    color: '#A2A2A2',
  },
  body2: {
    textAlign: 'center',
    font: 'normal normal medium 16px/19px Muli',
    letterSpacing: '0px',
    color: '#A2A2A2',
  },
  exit: {
    textAlign: 'right',
    font: 'normal normal bold 13px/19px Muli',
    letterSpacing: '0px',
    color: '#A2A2A2',
    opacity: 1,
    marginTop: '40px',
  },
  link: {
    color: '#A2A2A2',
  },
});

const AssistantDialog = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { open, step = 0, startAssistant, handleClose } = props;

  const config = [
    {
      title: '¿Es tu primera operación?',
      icon: (
        <img
          src={assistantDialogIcon}
          width={430}
          className={classes.imageAssistant}
          alt="Logo"
        />
      ),
      content: (
        <Grid>
          <Typography className={classes.body1} display="inline">
            ¡Tranquilo!{' '}
          </Typography>
          <Typography className={classes.body2} display="inline">
            Te asistiremos en el proceso.
            <br />
            Sigue las instrucciones y podrás
            <br />
            autogestionarte.
          </Typography>
        </Grid>
      ),
      buttonLabel: 'Iniciar asistencia',
    },
    {
      title: '¡Felicidades! Tu solicitud ha sido enviada',
      icon: (
        <img
          src={celebration}
          width={550}
          className={classes.gifAssistant}
          alt="Logo"
        />
      ),
      content: (
        <Grid>
          <Typography className={classes.body2}>
            Ahora sólo nos falta
          </Typography>
          <Typography className={classes.body1}>
            completar tu perfil.
          </Typography>
        </Grid>
      ),
      buttonLabel: 'Ir a perfil',
    },
    {
      title: '¡Felicidades! Estas listo para hacer tus propias operaciones',
      icon: (
        <img
          src={assistantProfileFinished}
          width={550}
          className={classes.profileFinished}
          alt="Logo"
        />
      ),
      content: (
        <Grid>
          <Typography className={classes.body1}>
            Ahora puedes revisar el estado de
          </Typography>
          <Typography className={classes.body1}>
            tu operación recién realizada
          </Typography>
        </Grid>
      ),
      buttonLabel: 'Operaciones',
    },
  ];

  const hadleClick = () => {
    if (step === 0) {
      startAssistant();
    }
    if (step === 1) {
      history.push('/user/profile/sii');
      dispatch(showAssistant(true, ASSISTANT_TYPES.PROFILE));
    }
    if (step === 2) {
      history.push('/orders');
    }
  };

  const { title, content, buttonLabel, icon } = config[step] || {};

  return (
    <BaseDialog isOpen={open} handleClose={handleClose} withCloseButton>
      <Grid container direction="column" alignItems="center" align="center">
        <Grid item xs={12}>
          {icon}
        </Grid>
        <Grid item xs={12} className={classes.textBody}>
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
          {content}
        </Grid>
        <Grid item xs={9} className={classes.actions}>
          <GradientButton onClick={hadleClick}>{buttonLabel}</GradientButton>
        </Grid>
        {step !== 2 && (
          <Grid item xs={12}>
            <Link onClick={handleClose} className={classes.link}>
              <Typography className={classes.exit}>
                No, quiero ir solo
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  );
};

AssistantDialog.defaultProps = {
  startAssistant: null,
};

AssistantDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  startAssistant: PropTypes.func,
  step: PropTypes.number.isRequired,
};

export default AssistantDialog;
