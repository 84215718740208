import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Tooltip, Fade } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import ServicePasswordDialog from './elements/ServicePasswordDialog';
import PreviredLogo from '../assets/logo-previred.svg';
import { registerBusinessService } from '../actions/businessAction';
import OrangeButton from './elements/OrangeButton';
import SuccessChip from './elements/SuccessChip';
import { PREVIRED_LABEL, ASSISTANT_STEPS } from '../helpers/Constants';
import { hasPreviredService } from '../helpers/services';
import AssistantTooltip from './elements/AssistantTooltip';
import { setActiveStep } from '../actions/assistantAction';

const useStyles = makeStyles({
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
  },
  paddedContainer: {
    padding: 20,
  },
  cardTitle: {
    font: 'normal 16px Muli',
    color: '#75B801',
  },
  tooltip: {
    color: '#F87348',
  },
  chipSuccess: {
    width: '100%',
    fontWeight: 'bold',
    color: 'white',
    background: '#75B801',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  checkIcon: {
    color: 'white',
  },
  paper: {
    border: '1px solid #E3E1E8',
    borderRadius: '17px',
  },
});

const previredTooltip =
  'Es el servicio que administra las cotizaciones previsionales y permite a empleadores y trabajadores independientes, pagar sus cotizaciones y llevar un registro de estas.';

const UserPrevired = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const [showPreviredDialog, setShowPreviredDialog] = useState(false);
  const [showAssistantTooltip, setShowAssistantTooltip] =
    useState(showAssistantStepper);
  const { STEP_PROFILE_FINISH } = ASSISTANT_STEPS;

  const handleSubmitService = serviceData => {
    const { user } = auth;
    const { id: userId, Businesses = [] } = user;
    const [{ id: businessId }] = Businesses;
    const serviceDataObj = { ...serviceData };
    serviceDataObj.userId = userId;
    serviceDataObj.businessId = businessId;
    dispatch(registerBusinessService(serviceDataObj));
  };

  const handleSetShowPreviredDialog = () => {
    setShowPreviredDialog(true);
    setShowAssistantTooltip(false);
  };

  const handleClosePreviredDialog = () => {
    setShowPreviredDialog(false);
    if (showAssistantStepper) dispatch(setActiveStep(STEP_PROFILE_FINISH));
  };

  const service = { serviceId: 2, name: PREVIRED_LABEL };
  const hasPrevired = hasPreviredService(auth);

  return (
    <Fade in timeout={400}>
      <div>
        <Grid container direction="column" spacing={4}>
          <ServicePasswordDialog
            service={service}
            logo={<img src={PreviredLogo} height="50px" alt="Previred" />}
            open={showPreviredDialog}
            handleCloseDialog={handleClosePreviredDialog}
            handleSubmit={serviceData => handleSubmitService(serviceData)}
          />
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Previred{' '}
              <Tooltip
                title={previredTooltip}
                placement="right"
                className={classes.tooltip}
              >
                <HelpIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subtitle}>
              Para que podamos procesar tus solicitudes de manera automática y
              sin contratiempos ingresa tu información de Previred.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.paddedContainer}
              >
                <Grid item>
                  <Typography variant="body2" className={classes.cardTitle}>
                    Información Previred
                  </Typography>
                </Grid>
                <Grid item>
                  {auth && hasPrevired ? (
                    <SuccessChip label="Certificado subido" />
                  ) : (
                    <AssistantTooltip
                      type="previred"
                      open={showAssistantTooltip}
                      element={
                        <Grid item xs={12} align="center">
                          <OrangeButton
                            onClick={handleSetShowPreviredDialog}
                            text="Ingresar contraseña"
                          />
                        </Grid>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

UserPrevired.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.shape({}),
    tooltip: PropTypes.shape({}),
    subtitle: PropTypes.shape({}),
    paper: PropTypes.shape({}),
    paddedContainer: PropTypes.shape({}),
    cardTitle: PropTypes.shape({}),
  }).isRequired,
};

export default UserPrevired;
