import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import TimesIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';

const styles = props => {
  const classes = makeStyles({
    container: {
      textAlign: props.align || 'left',
    },
    labelRoot: {
      minHeight: 32,
    },
    editButton: {
      color: '#6c6776b5',
      '& svg': {
        fontSize: 16,
      },
      marginLeft: 4,
      padding: 8,
    },
    label: {
      color: props?.labelStyles?.color || '#6C6776',
      fontSize: props?.labelStyles?.fontSize || '12px',
      fontWeight: props?.labelStyles?.fontWeight || 'inherit',
      lineHeight: props?.labelStyles?.lineHeight || 1,
      display: 'inline-block',
      padding: '10px 0',
    },
    data: {
      textTransform: props.dataTextTransform,
      color: props?.dataStyles?.color || 'inherit',
      fontSize: props?.dataStyles?.fontSize || '14px',
      fontWeight: props?.dataStyles?.fontWeight || '600',
      lineHeight: props?.dataStyles?.lineHeight || 1,
      padding: '7px 0',
    },
    greenIcon: {
      color: '#80C100',
    },
    redIcon: {
      color: 'red',
    },
    inputRoot: {
      '& input': {
        font: 'normal 13px Muli',
        textTransform: props.dataTextTransform,
      },
    },
    gridInput: {
      display: 'grid',
      gridTemplateColumns: 'auto 60px',
    },
  });
  return classes(props);
};

const InfoField = props => {
  const classes = styles(props);
  const [editing, setEditing] = useState(false);
  const {
    labelClasses,
    label,
    dataClasses,
    data,
    editable,
    onAccept,
    direction,
    inputErrorMessages,
    inputValidators,
  } = props;
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const submitHandler = () => {
    onAccept(value);
    setEditing(false);
    setValue(data);
  };

  const cancelHandler = () => {
    setEditing(false);
    setValue(data);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Grid container direction={direction} className={classes.container}>
      <Grid item className={classes.labelRoot}>
        <Typography
          variant="body1"
          classes={{
            root: classes.label,
          }}
          className={`${labelClasses}`}
        >
          {label}
        </Typography>
        {editable && (
          <IconButton
            aria-label="editar"
            className={classes.editButton}
            onClick={() => setEditing(true)}
            data-qa="button-edit-info-field"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Grid>

      <Grid item>
        {editing ? (
          <ValidatorForm onSubmit={submitHandler}>
            <Grid container direction="row" className={classes.gridInput}>
              <Grid item>
                <TextValidator
                  name="data"
                  value={value}
                  onChange={handleChange}
                  style={{ marginTop: 0 }}
                  classes={{
                    root: classes.inputRoot,
                  }}
                  validators={inputValidators}
                  errorMessages={inputErrorMessages}
                  data-qa="input-text-validator"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <IconButton
                  type="submit"
                  size="small"
                  className={classes.greenIcon}
                  data-qa="button-submit-info-field"
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={cancelHandler}
                  className={classes.redIcon}
                  data-qa="button-cancel-info-field"
                >
                  <TimesIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        ) : (
          <Typography
            variant="body1"
            classes={{
              root: classes.data,
            }}
            className={`${dataClasses}`}
          >
            {data}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

InfoField.defaultProps = {
  editable: false,
  direction: 'column',
  labelClasses: null,
  labelStyles: null,
  dataClasses: null,
  dataStyles: null,
  onAccept: null,
  inputErrorMessages: [],
  inputValidators: [],
};

InfoField.propTypes = {
  label: PropTypes.string.isRequired,
  labelClasses: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  labelStyles: PropTypes.object,
  data: PropTypes.object.isRequired,
  dataClasses: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  dataStyles: PropTypes.object,
  editable: PropTypes.bool,
  direction: PropTypes.oneOf(['row', 'column']),
  onAccept: PropTypes.func,
  inputErrorMessages: PropTypes.arrayOf(PropTypes.string),
  inputValidators: PropTypes.arrayOf(PropTypes.string),
};

export default InfoField;
