import {
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_SUCCESS,
  FETCH_STAKEHOLDERS_FAILURE,
  CREATE_STAKEHOLDERS_START,
  CREATE_STAKEHOLDERS_SUCCESS,
  CREATE_STAKEHOLDERS_FAILURE,
  EDIT_STAKEHOLDERS_START,
  EDIT_STAKEHOLDERS_FAILURE,
  EDIT_STAKEHOLDERS_SUCCESS,
  DELETE_STAKEHOLDERS_START,
  DELETE_STAKEHOLDERS_SUCCESS,
  DELETE_STAKEHOLDERS_FAILURE,
  RESET_STAKEHOLDERS_ACTIONS,
  DOWNLOADING_LEGAL_FILE_START,
  DOWNLOADING_LEGAL_FILE_END,
} from '../actions/types';
import { CONTRACT, LEGAL_REPORT, TERMS, POLICY } from '../helpers/Constants';

const initialState = {
  loadingStakeholders: false,
  deletingStakeholders: false,
  stakeholders: [],
  stakeholdersError: false,
  operationCompleted: false,
  downloadingLegalReport: false,
  downloadingContract: false,
  downloadingTerms: false,
  downloadingPolicy: false,
};

const updateDownloadingFlag = (type, flag) => {
  let update = {};
  switch (type) {
    case CONTRACT:
      update = { downloadingContract: flag };
      break;
    case POLICY:
      update = { downloadingPolicy: flag };
      break;
    case TERMS:
      update = { downloadingTerms: flag };
      break;
    case LEGAL_REPORT:
      update = { downloadingLegalReport: flag };
      break;
    default:
      break;
  }
  return update;
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_STAKEHOLDERS_START:
      return {
        ...state,
        loadingStakeholders: true,
        stakeholdersError: false,
      };

    case FETCH_STAKEHOLDERS_SUCCESS:
      return {
        ...state,
        loadingStakeholders: false,
        stakeholders: payload.stakeholders,
      };

    case FETCH_STAKEHOLDERS_FAILURE:
      return {
        ...state,
        loadingStakeholders: false,
        stakeholdersError: true,
      };

    case CREATE_STAKEHOLDERS_START:
      return {
        ...state,
        loadingStakeholders: true,
        stakeholdersError: false,
        operationCompleted: false,
      };

    case CREATE_STAKEHOLDERS_SUCCESS: {
      const newStakeholders = [...state.stakeholders, payload.stakeholder];
      return {
        ...state,
        loadingStakeholders: false,
        stakeholders: newStakeholders,
        operationCompleted: true,
      };
    }
    case CREATE_STAKEHOLDERS_FAILURE:
      return {
        ...state,
        loadingStakeholders: false,
        stakeholdersError: true,
        operationCompleted: true,
      };
    case EDIT_STAKEHOLDERS_START:
      return {
        ...state,
        loadingStakeholders: true,
        stakeholdersError: false,
        operationCompleted: false,
      };

    case EDIT_STAKEHOLDERS_SUCCESS: {
      const newStakeholders = state.stakeholders.filter(
        stakeholder => stakeholder.id !== payload.id
      );
      newStakeholders.push(payload);
      return {
        ...state,
        loadingStakeholders: false,
        stakeholders: newStakeholders,
        operationCompleted: true,
      };
    }
    case EDIT_STAKEHOLDERS_FAILURE:
      return {
        ...state,
        loadingStakeholders: false,
        stakeholdersError: true,
        operationCompleted: true,
      };
    case DELETE_STAKEHOLDERS_START:
      return {
        ...state,
        deletingStakeholders: true,
        stakeholdersError: false,
        operationCompleted: false,
      };

    case DELETE_STAKEHOLDERS_SUCCESS: {
      const newStakeholders = state.stakeholders.filter(
        stakeholder => stakeholder.id !== payload
      );
      return {
        ...state,
        deletingStakeholders: false,
        stakeholders: newStakeholders,
        operationCompleted: true,
      };
    }
    case DELETE_STAKEHOLDERS_FAILURE:
      return {
        ...state,
        deletingStakeholders: false,
        stakeholdersError: true,
        operationCompleted: true,
      };
    case RESET_STAKEHOLDERS_ACTIONS:
      return {
        ...state,
        deletingStakeholders: false,
        loadingStakeholders: false,
        operationCompleted: false,
        stakeholdersError: false,
      };
    case DOWNLOADING_LEGAL_FILE_START: {
      const update = updateDownloadingFlag(payload.type, true);
      return { ...state, ...update };
    }
    case DOWNLOADING_LEGAL_FILE_END: {
      const update = updateDownloadingFlag(payload.type, false);
      return { ...state, ...update };
    }
    default:
      return state;
  }
}
