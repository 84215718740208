export const wsConnect = () => ({ type: 'WS_CONNECT' });
export const loadSiiDataStart = token => ({
  type: 'LOAD_SII_DATA_START',
  token,
});
export const loadSiiDataUpdate = payload => ({
  type: 'LOAD_SII_DATA_UPDATE',
  payload,
});
export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = host => ({ type: 'WS_DISCONNECTED', host });
