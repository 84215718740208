import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    background: props =>
      props.disabled && !props.loading ? '#EFECF5' : 'white',
    border: props =>
      props.disabled && !props.loading
        ? '2px solid #EFECF5'
        : '2px solid #F87348',
    borderRadius: '30px',
    color: '#F87348',
    font: 'bold 13px Muli',
    textTransform: 'capitalize',
    '&:hover': {
      background: props => (!props.loading ? '#F87348' : 'white'),
      color: 'white',
    },
    '& span': {
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    width: props => props.width || 'auto',
  },
});

const OrangeButton = props => {
  const classes = useStyles(props);
  const { onClick, text, size, loading } = props;
  const propsButton = { ...props };
  delete propsButton.loading;
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsButton}
      size={size}
      className={classes.button}
      onClick={onClick}
    >
      {!loading ? text : <CircularProgress size={16} />}
    </Button>
  );
};

OrangeButton.defaultProps = {
  size: 'medium',
  loading: false,
  width: null,
};

OrangeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool,
  width: PropTypes.number,
};

export default OrangeButton;
