import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Chip, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import Input from '../form/Input';
import verifyRut from '../../helpers/validation/rut';
import ModalDialog from './ModalDialog';
import Container from './Container';

const useStyles = {
  dialog: {
    background: '#F3F2F5',
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    width: '80%',
  },
  title: {
    color: '#575360',
  },
  dialogContent: {
    padding: '40px 120px',
  },
  rounded: {
    borderRadius: '40px',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  chipSuccess: {
    background: 'green',
    color: 'white',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
  },
};

class ServicePasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceId: props.service.serviceId,
      rut: '',
      password: '',
    };
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isValidRut', value => {
      const validation = verifyRut(value);
      if (validation) return true;
      return false;
    });
  };

  handleInputChange = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = () => {
    const { rut: username, password, serviceId } = this.state;
    const serviceData = { serviceId, username, password };
    this.props.handleSubmit(serviceData);
  };

  render() {
    const {
      classes,
      registeringBusinessService,
      serviceRegistered,
      open,
      logo,
      handleCloseDialog,
      businessError,
      service: { name: serviceName },
    } = this.props;
    const { rut, password } = this.state;
    return (
      <ModalDialog
        open={open}
        close={handleCloseDialog}
        dialogContent={
          <Container gridGap={20} justify="center">
            {logo}
            <Typography
              variant="body1"
              className={classes.title}
              align="center"
            >
              Ingresa tus datos de {serviceName}{' '}
            </Typography>
            {registeringBusinessService ? (
              <CircularProgress />
            ) : serviceRegistered ? (
              <Chip
                color="primary"
                icon={<CheckIcon />}
                className={classes.chipSuccess}
                label="Información registrada!"
              />
            ) : (
              <ValidatorForm
                ref={form => {
                  this.form = form;
                }}
                onSubmit={this.handleSubmit}
                className={classes.form}
              >
                <Container gridGap={20} justify="center">
                  <Container>
                    <Input
                      name="rut"
                      label={
                        serviceName === 'Previred'
                          ? 'RUT de ingreso'
                          : 'RUT empresa'
                      }
                      value={rut}
                      onChange={e => this.handleInputChange(e)}
                      validators={['required', 'isValidRut']}
                      errorMessages={['Campo requerido', 'Rut inválido']}
                    />
                    <Input
                      type="password"
                      name="password"
                      label="Contraseña"
                      value={password}
                      onChange={e => this.handleInputChange(e)}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  </Container>
                  {businessError && (
                    <Typography variant="body1" className={classes.error}>
                      El rut y/o la clave ingresada no coinciden. Por favor
                      intenta nuevamente.
                    </Typography>
                  )}
                  <Container
                    gridGap={20}
                    justify="center"
                    gridTemplate="max-content max-content"
                  >
                    <SubmitLoadingGradientButton label="Guardar" />
                  </Container>
                </Container>
              </ValidatorForm>
            )}
          </Container>
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    registeringBusinessService: state.business.registeringBusinessService,
    serviceRegistered: state.business.serviceRegistered,
    businessError: state.business.businessError,
  };
};

ServicePasswordDialog.propTypes = {
  classes: PropTypes.shape({
    dialog: PropTypes.shape({}).isRequired,
    closeButtonContainer: PropTypes.shape({}).isRequired,
    form: PropTypes.shape({}).isRequired,
    title: PropTypes.shape({}).isRequired,
    dialogContent: PropTypes.shape({}).isRequired,
    rounded: PropTypes.shape({}).isRequired,
    justifyCenter: PropTypes.shape({}).isRequired,
    chipSuccess: PropTypes.shape({}).isRequired,
    error: PropTypes.shape({}).isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  logo: PropTypes.node.isRequired,
  registeringBusinessService: PropTypes.bool.isRequired,
  serviceRegistered: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  businessError: PropTypes.bool.isRequired,
  service: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  withStyles(useStyles)(ServicePasswordDialog)
);
