import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px 10px 20px 10px',
  },
  error: {
    color: '#FFF',
    background: props =>
      props.variant === 'warning' ? '#FFB100' : theme.palette.error.main,
    padding: 8,
    borderRadius: 10,
    textAlign: 'center',
    fontWeight: 600,
  },
}));
const AlertForm = props => {
  const classes = useStyles(props);
  const { message } = props;

  return (
    <Grid item xs={12} className={classes.root}>
      <Typography variant="body1" className={classes.error}>
        {message}
      </Typography>
    </Grid>
  );
};

AlertForm.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AlertForm;
