import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderRadius: 17,
    border: '1px solid #E7E5EC',
    background: props => props.background,
  },
});

const Card = props => {
  const classes = useStyles(props);
  return (
    <Paper spacing={3} elevation={0} className={classes.root}>
      {props.children}
    </Paper>
  );
};

Card.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Card;
