import { Fade, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 20,
    minHeight: '75vh',
    borderRadius: 17,
    padding: '20px 0px',
    border: '1px solid #E7E5EC',
  },
});

const XtrackerPage = ({ businessId }) => {
  const classes = useStyles();

  const xtrackerUrl = `https://datastudio.google.com/report-framed/reporting/e5d966ad-217a-4b0b-888c-8968a48e86da/page/p_79sh5k83nc?params=%7B%22df85%22:%22include%25EE%2580%25801%25EE%2580%2580EQ%25EE%2580%2580${businessId}%22%7D`;
  return (
    <Fade timeout={400} in>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Paper spacing={3} elevation={0} className={classes.paper}>
              <iframe
                title="xtracker-iframe"
                allowFullScreen=""
                frameBorder="0"
                src={xtrackerUrl}
                width="100%"
                height="2000"
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

XtrackerPage.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default XtrackerPage;
