import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import GradientButton from './form/GradientButton';
import imgChampagna from '../assets/champagna.svg';
import TopImageModalDialog from './elements/TopImageModalDialog';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
});

const FacturasSentDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/orders');
  };

  return (
    <TopImageModalDialog
      isOpen={open}
      handleClose={handleClose}
      withCloseButton
      imageSource={imgChampagna}
      imageWidth={169}
      title="¡Solicitud enviada!"
      content={
        <Typography className={classes.content}>
          Puedes realizar el <strong>seguimiento de tu solicitud</strong>
          <br /> en la sección del <strong>estado de operaciones.</strong>
        </Typography>
      }
      actions={
        <Grid xs={12} align="center">
          <GradientButton fullWidth={false} onClick={handleConfirm}>
            Ver mis facturas
          </GradientButton>
        </Grid>
      }
    />
  );
};

FacturasSentDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FacturasSentDialog;
