import { PREVIRED_LABEL } from './Constants';

// eslint-disable-next-line import/prefer-default-export
export const hasPreviredService = auth => {
  let hasPreviredService = false;
  const [{ Services }] = auth?.user?.Businesses;
  if (Services) {
    hasPreviredService = Services.some(
      service => service.name === PREVIRED_LABEL
    );
  }

  return hasPreviredService;
};
