import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import GradientButton from './form/GradientButton';
import imgBanco from '../assets/banco.svg';
import TopImageModalDialog from './elements/TopImageModalDialog';

const useStyles = makeStyles({
  content: {
    color: '#575360',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '24px',
  },
});

const FacturasSentBankAccountDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/user/profile/bank-accounts');
  };

  return (
    <TopImageModalDialog
      isOpen={open}
      withCloseButton
      handleClose={handleClose}
      imageSource={imgBanco}
      imageWidth={172}
      title="Ingresa tu cuenta bancaria"
      subtitle="¡Felicidades! Tu solicitud ha sido enviada."
      content={
        <Typography className={classes.content}>
          Ingresa los <strong>datos de la cuenta bancaria</strong> para que
          <br />
          podamos realizar la <strong>transferencia de tu dinero.</strong>
        </Typography>
      }
      actions={
        <Grid xs={5}>
          <GradientButton onClick={handleConfirm}>
            Ir a Cuentas bancarias
          </GradientButton>
        </Grid>
      }
    />
  );
};

FacturasSentBankAccountDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FacturasSentBankAccountDialog;
