import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ModalDialog from './ModalDialog';
import Container from './Container';
import DropzoneGeneric from './DropzoneGeneric';

const useStyles = makeStyles({
  dialogContent: {
    padding: 30,
  },
  title: {
    color: '#575360',
    fontSize: 30,
    fontWeight: 'bold',
  },
  subtitle: {
    color: '#A8A4B3',
    font: 'Regular 13px/21px Muli',
  },
  content: {
    color: '#575360',
    fontSize: 18,
  },
  actions: {
    paddingTop: 30,
    paddingBottom: 50,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
});

const BulkFacturaDialog = ({
  open,
  handleClose,
  handleSubmitBulkFacturas,
  pendingFacturasCounter,
  isUploading,
}) => {
  const classes = useStyles();

  return (
    <ModalDialog
      open={open}
      close={handleClose}
      size="md"
      dialogTitle={`Sube tus ${pendingFacturasCounter} facturas cedibles`}
      dialogContent={
        <Container gridGap={20}>
          <Typography variant="body2" className={classes.subTitle}>
            Te sugerimos que cargues tus PDFs con el número de folio de la
            factura como nombre del archivo. Ejemplo: 76.pdf
          </Typography>
          {isUploading ? (
            <Container direction="row" align="center">
              <CircularProgress />
            </Container>
          ) : (
            <Container>
              <DropzoneGeneric
                handleSubmit={handleSubmitBulkFacturas}
                submitButtonContent="Subir PDFs"
                accept=".pdf"
                multiple
                maxFiles={pendingFacturasCounter}
              />
            </Container>
          )}
        </Container>
      }
    />
  );
};

BulkFacturaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmitBulkFacturas: PropTypes.func.isRequired,
  pendingFacturasCounter: PropTypes.isRequired,
  isUploading: PropTypes.func.isRequired,
};

export default BulkFacturaDialog;
