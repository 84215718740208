import {
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  RESET_EMAIL_DIALOG,
} from '../actions/types';

const initialState = {
  sendingEmail: false,
  emailResponse: [],
  emailError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEND_EMAIL_START:
      return {
        ...state,
        sendingEmail: true,
      };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailResponse: payload.status,
        sendingEmail: false,
      };

    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        sendingEmail: false,
        emailError: payload.error,
      };

    case RESET_EMAIL_DIALOG:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
