// authentication.js

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../helpers/setAuthToken';
import configureStore from '../modules/store';
import {
  REGISTER_BUSINESS_SERVICE_START,
  REGISTER_BUSINESS_SERVICE_SUCCESS,
  REGISTER_BUSINESS_SERVICE_FAILURE,
  REGISTER_BANK_ACCOUNT_START,
  REGISTER_BANK_ACCOUNT_SUCCESS,
  REGISTER_BANK_ACCOUNT_FAILURE,
  SET_CURRENT_USER,
  LOGIN_SUCCESS,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  SETTINGS_RESUME_START,
  SETTINGS_RESUME_SUCCESS,
  SETTINGS_RESUME_FAILURE,
  DOWNLOADING_LEGAL_FILE_START,
  DOWNLOADING_LEGAL_FILE_END,
  FETCH_BANK_ACCOUNTS_START,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILURE,
} from './types';
import { identifyAndTrack } from '../helpers/trackingSegment';
import { addNotification } from './notificationsAction';
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  CONTRACT,
  CONTRACT_LABEL,
  LEGAL_REPORT_LABEL,
} from '../helpers/Constants';
import { b64toBlob, download } from '../helpers/fileHandling';
import {
  DELETE_BANK_ACCOUNT_MSG_ERROR,
  DELETE_BANK_ACCOUNT_MSG_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_MSG_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_MSG_ERROR,
} from '../helpers/snackBarMessages';

const { persistor } = configureStore();
const identifyAndTrackServiceUpdate = (businessId, serviceData) => {
  const { serviceId } = serviceData;
  let identifyPayload;
  let trackMsg;

  switch (serviceId) {
    case 1:
      identifyPayload = {
        tgrSetup: true,
      };
      trackMsg = 'TGR Setup';
      break;

    case 2:
      identifyPayload = {
        previredSetup: true,
      };
      trackMsg = 'Previred Setup';
      break;

    default:
      break;
  }
  identifyAndTrack(businessId, identifyPayload, trackMsg);
};

export const fetchSettingsResume = (businessId, userId) => dispatch => {
  dispatch({ type: SETTINGS_RESUME_START });
  axios
    .get(`/api/business/${businessId}/users/${userId}/settingsresume`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: SETTINGS_RESUME_SUCCESS, payload: resData });
      return resData;
    })
    .catch(() => {
      dispatch({ type: SETTINGS_RESUME_FAILURE });
    });
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const loginWithToken = token => dispatch => {
  const decoded = jwtDecode(token);
  localStorage.setItem('jwtToken', token);
  setAuthToken(token);
  persistor.persist();
  dispatch({
    type: LOGIN_SUCCESS,
  });
  dispatch(setCurrentUser({ decoded: decoded.data, token }));
};

export const registerBusinessService = serviceData => dispatch => {
  dispatch({ type: REGISTER_BUSINESS_SERVICE_START });
  axios
    .post(`/api/business/${serviceData.businessId}/service`, { serviceData })
    .then(({ data }) => {
      const { token } = data;
      const decoded = jwtDecode(token);

      // Track business service registration
      const { Businesses } = decoded.data;
      const [business = {}] = Businesses;
      const { id: businessId = 0 } = business;
      identifyAndTrackServiceUpdate(businessId, serviceData);

      dispatch(loginWithToken(token, dispatch));
      dispatch({ type: REGISTER_BUSINESS_SERVICE_SUCCESS });
    })
    .catch(err => {
      dispatch(
        dispatch({ type: REGISTER_BUSINESS_SERVICE_FAILURE, payload: err })
      );
    });
};

export const registerBankAccount =
  (bankAccountData, businessId, userId) => dispatch => {
    dispatch({ type: REGISTER_BANK_ACCOUNT_START });
    axios
      .post('/api/bank-account/', {
        bankAccountData,
      })
      .then(({ data }) => {
        const { bankAccount } = data;

        dispatch({
          type: REGISTER_BANK_ACCOUNT_SUCCESS,
          payload: { newBankAccount: bankAccount },
        });
        dispatch(
          addNotification('Cuenta agregada existosamente', SNACKBAR_SUCCESS)
        );
        dispatch(fetchSettingsResume(businessId, userId));
      })
      .catch(err => {
        dispatch({ type: REGISTER_BANK_ACCOUNT_FAILURE, payload: err });
      });
  };

export const deleteBankAccount = bankAccountId => dispatch => {
  dispatch({ type: DELETE_BANK_ACCOUNT_START });

  axios
    .delete(`/api/bank-account/${bankAccountId}`)
    .then(() => {
      dispatch({
        type: DELETE_BANK_ACCOUNT_SUCCESS,
        payload: { bankAccountId },
      });
      dispatch(
        addNotification(DELETE_BANK_ACCOUNT_MSG_SUCCESS, SNACKBAR_SUCCESS)
      );
    })
    .catch(err => {
      dispatch({ type: DELETE_BANK_ACCOUNT_FAILURE, payload: err });
      dispatch(addNotification(DELETE_BANK_ACCOUNT_MSG_ERROR, SNACKBAR_ERROR));
    });
};

export const setDefaultBankAccount = bankAccountId => dispatch => {
  dispatch({ type: SET_DEFAULT_BANK_ACCOUNT_START });
  axios
    .put(`/api/bank-account/${bankAccountId}`)
    .then(({ data }) => {
      const { bankAccount } = data;
      dispatch({
        type: SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
        payload: { bankAccountUpdated: bankAccount },
      });
      dispatch(
        addNotification(SET_DEFAULT_BANK_ACCOUNT_MSG_SUCCESS, SNACKBAR_SUCCESS)
      );
    })
    .catch(() => {
      dispatch({ type: SET_DEFAULT_BANK_ACCOUNT_FAILURE });
      dispatch(
        addNotification(SET_DEFAULT_BANK_ACCOUNT_MSG_ERROR, SNACKBAR_ERROR)
      );
    });
};

export const fetchBusinessLegalFile = (businessId, type) => dispatch => {
  dispatch({ type: DOWNLOADING_LEGAL_FILE_START, payload: { type } });
  axios
    .get(`api/business/${businessId}/file?type=${type}`)
    .then(({ data }) => {
      const { file, name } = data;
      const blob = b64toBlob(file, 'application/pdf');
      dispatch({ type: DOWNLOADING_LEGAL_FILE_END, payload: { type } });
      return download(blob, name);
    })
    .catch(() => {
      const docLabel = type === CONTRACT ? CONTRACT_LABEL : LEGAL_REPORT_LABEL;
      dispatch({ type: DOWNLOADING_LEGAL_FILE_END, payload: { type } });
      dispatch(
        addNotification(`Error descargando ${docLabel}`, SNACKBAR_ERROR)
      );
    });
};

export const fetchBankAccounts = businessId => dispatch => {
  dispatch({ type: FETCH_BANK_ACCOUNTS_START });
  axios
    .get(`/api/bank-account/${businessId}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_BANK_ACCOUNTS_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({ type: FETCH_BANK_ACCOUNTS_FAILURE, payload: { error } });
    });
};
