import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const XtrackerIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g id="Mesa_de_trabajo_1" data-name="Mesa de trabajo – 1">
        <g id="Grupo_3613" data-name="Grupo 3613" transform="translate(1 1)">
          <g id="Grupo_3611" data-name="Grupo 3611" transform="translate(0 0)">
            <path
              id="Trazado_6871"
              data-name="Trazado 6871"
              d="M18.7,3.14,17.519,4.348a9.326,9.326,0,1,1-15.832,6.68,9.4,9.4,0,0,1,8.485-9.291V4.049a7.036,7.036,0,1,0,5.813,2.01L14.792,7.251a5.341,5.341,0,1,1-4.62-1.5V8.994a2.2,2.2,0,1,0,1.687,0V0L10.681.018a11.015,11.015,0,1,0,11.35,11.01A10.928,10.928,0,0,0,18.7,3.14"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default XtrackerIcon;
