import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { setNewPassword } from '../actions/resetPasswordAction';
import CustomInfoCard from './elements/CustomInfoCard';
import Input from './form/Input';
import SubmitLoadingGradientButton from './form/SubmitLoadingGradientButton';
import { ReactComponent as LogoXepelin } from '../assets/logo-color.svg';

const useStyles = {
  mainContainer: {
    marginTop: '5%',
  },
  paperContainer: {
    padding: 20,
  },
  topBar: {
    padding: 0,
    background: 'linear-gradient(-90deg, #F87C54 30%, #FAB584 100%)',
    borderBottom: '8px solid ',
    borderImageSlice: '0 0 1 0',
    borderImageSource: 'linear-gradient(to bottom, #A739E3, #7300FF)',
  },
  logo: {
    width: 300,
    padding: 10,
  },
  dangerText: {
    color: 'red',
    fontSize: 14,
    paddingTop: 10,
  },
  link: {
    cursor: 'pointer',
  },
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('passwordMatch', () => {
      if (this.state.password !== this.state.confirmPassword) {
        return false;
      }
      return true;
    });
  }

  handleFormSubmit = e => {
    e.preventDefault();
    const data = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: this.props.match.params.hash,
    };
    this.props.setNewPassword(data);
  };

  handleInputChange = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  render() {
    const {
      classes,
      history,
      newPasswordSubmit,
      newPasswordSuccess,
      newPasswordError,
    } = this.props;
    const { password, confirmPassword } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            xs={12}
            direction="column"
            className={classes.topBar}
            alignItems="center"
          >
            <Grid item>
              <LogoXepelin className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.mainContainer}
          >
            <Grid item xs={10} sm={6}>
              {!newPasswordSuccess && (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                >
                  <Grid item xs={10}>
                    <Typography variant="h5" align="center">
                      Ingresa tu nueva contraseña
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <ValidatorForm
                      ref={form => {
                        this.form = form;
                      }}
                      onSubmit={this.handleFormSubmit}
                    >
                      <Input
                        label="Contraseña"
                        onChange={this.handleInputChange}
                        value={password}
                        name="password"
                        type="password"
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                        disabled={newPasswordSubmit}
                      />
                      <Input
                        label="Confirmar contraseña"
                        onChange={this.handleInputChange}
                        value={confirmPassword}
                        name="confirmPassword"
                        type="password"
                        validators={['required', 'passwordMatch']}
                        errorMessages={[
                          'Campo requerido',
                          'Las contraseñas no coinciden',
                        ]}
                        disabled={newPasswordSubmit}
                      />
                      <SubmitLoadingGradientButton
                        label="Enviar"
                        disabled={newPasswordSubmit}
                      />
                    </ValidatorForm>
                  </Grid>
                </Grid>
              )}
              {newPasswordSuccess && (
                <Grid container justifyItems="center">
                  <Grid item xs={12}>
                    <CustomInfoCard title="Tu contraseña ha sido actualizada!">
                      Para volver al inicio, has click{' '}
                      <Link
                        onClick={() => history.push('/start')}
                        className={classes.link}
                      >
                        aquí
                      </Link>
                    </CustomInfoCard>
                  </Grid>
                </Grid>
              )}
              {newPasswordError && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      className={classes.dangerText}
                      align="center"
                    >
                      Ha ocurrido un error. Vuelve a intentarlo.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  match: Proptypes.objectOf(Object).isRequired,
  setNewPassword: Proptypes.func.isRequired,
  history: Proptypes.objectOf(Object).isRequired,
  classes: Proptypes.objectOf(Object).isRequired,
  newPasswordSubmit: Proptypes.bool.isRequired,
  newPasswordSuccess: Proptypes.bool.isRequired,
  newPasswordError: Proptypes.bool.isRequired,
};

const mapStateToProps = state => ({
  newPasswordSubmit: state.reset.newPasswordSubmit,
  newPasswordSuccess: state.reset.newPasswordSuccess,
  newPasswordError: state.reset.newPasswordError,
});

export default compose(
  connect(mapStateToProps, { setNewPassword }),
  withStyles(useStyles)
)(ResetPassword);
