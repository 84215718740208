import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import logoXepelin from '../assets/logo-blanco.svg';
import logoFinmas from '../assets/imagotipo-finmas.svg';

const useStyles = makeStyles({
  menuContainer: {
    padding: 0,
    background: 'linear-gradient(-90deg, #F87C54 30%, #FAB584 100%)',
    borderBottom: '8px solid ',
    borderImageSlice: '0 0 1 0',
    borderImageSource: 'linear-gradient(to bottom, #A739E3, #7300FF)',
  },
  // iconContainer: {
  //   paddingTop: 10,
  // },
  buttonsContainer: {
    paddingTop: 20,
  },
  buttonActive: {
    backgroundColor: 'white',
    color: '#7300FF',
    fontSize: 15,
    fontStyle: 'camelized',
    padding: 10,
    borderRadius: '30px 30px 0px 0px',
    // marginRight: -40,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  buttonInactive: {
    fontSize: 15,
    backgroundColor: 'transparent',
    color: 'white',
    padding: 10,
    // marginRight: -40,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logoXepelin: {
    margin: 10,
    height: 100,
  },
  logoFinmas: {
    margin: '40px 10px 10px 10px',
    height: 50,
  },
});

const MobileStartMenu = ({ active, handleActive }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      xs={12}
      direction="column"
      className={classes.menuContainer}
      alignItems="center"
      //   spacing={5}
    >
      <Grid item xs={12}>
        <Grid container justify="center" direction="row">
          <Grid item className={classes.iconContainer} xs={6}>
            <img
              src={logoXepelin}
              alt="Xepelin"
              width="100px"
              className={classes.logoXepelin}
            />
          </Grid>
          <Grid item className={classes.iconContainer} xs={6}>
            <img
              src={logoFinmas}
              alt="Finmas"
              width="100px"
              className={classes.logoFinmas}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.buttonsContainer} xs={12}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <Button
              className={
                active === 'register'
                  ? classes.buttonActive
                  : classes.buttonInactive
              }
              onClick={e => handleActive(e, 'register')}
            >
              Registro
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={
                active === 'login'
                  ? classes.buttonActive
                  : classes.buttonInactive
              }
              onClick={e => handleActive(e, 'login')}
            >
              {' '}
              Ingresar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MobileStartMenu.propTypes = {
  active: PropTypes.string.isRequired,
  handleActive: PropTypes.func.isRequired,
};

export default MobileStartMenu;
