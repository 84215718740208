import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Link,
  CircularProgress,
  Typography,
  InputAdornment,
  FormHelperText,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Check, ErrorOutline } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import LoopIcon from '@material-ui/icons/Loop';
import SubmitLoadingGradientButton from './form/SubmitLoadingGradientButton';
import Input from './form/Input';
import verifyRut from '../helpers/validation/rut';
import { fetchCompanyNameByRut } from '../actions/sii';
import {
  verifyEmail,
  handleInputChange,
  resetVerifyEmail,
} from '../actions/user';
import AlertForm from './elements/AlertForm';
import { VALIDATION_MESSAGES, VALIDATION_TYPES } from '../helpers/Constants';

const useStyles = makeStyles({
  userDisclamer: {
    marginTop: 20,
    fontSize: 12,
    fontFamily: 'Muli',
    color: '#CECAD9',
  },
  link: {
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: 30,
  },
  submitButton: {
    marginTop: 20,
  },
  progress: {
    color: '#1A73E7',
  },
  valid: {
    color: '#75B801',
  },
  warning: {
    color: '#E29D02',
  },
  iconArrow: { color: '#FFFF' },
  errorInput: {
    '& label': {
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
});

const Register = ({ handleSubmit }) => {
  const [checkingEmail, setCheckingEmail] = useState(false);
  const nombre = useSelector(state => state.register.nombre);
  const nombreEmpresa = useSelector(state => state.register.nombreEmpresa);
  const rutEmpresa = useSelector(state => state.register.rutEmpresa);
  const email = useSelector(state => state.register.email);
  const telefono = useSelector(state => state.register.telefono);
  const password = useSelector(state => state.register.password);
  const passwordCheck = useSelector(state => state.register.passwordCheck);
  const submit = useSelector(state => state.register.submit);
  const errors = useSelector(state => state.register.errors);
  const disabledCompanyName = useSelector(
    state => state.register.disabledCompanyName
  );
  const verifyingEmail = useSelector(state => state.register.verifyingEmail);
  const emailValid = useSelector(state => state.register.emailValid);
  const emailSuggestion = useSelector(state => state.register.emailSuggestion);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', () => {
      if (password !== passwordCheck) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isValidPhone', () => {
      // This regex acepts +(numbers 51 to 57)(9 digits),  no spaces.
      const regex = new RegExp(/^(\+)(5[1-7])(\d{9})$/g);
      return regex.test(telefono);
    });

    ValidatorForm.addValidationRule('isValidRut', value => {
      const validation = verifyRut(value?.trim());
      if (validation) {
        if (!nombreEmpresa) {
          dispatch(fetchCompanyNameByRut(value?.trim()));
          dispatch(handleInputChange('nombreEmpresa', nombreEmpresa));
        }
        return true;
      }
      dispatch(handleInputChange('nombreEmpresa', ''));
      return false;
    });
  }, [password, passwordCheck, telefono, nombreEmpresa, dispatch]);

  const checkEmail = email => {
    setCheckingEmail(true);
    dispatch(verifyEmail(email));
  };

  const handleInputEmail = e => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/gi)) {
      checkEmail(value);
    } else {
      setCheckingEmail(false);
      dispatch(resetVerifyEmail());
    }
    dispatch(handleInputChange('email', value));
  };

  const getIconEmailInput = () => {
    let icon;
    if (!checkingEmail) {
      return null;
    }
    if (verifyingEmail) {
      icon = (
        <Tooltip title="Validando correo">
          <CircularProgress className={classes.progress} size={30} />
        </Tooltip>
      );
    } else if ((emailValid && !emailSuggestion) || emailSuggestion === email) {
      icon = <Check className={classes.valid} />;
    } else {
      icon = <ErrorOutline className={classes.warning} />;
    }
    return <InputAdornment position="end">{icon}</InputAdornment>;
  };

  const getMessageWarning = () => {
    let messageWarning;
    if (emailSuggestion && emailSuggestion !== email) {
      messageWarning = (
        <>
          Quisiste decir {emailSuggestion}
          <IconButton
            onClick={() =>
              dispatch(handleInputChange('email', emailSuggestion))
            }
          >
            <LoopIcon className={classes.iconArrow} />
          </IconButton>
        </>
      );
    } else if (checkingEmail && !emailValid) {
      messageWarning = 'Correo no pudo ser verificado';
    }
    return messageWarning;
  };
  const messageWarning = getMessageWarning();

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title} align="center">
          Empieza a usar Xepelin
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Input
            autoComplete="new-password"
            label="* Nombre y Apellido"
            name="nombre"
            value={nombre}
            onChange={e =>
              dispatch(handleInputChange('nombre', e.target.value))
            }
            validators={['required']}
            errorMessages={['Campo requerido']}
          />
          <Input
            autoComplete="new-password"
            label="* RUT Empresa"
            name="rut"
            value={rutEmpresa}
            onChange={e =>
              dispatch(handleInputChange('rutEmpresa', e.target.value))
            }
            validators={['required', 'isValidRut']}
            errorMessages={['Campo requerido', 'Rut inválido']}
          />
          <Input
            disabled={disabledCompanyName}
            autoComplete="empresa"
            label="* Nombre Empresa"
            name="empresa"
            value={nombreEmpresa}
            onChange={e =>
              dispatch(handleInputChange('nombreEmpresa', e.target.value))
            }
            validators={['required']}
            errorMessages={['Campo requerido']}
          />
          <Input
            autoComplete="new-password"
            label="* Email"
            name="email"
            value={email}
            onChange={handleInputEmail}
            InputProps={{
              endAdornment: getIconEmailInput(),
            }}
            validators={[
              (VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.VALID_EMAIL),
            ]}
            errorMessages={[
              VALIDATION_MESSAGES.REQUIRED_MSG,
              VALIDATION_MESSAGES.VALID_EMAIL_MSG,
            ]}
          />
          <Input
            autoComplete="new-password"
            label="* Numero de contacto"
            name="telefono"
            value={telefono}
            onChange={e =>
              dispatch(handleInputChange('telefono', e.target.value))
            }
            validators={[
              VALIDATION_TYPES.REQUIRED,
              VALIDATION_TYPES.VALID_PHONE,
            ]}
            errorMessages={[
              VALIDATION_MESSAGES.REQUIRED_MSG,
              VALIDATION_MESSAGES.VALID_PHONE_MSG,
            ]}
          />
          {errors.telefono &&
          errors.telefono.errors &&
          errors.telefono.errors.length > 0 ? (
            <FormHelperText className="Mui-error">
              {errors.telefono.errors}
            </FormHelperText>
          ) : null}
          <Input
            autoComplete="new-password"
            label="* Contraseña"
            name="password"
            value={password}
            type="password"
            onChange={e =>
              dispatch(handleInputChange('password', e.target.value))
            }
            validators={[VALIDATION_TYPES.REQUIRED, 'minStringLength:6']}
            errorMessages={[
              VALIDATION_MESSAGES.REQUIRED_MSG,
              'Mínimo 6 caracteres',
            ]}
          />
          <Input
            autoComplete="new-password"
            label="* Repetir Contraseña"
            name="repeat-email"
            value={passwordCheck}
            type="password"
            onChange={e =>
              dispatch(handleInputChange('passwordCheck', e.target.value))
            }
            validators={[
              VALIDATION_TYPES.REQUIRED,
              VALIDATION_TYPES.MATCH_CONFIRM_PASSWORD,
            ]}
            errorMessages={[
              VALIDATION_MESSAGES.REQUIRED_MSG,
              VALIDATION_MESSAGES.MATCH_CONFIRM_PASSWORD_MSG,
            ]}
          />
          {(checkingEmail && !verifyingEmail && !emailValid) ||
          (emailSuggestion && email && emailSuggestion !== email) ? (
            <AlertForm variant="warning" message={messageWarning} />
          ) : null}
          <SubmitLoadingGradientButton
            submit={submit}
            label="Registrarse"
            className={classes.submitButton}
          />
        </ValidatorForm>
        <Grid container justify="center" className={classes.userDisclamer}>
          <Grid item className={classes.formFooter}>
            Al registrarte aceptas nuestros{' '}
            <Link
              target="_blank"
              color="primary"
              href="https://www.xepelin.com/terminos-y-condiciones"
              className={classes.link}
            >
              Términos y Condiciones
            </Link>{' '}
            y{' '}
            <Link
              target="_blank"
              color="primary"
              href="https://www.xepelin.com/politica-de-privacidad"
              className={classes.link}
            >
              Política de Privacidad
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Register.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default Register;
