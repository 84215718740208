export const trackSegment = (msg, payload = null) => {
  if (payload) {
    window.analytics.track(msg, payload);
  } else {
    window.analytics.track(msg);
  }
};

export const identifyAndTrack = (
  businessId,
  identifyPayload,
  trackMsg,
  trackPayload = null
) => {
  window.analytics.identify(businessId, identifyPayload);
  if (trackPayload) {
    window.analytics.track(trackMsg, trackPayload);
  } else {
    window.analytics.track(trackMsg);
  }
};

export const identifyLoggedUser = (
  registeredUser,
  utmCampaign,
  utmMedium,
  utmSource
) => {
  const {
    nombre: name = '',
    Businesses = [],
    email = '',
    telefono: phone = '',
  } = registeredUser;
  const [business = {}] = Businesses;
  const {
    rut: businessRut = '',
    name: businessName = '',
    id: businessId,
  } = business;
  const userData = {
    name,
    businessRut,
    businessName,
    email,
    phone,
    utmSource,
    utmCampaign,
    utmMedium,
  };
  window.analytics.identify(businessId, userData);
};

export const identify = businessId => {
  window.analytics.identify(businessId);
};

export const trackUpdatedWhatsapp = (businessId, phoneData) => {
  if (phoneData.data) {
    const { data: newWhatsappPhone, oldPhone: oldWhatsappPhone } = phoneData;
    // Trigger when user changes whatsapp number
    window.analytics.track('Whatsapp Opt-out', {
      whatsappPhone: oldWhatsappPhone,
    });

    window.analytics.identify(businessId, {
      //  new phone
      whatsappPhone: newWhatsappPhone,
    });

    window.analytics.track('Whatsapp Opt-In', {
      //  new phone
      whatsappPhone: newWhatsappPhone,
    });
  } else {
    // Trigger when user disables whatsapp notifications
    window.analytics.identify(businessId, {
      whatsapp: phoneData.isActive,
    });
  }
};

export const setPageViewSegment = path => {
  const regexOrderDetail = RegExp(/\/orders\/\d+/g);

  let page;
  if (path === '/finance/directo') {
    page = 'Financiamiento Directo';
  } else if (path === '/finance/prontopago') {
    page = 'Pronto Pago';
  } else if (path === '/orders') {
    page = 'Order Status';
  } else if (regexOrderDetail.test(path)) {
    page = 'Order Detail';
  } else if (path === '/user/profile') {
    page = 'User Profile';
  } else if (path === '/user/profile/bank-accounts') {
    page = 'User Profile Bank Accounts';
  } else if (path === '/user/profile/sii') {
    page = 'User Profile SII';
  } else if (path === '/user/profile/tgr') {
    page = 'User Profile TGR';
  } else if (path === '/user/profile/previred') {
    page = 'User Profile Previred';
  } else if (path === '/user/profile/notifications') {
    page = 'User Profile Notifications';
  } else {
    page = '';
  }
  window.analytics.page(page);
};

export const analyticsReset = () => {
  window.analytics.reset();
};
