import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import { BUTTON_LABEL } from '../../helpers/Constants';
import BaseDialog from './BaseDialog';

const useStyles = {
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
    marginBottom: 20,
  },
  selectOutlined: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: '40px',
      background: 'transparent',
    },
  },
  customInput: {
    '& .MuiFormHelperText-root': {
      font: 'bold 11px Muli',
    },
    background: 'transparent',
    font: 'normal 12px Muli',
    '& input': {
      font: 'normal 12px Muli',
    },
    '& label': {
      font: 'normal 12px Muli',
    },
    marginBottom: 12,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '40px',
      },
    },
  },
  rounded: {
    borderRadius: '40px',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  chipSuccess: {
    background: 'green',
    color: 'white',
    fontWeight: 'bold',
  },
  label: {
    fontWeight: 'bold',
    color: '#575360',
    paddingBottom: '10px',
  },
  error: {
    color: '#FFF',
    background: '#D92F24',
    padding: 8,
    borderRadius: 5,
    textAlign: 'center',
    fontSize: '12px',
  },
  button: {
    marginTop: 30,
    marginBottom: 34,
  },
};

class UserPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.open && !this.props.open) {
      this.setState(prevState => {
        return {
          ...prevState,
          currentPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        };
      });
    }
  };

  getErrorMessage = errors => {
    switch (errors) {
      case 'Wrong password':
        return 'Su contraseña actual es incorrecta';
      case 'Password fields cannot be empty':
        return 'Debes completar todos campos';
      case 'New password does not match':
        return 'La nueva contraseña no coincide';
      default:
        return 'Error al procesar tu solicitud. Intente más tarde.';
    }
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isPasswordMatch', () => {
      if (this.state.newPassword !== this.state.newPasswordConfirm) {
        return false;
      }
      return true;
    });
  };

  handleInputChange = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = () => {
    const {
      user: { id },
      handleSubmit,
    } = this.props;
    handleSubmit({ id, ...this.state });
  };

  render() {
    const {
      classes,
      open,
      updatingUser,
      errors,
      handleCloseDialog,
      user: { email },
    } = this.props;
    const { currentPassword, newPassword, newPasswordConfirm } = this.state;

    return (
      <BaseDialog
        title="Cambiar contraseña"
        isOpen={open}
        handleClose={handleCloseDialog}
      >
        <Typography variant="body1" className={classes.subtitle} align="center">
          Vas a cambiar la contraseña para{' '}
          <strong data-qa="email-change-password-dialog">{email}</strong>
        </Typography>

        <ValidatorForm
          ref={form => {
            this.form = form;
          }}
          onSubmit={this.handleSubmit}
        >
          <TextValidator
            type="password"
            name="currentPassword"
            label="Contraseña actual"
            size="small"
            variant="outlined"
            value={currentPassword}
            onChange={e => this.handleInputChange(e)}
            validators={['required']}
            errorMessages={['Campo requerido']}
            className={classes.customInput}
            data-qa="input-current-password-password-dialog"
            fullWidth
          />
          <TextValidator
            size="small"
            variant="outlined"
            fullWidth
            className={classes.customInput}
            type="password"
            name="newPassword"
            label="Contraseña nueva"
            value={newPassword}
            onChange={e => this.handleInputChange(e)}
            validators={['required', 'minStringLength:6']}
            errorMessages={['Campo requerido', 'Mínimo 6 caracteres']}
            data-qa="input-new-password-password-dialog"
          />
          <TextValidator
            size="small"
            variant="outlined"
            fullWidth
            className={classes.customInput}
            type="password"
            name="newPasswordConfirm"
            label="Confirma tu nueva contraseña"
            value={newPasswordConfirm}
            onChange={e => this.handleInputChange(e)}
            validators={['required', 'isPasswordMatch']}
            errorMessages={['Campo requerido', 'La contraseña no coincide']}
            data-qa="input-new-password-confirm-password-dialog"
          />
          {errors && (
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.error}>
                {this.getErrorMessage(errors)}
              </Typography>
            </Grid>
          )}
          <div className={classes.button}>
            <SubmitLoadingGradientButton
              label={BUTTON_LABEL.CAMBIAR}
              subtitle={updatingUser}
              dataQa="button-submit-password-password-dialog"
            />
          </div>
        </ValidatorForm>
      </BaseDialog>
    );
  }
}

UserPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updatingUser: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    subtitle: PropTypes.object,
    customInput: PropTypes.object,
    error: PropTypes.object,
    button: PropTypes.object,
  }).isRequired,
  errors: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    updatingUser: state.auth.updatingUser,
    errors: state.auth.errors,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(UserPasswordDialog));
