import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '../reducers/rootReducer';
import wsMiddleware from '../middleware/websocketMiddleware';
import migrations from './migration';

// import logger from "redux-logger";

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

let middleware = [];
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const logger = require('redux-logger').createLogger();
  middleware = [logger];
}

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  applyMiddleware(wsMiddleware),
  applyMiddleware(...middleware)
);

const persistConfig = {
  key: 'root',
  storage,
  version: 6,
  whitelist: ['auth', 'app', 'sii', 'cart', 'order', 'assistant'],
  timeout: null,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
