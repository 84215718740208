import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const GreenSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
      '&:hover': {
        backgroundColor: '#75b80129',
      },
    },
    '& + $track': {
      backgroundColor: '#CECAD9',
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: '#75B801',
    },
  },
  checked: {},
  track: {},
})(Switch);

export default GreenSwitch;
