import {
  GET_WALLET_REQUEST,
  GET_WALLET_REQUEST_SUCCESS,
  GET_WALLET_REQUEST_ERROR,
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_REQUEST_SUCCESS,
  CREATE_WALLET_REQUEST_ERROR,
} from '../actions/types';

const initialState = {
  walletRequests: [],
  loading: false,
  errors: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_WALLET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_WALLET_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletRequests: payload.walletRequests,
      };
    }
    case GET_WALLET_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
        errors: payload.error,
      };
    }
    case CREATE_WALLET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_WALLET_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletRequests: [payload.walletRequest],
      };
    }
    case CREATE_WALLET_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
        errors: payload.error,
      };
    }
    default:
      return state;
  }
}
