import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  message: {
    backgroundColor: '#f77240',
    borderRadius: 8,
    color: '#FFF',
    font: 'Muli',
    fontWeight: 'bold',
    fontSize: 13,
    padding: 8,
    textAlign: 'center',
    margin: '10px 10px 0 10px',
  },
});

const CustomToolbarDatePicker = props => {
  const { message } = props;

  const classes = useStyles();

  return !!message && <div className={classes.message}>{message}</div>;
};

CustomToolbarDatePicker.defaultProps = {
  message: null,
};

CustomToolbarDatePicker.propTypes = {
  message: PropTypes.object,
};

export default CustomToolbarDatePicker;
