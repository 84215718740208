import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  IconButton,
  Fade,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import TimesIcon from '@material-ui/icons/CloseRounded';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PhoneInput from 'react-phone-input-2';
import WhatsappOptinDialog from './elements/WhatsappOptinDialog';
import CustomSnackBar from './elements/CustomSnackBar';
import {
  fetchUserServices,
  registerUserService,
  updateUserService,
} from '../actions/user';
import {
  BUTTON_LABEL,
  SERVICE_WHATSAPP,
  SERVICE_NOTIFICATIONS,
  SERVICE_UPDATES,
  SERVICE_EMAIL,
  VALIDATION_TYPES,
  VALIDATION_MESSAGES,
} from '../helpers/Constants';
import Input from './form/Input';
import 'react-phone-input-2/lib/material.css';
import OrangeButton from './elements/OrangeButton';
import GreenSwitch from './elements/GreenSwitch';

const useStyles = {
  paper: {
    border: '1px solid #E3E1E8',
    borderRadius: '17px',
  },
  paddedContainer: {
    padding: 20,
  },
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  notificationType: {
    color: '#6C6776',
    font: 'bold 16px Muli',
  },
  notificationDescription: {
    color: '#A8A4B3',
    font: 'normal 14px Muli',
  },
  notificationData: {
    color: ' #F87348',
    fontWeight: 'bold',
  },
  greenIcon: {
    color: '#80C100',
  },
  redIcon: {
    color: 'red',
  },
  boldFont: {
    fontWeight: 'bold',
    color: '#575360',
  },
  alignCenter: {
    display: 'flex',
  },
};

class UserNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWhatsappDialog: false,
      showEmailDialog: false,
      whatsappSwitch: false,
      notificationsSwitch: false,
      updatesSwitch: false,
      showEditWhatsappInput: false,
      showEditEmailInput: false,
      whatsappInput: '',
      emailInput: '',
    };
  }

  componentDidMount = () => {
    this.props.resetSnackBar();
    const {
      user: { id: userId },
      fetchUserServices,
    } = this.props;
    fetchUserServices(userId);
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.userServices.length > 0 &&
      prevProps.userServices !== this.props.userServices
    ) {
      this.setState(prevState => ({
        ...prevState,
        whatsappSwitch: this.checkUserService(SERVICE_WHATSAPP),
        notificationsSwitch: this.checkUserService(SERVICE_NOTIFICATIONS),
        updatesSwitch: this.checkUserService(SERVICE_UPDATES),
      }));
    }
  };

  handleShowWhatsappDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showWhatsappDialog: true,
    }));
  };

  handleCloseWhatsappDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showWhatsappDialog: false,
    }));
  };

  handleShowEmailDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showEmailDialog: true,
    }));
  };

  handleCloseEmailDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showEmailDialog: false,
    }));
  };

  handleSwitchChange = e => {
    e.persist();
    if (
      e.target.value === 'whatsappSwitch' &&
      !this.getServiceData(SERVICE_WHATSAPP) &&
      this.state[e.target.value] === false
    ) {
      this.handleShowWhatsappDialog();
    } else {
      const {
        user: { id: userId, email, Businesses },
        registerUserService,
        updateUserService,
      } = this.props;
      const { id: businessId } = Businesses[0];
      let name = '';
      let data = '';

      switch (e.target.value) {
        case 'whatsappSwitch':
          name = SERVICE_WHATSAPP;
          break;
        case 'notificationsSwitch':
          name = SERVICE_NOTIFICATIONS;
          // Test if service exists. If not, business email will be used to create the service for the first time.
          if (!this.getServiceData(SERVICE_NOTIFICATIONS)) {
            data = email;
          }
          break;
        case 'updatesSwitch':
          name = SERVICE_UPDATES;
          // Test if service exists. If not, business email will be used to create the service for the first time.
          if (!this.getServiceData(SERVICE_UPDATES)) {
            data = email;
          }
          break;
        default:
          name = '';
      }

      const serviceData = {
        name,
        isActive: !this.state[e.target.value],
      };

      // data willl only exist if notifications are activated without having the service created (EXCEPT FOR WHATSAPP SERVICE)
      // in this case, instead of requesting an update, a new record will be requested
      if (data) {
        serviceData.data = data;
        registerUserService(userId, serviceData);
      } else {
        updateUserService(userId, serviceData, businessId);
      }
    }
  };

  handleSubmitForm = (userId, serviceData) => {
    const {
      registerUserService,
      user: { Businesses },
    } = this.props;
    const { id: businessId } = Businesses[0];
    registerUserService(userId, serviceData, businessId);
    this.handleCloseWhatsappDialog();
  };

  checkUserService = name => {
    const { userServices } = this.props;
    return !!userServices.find(
      userService => userService.name === name && userService.isActive
    );
  };

  getServiceData = name => {
    const { userServices } = this.props;
    const service = userServices.find(userService => userService.name === name);
    return service && service.data;
  };

  handleShowEditWhatsappInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showEditWhatsappInput: true,
      whatsappInput: this.getServiceData(SERVICE_WHATSAPP),
    }));
  };

  handleCloseEditWhatsappInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showEditWhatsappInput: false,
    }));
  };

  handleShowEditEmailInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showEditEmailInput: true,
      emailInput:
        this.getServiceData(SERVICE_NOTIFICATIONS) || this.props.user.email,
    }));
  };

  handleCloseEditEmailInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showEditEmailInput: false,
    }));
  };

  handleInputChange = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleWhatsappInputChange = phone => {
    this.setState(prevState => ({
      ...prevState,
      whatsappInput: phone,
    }));
  };

  handleUpdateWhatsapp = () => {
    const {
      user: { id: userId, nombre: username, email, Businesses },
      updateUserService,
    } = this.props;
    const { whatsappInput } = this.state;
    const { id: businessId } = Businesses[0];

    const hasPlusSymbol = whatsappInput.includes('+');
    const newPhone = hasPlusSymbol ? whatsappInput.substring(1) : whatsappInput;
    const prevPhone = this.getServiceData(SERVICE_WHATSAPP);
    const oldPhone = hasPlusSymbol ? prevPhone.substring(1) : prevPhone;
    const data = hasPlusSymbol ? whatsappInput : `+${whatsappInput}`;

    const serviceData = {
      name: SERVICE_WHATSAPP,
      data,
      oldPhone,
      queryParams: {
        name: username,
        newPhone,
        email,
      },
    };
    updateUserService(userId, serviceData, businessId);
    this.handleCloseEditWhatsappInput();
  };

  handleUpdateEmail = () => {
    const {
      user: { id: userId },
      registerUserService,
      updateUserService,
    } = this.props;

    const serviceData = {
      name: SERVICE_EMAIL,
      data: this.state.emailInput,
    };
    if (
      !this.getServiceData(SERVICE_NOTIFICATIONS) &&
      !this.getServiceData(SERVICE_UPDATES)
    ) {
      registerUserService(userId, serviceData);
    } else {
      updateUserService(userId, serviceData);
    }
    this.handleCloseEditEmailInput();
  };

  getSnackBarMessage = name => {
    switch (name) {
      case SERVICE_WHATSAPP:
        return 'Tus preferencias de WhatsApp han sido actualizadas!';
      case SERVICE_NOTIFICATIONS:
        return 'Tus preferencias de Email han sido actualizadas!';
      case SERVICE_UPDATES:
        return 'Tus preferencias de Email han sido actualizadas!';
      case SERVICE_EMAIL:
        return 'Tu Email ha sido actualizado!';
      default:
        return 'Tus preferencias han sido actualizadas!';
    }
  };

  getSnackBarVariant = name => {
    switch (name) {
      case SERVICE_WHATSAPP:
        return 'whatsapp';
      case SERVICE_NOTIFICATIONS:
        return 'email';
      case SERVICE_UPDATES:
        return 'email';
      case SERVICE_EMAIL:
        return 'email';
      default:
        return 'info';
    }
  };

  render() {
    const {
      classes,
      user,
      userServices,
      userServiceRegistered,
      userServiceUpdated,
      userServicesError,
    } = this.props;
    const { showWhatsappDialog, whatsappInput, emailInput } = this.state;
    const { EDITAR } = BUTTON_LABEL;
    const { VALID_EMAIL, REQUIRED } = VALIDATION_TYPES;
    const { VALID_EMAIL_MSG, REQUIRED_MSG } = VALIDATION_MESSAGES;
    return (
      <Fade in timeout={400}>
        <div>
          <Grid container direction="column" spacing={4}>
            {(userServiceRegistered || userServiceUpdated) && (
              <CustomSnackBar
                anchor={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                variant={this.getSnackBarVariant(this.props.lastServiceUpdated)}
                size={24}
                message={this.getSnackBarMessage(this.props.lastServiceUpdated)}
                autoHideDuration={4000}
                showPercentage={false}
              />
            )}
            {userServicesError && (
              <CustomSnackBar
                anchor={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                variant="error"
                size={24}
                message="Ha ocurrido un error. Intenta nuevamente."
                autoHideDuration={4000}
                showPercentage={false}
              />
            )}
            {user && (
              <WhatsappOptinDialog
                open={showWhatsappDialog}
                user={user}
                handleSubmitForm={(userId, serviceData) =>
                  this.handleSubmitForm(userId, serviceData)
                }
                handleCloseDialog={() => this.handleCloseWhatsappDialog()}
              />
            )}
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.title}>
                Notificaciones{' '}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Paper elevation={0} className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  className={classes.paddedContainer}
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography
                              variant="h6"
                              className={classes.notificationType}
                            >
                              Notificaciones Whatsapp
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              className={classes.notificationDescription}
                            >
                              Avances y estados de las operaciones vía WhatsApp
                            </Typography>
                          </Grid>
                          {userServices.length > 0 &&
                            !!this.getServiceData(SERVICE_WHATSAPP) && (
                              <Grid item>
                                {this.state.showEditWhatsappInput ? (
                                  <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item>
                                          <PhoneInput
                                            placeholder="Número contacto"
                                            name="telefono"
                                            style={{ marginTop: '10px' }}
                                            inputStyle={{
                                              background: 'transparent',
                                              borderRadius: '40px',
                                              width: '100%',
                                              padding:
                                                '10.5px 20px 10.5px 60px',
                                            }}
                                            value={whatsappInput}
                                            defaultCountry="cl"
                                            onlyCountries={[
                                              'cl',
                                              'mx',
                                              'co',
                                              'ar',
                                              'pe',
                                              'br',
                                            ]}
                                            onChange={value =>
                                              this.handleWhatsappInputChange(
                                                value
                                              )
                                            }
                                            autoFormat={false}
                                            inputProps={{
                                              required: true,
                                            }}
                                            isValid={(
                                              inputNumber,
                                              countries,
                                              onlyCountries
                                            ) => {
                                              return onlyCountries.some(
                                                country =>
                                                  inputNumber.startsWith(
                                                    country.dialCode
                                                  )
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          className={classes.alignCenter}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <IconButton
                                              size="small"
                                              type="submit"
                                              onClick={
                                                this.handleUpdateWhatsapp
                                              }
                                              className={classes.greenIcon}
                                            >
                                              <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                              size="small"
                                              onClick={
                                                this
                                                  .handleCloseEditWhatsappInput
                                              }
                                              className={classes.redIcon}
                                            >
                                              <TimesIcon />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        className={classes.boldFont}
                                      >
                                        Al hacer click, aceptas los términos y
                                        condiciones de WhatsApp.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        className={classes.notificationData}
                                      >
                                        {this.getServiceData(SERVICE_WHATSAPP)}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        className={classes.editButton}
                                        onClick={
                                          this.handleShowEditWhatsappInput
                                        }
                                      >
                                        {EDITAR}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <GreenSwitch
                          checked={this.state.whatsappSwitch}
                          value="whatsappSwitch"
                          onChange={e => this.handleSwitchChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography
                              variant="h6"
                              className={classes.notificationType}
                            >
                              Notificaciones Email
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              className={classes.notificationDescription}
                            >
                              Avances y estados de las operaciones vía Email
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <GreenSwitch
                          checked={this.state.notificationsSwitch}
                          value="notificationsSwitch"
                          onChange={e => this.handleSwitchChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography
                              variant="h6"
                              className={classes.notificationType}
                            >
                              Novedades Email
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              className={classes.notificationDescription}
                            >
                              Actualizaciones y nuevas funciones via Email
                            </Typography>
                          </Grid>
                          {(!!this.getServiceData(SERVICE_NOTIFICATIONS) ||
                            !!this.getServiceData(SERVICE_UPDATES) ||
                            user.email) && (
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {this.state.showEditEmailInput ? (
                                  <Grid item>
                                    <ValidatorForm
                                      onSubmit={this.handleUpdateEmail}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Input
                                            name="emailInput"
                                            value={emailInput}
                                            onChange={e =>
                                              this.handleInputChange(e)
                                            }
                                            style={{ marginTop: 0 }}
                                            validators={[REQUIRED, VALID_EMAIL]}
                                            errorMessages={[
                                              REQUIRED_MSG,
                                              VALID_EMAIL_MSG,
                                            ]}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <Grid container direction="row">
                                            <IconButton
                                              type="submit"
                                              size="small"
                                              className={classes.greenIcon}
                                            >
                                              <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                              size="small"
                                              onClick={
                                                this.handleCloseEditEmailInput
                                              }
                                              className={classes.redIcon}
                                            >
                                              <TimesIcon />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </ValidatorForm>
                                  </Grid>
                                ) : (
                                  <>
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        className={classes.notificationData}
                                      >
                                        {this.getServiceData(
                                          SERVICE_NOTIFICATIONS
                                        ) ||
                                          this.getServiceData(
                                            SERVICE_UPDATES
                                          ) ||
                                          user.email}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <OrangeButton
                                        onClick={this.handleShowEditEmailInput}
                                        size="small"
                                        text={EDITAR}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <GreenSwitch
                          checked={this.state.updatesSwitch}
                          value="updatesSwitch"
                          onChange={e => this.handleSwitchChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Fade>
    );
  }
}

UserNotifications.propTypes = {
  classes: PropTypes.objectOf(Object).isRequired,
  resetSnackBar: PropTypes.func.isRequired,
  user: PropTypes.objectOf(Object).isRequired,
  fetchUserServices: PropTypes.func.isRequired,
  userServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  registerUserService: PropTypes.func.isRequired,
  updateUserService: PropTypes.func.isRequired,
  userServiceRegistered: PropTypes.bool.isRequired,
  userServiceUpdated: PropTypes.bool.isRequired,
  userServicesError: PropTypes.objectOf(Object).isRequired,
  lastServiceUpdated: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserServices: userId => dispatch(fetchUserServices(userId)),
    registerUserService: (userId, serviceData, businessId) =>
      dispatch(registerUserService(userId, serviceData, businessId)),
    updateUserService: (userId, serviceData, businessId) =>
      dispatch(updateUserService(userId, serviceData, businessId)),
    resetSnackBar: () => dispatch({ type: 'RESET_SERVICE_SNACKBAR' }),
  };
};

const mapStateToProps = state => {
  return {
    loadingUserServices: state.user.loadingUserServices,
    userServices: state.user.userServices,
    userServicesError: state.user.userServicesError,
    registeringUserService: state.user.registerUserService,
    userServiceRegistered: state.user.userServiceRegistered,
    updatingUserService: state.user.updatingUserService,
    userServiceUpdated: state.user.userServiceUpdated,
    lastServiceUpdated: state.user.lastServiceName,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(UserNotifications));
